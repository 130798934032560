import Tracker from '~lib/tracker';

import { getUserId } from '../common-data';

export const copyPremiumSellerVoucher = (() => {
  const track = (payload) => {
    const data = {
      evn: 'copy_premium_seller_voucher',
      ui: getUserId(),
      referrer: 'product_detail_page',
      voucher_code: payload.voucherCode,
      voucher_type: '',
    };

    Tracker.event(data);
  };

  return {
    track,
  };
})();

export default {
  copyPremiumSellerVoucher,
};
