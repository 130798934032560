// STORE provided by Westeros
const { STORE = {} } = global;
const { commit, dispatch } = STORE;

/** @function callIfStore
 * check if STORE is available or not before calling it.
 * if getter, return the value of the key
 * if action, return Promise
 *
 * @param {function} func
 * @returns {Promise|any}
 */
const callIfStore = func => {
  if (STORE) {
    return func();
  }

  const err = new Error(
    `STORE is not provided, API won't work. Please run in Westeros environment`,
  );

  // eslint-disable-next-line
  console.warn(err.message);
  return Promise.reject(err);
};

// STORE GETTER         ====
/** @function getState
 * Getter for getting STORE state
 *
 * @param {string} name - the key of STORE state
 * @returns {any}
 */
export const getState = name => callIfStore(() => STORE.state[name]);

// STORE ACTION WRAPPER ====
/**
 * @typedef {Object} CartOptions
 * @property {number} quantity
 * @property {Object} product
 * @property {string} product.id
 * @property {number} product.sku_id
 * @property {Object} headers
 * @property {Object} query
 *
 */

/** @function getCarts
 * wrapper for fetching cart by STORE's action
 *
 * @returns {Promise}
 */
export const getCarts = () => callIfStore(() => dispatch('cart/getCarts'));

/** @function getProduct
 * wrapper for fetching product by STORE's action
 *
 * @returns {Promise}
 */
export const getProduct = (id, force = false) =>
  callIfStore(() => dispatch('pdp/getProduct', { id, force }));

/** @function addToCart
 * wrapper for add to cart by STORE's action
 *
 * @param {CartOptions} params
 * @returns {Promise}
 */
export const addToCart = params => callIfStore(() => dispatch('cart/addProductToCart', params));

/** @function updateCart
 * wrapper for update product in cart by STORE's action
 *
 * @param {CartOptions} params
 * @param {number} itemId - id of an item in user's cart
 * @returns {Promise}
 */
export const updateCart = params => callIfStore(() => dispatch('cart/updateProductInCart', params));

/** @function addOrUpdateCart
 * wrapper for add or update to cart by STORE's action
 *
 * @param {CartOptions} params
 * @returns {Promise}
 */
export const addOrUpdateCart = params =>
  callIfStore(() => dispatch('cart/addOrUpdateProductToCart', params));

/** @function removeCartItem
 * wrapper for delete product in a cart by STORE's action
 *
 * @param {Object} params
 * @param {number} params.itemId
 * @param {Object} params.product
 * @param {Object} params.query
 * @returns {Promise}
 */
export const removeCartItem = params =>
  callIfStore(() => dispatch('cart/deleteProductFromCart', params));

/** @function updateQuantity
 *
 * @param {Object} payload
 * @param {number} payload.id - id of an item in user's cart
 * @param {number|string} payload.quantity - quantity of product to be update.
 * @returns {Promise}
 */
export const updateQuantity = payload => {
  const cart = getState('cart');
  return callIfStore(() => {
    let items = [];
    if (cart.items) {
      items = cart.items.map(item => {
        if (item.id === payload.id) {
          return {
            ...item,
            quantity: payload.quantity,
          };
        }
        return item;
      });
    }

    commit('cart/updateItems', items);
  });
};

export default {
  addOrUpdateCart,
  getCarts,
  removeCartItem,
  updateCart,
  updateQuantity,
};
