<template lang="pug">
  .c-carousel-mv__nav
    button.c-carousel-mv__nav-button.c-carousel-mv__nav-button--prev(
      :disabled="!canAdvanceBackward"  
      type="button" 
      ref="prevButton"
      v-html="prevLabel"
      @click="triggerPageAdvance('backward')"
    )

    button.c-carousel-mv__nav-button.c-carousel-mv__nav-button--next(
      :disabled="!canAdvanceForward"
      type="button" 
      ref="nextButton"
      v-html="nextLabel"
      @click="triggerPageAdvance('forward')" 
    )
</template>

<script>
export default {
  name: 'BlCarouselNavigationMv',
  inject: ['carousel'],
  props: {
    /**
     * Amount of padding to apply around the label in pixels
     */
    clickTargetSize: {
      type: Number,
      default: 8,
    },
    /**
     * Text content of the navigation next button
     */
    nextLabel: {
      type: String,
      default: '<i class="c-icon c-icon--chevron-right" />',
    },
    /**
     * Text content of the navigation prev button
     */
    prevLabel: {
      type: String,
      default: '<i class="c-icon c-icon--chevron-left" />',
    },
  },
  computed: {
    /**
     * @return {Boolean} Can the slider move forward?
     */
    canAdvanceForward() {
      return this.carousel.canGoToNext || false;
    },
    /**
     * @return {Boolean} Can the slider move backward?
     */
    canAdvanceBackward() {
      return this.carousel.canGoToPrev || false;
    },
  },
  methods: {
    /**
     * Trigger page change on +/- 1 depending on the direction
     * @param {"backward"} [direction]
     * @return {void}
     */
    triggerPageAdvance(direction) {
      /**
       * @event paginationclick
       * @type {string}
       */
      this.$emit('navigationclick', direction);
    },
  },
};
</script>
<style src="./BlCarouselNavigationMv.style.scss" lang="scss"></style>
