import actions from './actions';
import mutations from './mutations';
import state from './state';
import cart from './modules/cart';
import product from './modules/product';
import productReviews from './modules/product-reviews';
import shipping from './modules/shipping';
import voucher from './modules/voucher';

function createStore(Vuex) {
  return new Vuex.Store({
    actions,
    mutations,
    modules: {
      cart,
      productStore: product,
      productReviews,
      shipping,
      voucher,
    },
    state,
  });
}

export default createStore;
