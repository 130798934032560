import { getLoadTime } from '~shared-js/helpers/common-helpers';

export const SET_BASE_STATE = 'SET_BASE_STATE';
export const SET_LATENCY_REQUEST = 'SET_LATENCY_REQUEST';
export const ADD_SECTION_LATENCY_TRACKER = 'ADD_SECTION_LATENCY_TRACKER';
export const RESET_LATENCY_TRACKER = 'RESET_LATENCY_TRACKER';

const mutations = {
  [SET_BASE_STATE](state, { name, payload }) {
    state[name] = payload;
  },

  [SET_LATENCY_REQUEST](state, newLatencyRequest) {
    state.latencyRequest = {
      ...state.latencyRequest,
      ...newLatencyRequest,
    };
  },

  [ADD_SECTION_LATENCY_TRACKER](state, { key }) {
    state.latencySectionsTrackers = {
      ...state.latencySectionsTrackers,
      [key]: {
        loadTime: getLoadTime(state.latencyTimeOrigin),
        product: state.productStore.product,
        section: key,
        type: 'section',
        is_spa: state.sentLatencyCount > 0
      }
    }
  },

  [RESET_LATENCY_TRACKER](state) {
    state.hasSentLatency = false;
    state.latencySectionsTrackers = {};
    state.latencyRequest = Object.keys(state.latencyRequest).reduce((newVal, key) => ({
      ...newVal,
      [key]: true,
    }), state.latencyRequest);
  }
};

export default mutations;
