import to from '~lib/to';
import { chatOnlines, productService } from '~pdp/js/modules/api-modules';
import { webLoadTime } from '~shared-js/tracker/rage';

import { SET_BASE_STATE, SET_LATENCY_REQUEST, ADD_SECTION_LATENCY_TRACKER } from './mutations';

export default (() => {
  function fetchSimilarProducts({ commit, dispatch }, { id, limit = 6 }) {
    const queryParams = { limit, promoted: true };
    productService.$similar
      .retrieveSimilarProducts(id, { queryParams })
      .then((res) => {
        commit(SET_BASE_STATE, { name: 'similarProducts', payload: res.data });
      })
      .finally(() => {
        dispatch('setLatencyRequest', { similarProducts: false }, { root: true });
      });
  }

  function setLatencyRequest({ commit, state }, newLatencyRequest) {
    const entries = Object.entries(newLatencyRequest);

    if (entries.length === 1) {
      // skip commit if state's value is equal
      const [key, value] = entries[0];
      if (state.latencyRequest[key] === value) return;
      if (state.latencyRequestSections && Object.keys(state.latencyRequestSections).length) {
        if (state.latencyRequestSections[key] && !state.latencySectionsTrackers[key]) {
          commit(ADD_SECTION_LATENCY_TRACKER, { key });
        } else {
          // iterate sections config
          Object.keys(state.latencyRequestSections).every((section) => {
            // if not yet finished
            if (state.latencyRequestSections[section].includes(key)) {
              if (
                !state.latencySectionsTrackers[section] &&
                state.latencyRequestSections[section].every((endpoint) =>
                  endpoint === key ? !value : !state.latencyRequest[endpoint],
                )
              ) {
                commit(ADD_SECTION_LATENCY_TRACKER, { key: section });
              }
              return false;
            }
            return true;
          });
        }
      }
    }
    commit(SET_LATENCY_REQUEST, newLatencyRequest);
    if (Object.values(state.latencyRequest).every((bool) => !bool) && !state.hasSentLatency) {
      webLoadTime().track({
        product: state.productStore.product,
        is_spa: state.sentLatencyCount > 0,
        timeOrigin: state.latencyTimeOrigin,
      });
      webLoadTime().trackBulk(Object.values(state.latencySectionsTrackers));

      commit(SET_BASE_STATE, { name: 'hasSentLatency', payload: true });
      commit(SET_BASE_STATE, { name: 'sentLatencyCount', payload: state.sentLatencyCount + 1 });
    }
  }

  async function fetchSellerStatus({ dispatch }, sellerId) {
    const queryParams = { user_ids: [sellerId] };
    const [err, res] = await to(chatOnlines.retrieveOnlineStatus({ queryParams }));
    dispatch('setLatencyRequest', { chat: false });

    if (!err) {
      return res.data && res.data.onlines;
    }

    return Promise.reject(err);
  }

  return {
    fetchSimilarProducts,
    setLatencyRequest,
    fetchSellerStatus,
  };
})();
