import Cookies from 'js-cookie';
import { get } from 'lodash';

export const getUser = key => {
  if (key) return get(global.USER, key);
  return global.USER;
};

export const isLogin = () => !!(global.USER && global.USER.id);

export const getSessionId = () => Cookies.get('session_id') || '';

/**
 * Append a style tag in head.
 *
 * @param {String} style
 */
let ecsCount = 1;
export const insertCss = style => {
  if (!style) return;

  const el = document.createElement('style');
  el.type = 'text/css';
  el.id = `ecs-${ecsCount}`; // ecs = Elysium Custom Style
  document.head.appendChild(el);

  if (el.styleSheet) {
    el.styleSheet.cssText = style;
  } else {
    el.innerHTML = style;
  }
  ecsCount += 1;
};

export default {
  getSessionId,
  getUser,
  isLogin,
  insertCss,
};
