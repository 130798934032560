export const voucherSellerType = (store) => {
  if (store.brand_seller) {
    return 'brand_seller';
  }
  if (store.premium_top_seller) {
    return 'best_seller';
  }

  return 'all_seller';
};
