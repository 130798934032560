import { get as lodashGet } from 'lodash';

export default {
  data() {
    return {
      itemkuImageUrl: 'https://s2.bukalapak.com/m-ast/web-drawable/drawable-mdpi/logo_itemku.png',
      itemkuTagConfig: {
        enabled: false,
        criterias: [],
      },
      itemkuPowered: {
        label: 'Didukung oleh',
        title: '',
        description: '',
        imageUrl: 'https://s2.bukalapak.com/m-ast/web-drawable/drawable-mdpi/logo_itemku.png',
      },
      neoConfigSettings: {
        'itemku-search-rebranding': {
          dataName: 'itemkuTagConfig',
          map: config => {
            const itemkuTags = config?.tags || [];
            return itemkuTags.find(itemkuTag => itemkuTag.id === 'itemku');
          },
        },
      },
    };
  },
  computed: {
    isItemkuProduct() {
      const { product } = this;
      if (!product) return false;

      const { enabled, criterias } = this.itemkuTagConfig;
      return (
        enabled &&
        criterias.some(({ field, values }) => {
          const productCategory = lodashGet(product, field);
          return values.includes(productCategory);
        })
      );
    },
  },
};
