<template lang="pug">
  .u-txt--base(:class="stock <= 10 ? 'u-fg--red-brand' : ''") {{ stockText }}
</template>

<script>
export default {
  name: 'BlStockAv',

  props: {
    stock: {
      type: Number,
      required: true,
    },
    minQuantity: {
      type: Number,
      default: 1,
    },
    variantName: {
      type: String,
      default: '',
    },
  },

  computed: {
    stockText() {
      const { stock } = this;
      let n;

      if (stock > 10 && stock <= 50) {
        n = 10;
      } else if (stock > 50 && stock <= 100) {
        n = 50;
      } else if (stock > 100 && stock <= 200) {
        n = 100;
      } else if (stock > 200 && stock <= 500) {
        n = 200;
      } else if (stock > 500 && stock <= 1000) {
        n = 500;
      } else if (stock > 1000) {
        n = 1000;
      } else if (stock < this.minQuantity) {
        return `Stok ${this.variantName ? this.variantName : 'produk'} habis`;
      } else {
        return `Stok terbatas! Tersisa ${stock} lagi!`;
      }

      return `Tersedia > ${n} stok barang`;
    },
  },
};
</script>

<style lang="scss" src="./BlStockAv.style.scss"></style>
