<template lang="pug">
  .bl-shimmer-box-mv(:style="[ widthStyle, borderStyles ]")
    svg(:viewBox="viewBoxString")

</template>

<script>
import mixin from '../mixins'

export default {
  name: 'BlShimmerBoxMv',
  status: 'prototype',
  release: '0.1.0',

  mixins: [ mixin ],
  props: {
    rWidth: {
      type: Number,
      default: 1,
    },
    rHeight: {
      type: Number,
      default: 1,
    },
    width: {
      type: Number | String,
      default: '100%',
    },
  },

  computed: {
    viewBoxString () {
      return `0 0 ${this.rWidth} ${this.rHeight}`
    }
  }
}
</script>
