<template>
  <div class="c-delivery-location">
    <div v-if="storeAddress" class="c-delivery-location__seller">
      <small>Lokasi Pelapak</small>
      <div class="c-delivery-location__name">
        {{ storeAddress.city }}
      </div>
    </div>
    <div class="c-delivery-location__customer">
      <small>Tujuan Pengiriman</small>
      <div class="c-delivery-location__name">
        {{ currentAddress.district }}, {{ currentAddress.city }}
      </div>
    </div>
    <div class="c-delivery-location__change">
      <BlExpansionsMv :active.sync="showLocationSearch" data-testid="delivery-expansion">
        <button slot="trigger">
          Ubah Alamat
        </button>
        <BlLocationSearchMv
          slot="content"
          v-bind="locationSearchProps"
          @onSelectAddress="handleSelectAddress"
        />
      </BlExpansionsMv>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import BlExpansionsMv from '~pdp-mv/BlExpansionsMv';
import BlLocationSearchMv from '~pdp-mv/BlLocationSearchMv';

export default {
  name: 'DeliveryLocationOv',
  components: {
    BlExpansionsMv,
    BlLocationSearchMv,
  },
  props: {
    currentAddress: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showLocationSearch: false,
    };
  },

  computed: {
    ...mapState('shipping', ['addresses', 'geocoderAddresses', 'selectedAddress']),

    locationSearchProps() {
      return {
        userAddresses: this.addresses,
        geocoderAddresses: this.geocoderAddresses,
        getGeocoderAddresses: this.getGeocoderAddresses,
      };
    },
    storeAddress() {
      return this.product.get('store.address');
    },
  },

  methods: {
    ...mapActions('shipping', ['getGeocoderAddresses', 'selectAddress']),

    handleSelectAddress(address) {
      this.selectAddress(address);
      this.$emit('onSelectAddress', address);
      this.showLocationSearch = false;
    },
  },
};
</script>

<style lang="scss" src="./DeliveryLocationOv.scss"></style>
