<template lang="pug">
  .bl-flex-item(:class="flexItemClasses" :style="style")
    slot
</template>

<script>
import { SELF_VARIATION, COL_VARIATION } from './type';

export default {
  name: 'BlFlexItemAv',
  status: 'ready',
  release: '2.3.0',
  props: {
    /**
     * This allows the default alignment (or the one specified by align-items) to be overridden for individual flex items.
     */
    alignSelf: {
      type: String,
      validator(value) {
        return SELF_VARIATION.indexOf(value) > -1;
      },
      default: 'auto',
    },
    /**
     * Number of column the grid flex item.
     * `1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12`
     */
    col: {
      type: Number,
      validator(value) {
        return COL_VARIATION.indexOf(value) > -1;
      },
      default: undefined,
    },
    /**
     * Number of spacing on the left side of the flex item
     * `1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12`
     */
    offset: {
      type: Number,
      validator(value) {
        return COL_VARIATION.indexOf(value) > -1;
      },
      default: undefined,
    },
    /**
     * By default, flex items are laid out in the source order. However, the order property controls the order in which they appear in the flex container.
     */
    order: {
      type: Number,
      default: 0,
    },
    /**
     * This defines the ability for a flex item to grow if necessary. It accepts a unitless value that serves as a proportion.
     */
    grow: {
      type: Number,
      default: 0,
    },
    /**
     * This defines the ability for a flex item to shrink if necessary.
     */
    shrink: {
      type: Number,
      default: 1,
    },
    /**
     * This defines the default size of an element before the remaining space is distributed.  It can be a length (e.g. 20%, 5rem, etc.)
     */
    basis: {
      type: String,
      default: 'auto',
    },
  },
  computed: {
    flexItemClasses() {
      return [
        this.alignSelf !== '' ? `align-self-${this.alignSelf}` : '',
        this.col !== undefined ? `is-col-${this.col}` : '',
        this.offset !== undefined ? `is-offset-${this.offset}` : '',
      ];
    },
    style() {
      const style = {};
      if (this.order !== 0) {
        style.order = this.order;
      }
      if (this.grow !== 0 || this.shrink !== 1 || this.basis !== 'auto') {
        style.flex = `${this.grow} ${this.shrink} ${this.basis === 'unset' ? '' : this.basis}`;

        if (this.basis === 'unset') {
          style.flexBasis = 'unset';
        }
      }
      return style;
    },
  },
};
</script>

<style src="./BlFlexItemAv.style.scss" lang="scss"></style>
