<template>
  <component
    :is="href ? 'a' : 'button'"
    :class="buttonClasses"
    :disabled="isDisabled || isLoading"
    :href="href ? href : undefined"
    :target="href && target ? target : undefined"
    @click="handleClick"
  >
    <div v-if="isLoading" class="bl-button--loading">
      <slot name="is-loading-html" />
    </div>
    <template
      v-if="hasSlotData !== undefined && size !== 'extra-small'"
      tag="span"
      :variation="textVariation"
      :is-bolder="true"
      :is-inverse="variation === `primary` || variation === `destructive`"
      :style="{visibility: isLoading ? `hidden` : `visible`}"
      :is-disabled="isDisabled"
    >
      <slot />
    </template>
    <div
      v-if="icon"
      class="bl-button__has-icon"
      :style="{visibility: isLoading ? `hidden` : `visible`}"
    >
      <BlIconAv :icon="icon" color="#fff" />
    </div>
  </component>
</template>

<script>
import { BlIconAv } from '@bukalapak/bazaar-visual-v1';
import { VARIATION, SIZE } from './type';

export default {
  name: 'BlButtonV2Av',
  status: 'ready',
  release: '2.3.0',
  components: {
    BlIconAv,
  },
  props: {
    /**
     * Variation of button.
     * `primary, outline, outline-expressive, ghost, destructive`
     */
    variation: {
      type: String,
      default: 'primary',
      validator: value => VARIATION.indexOf(value) !== -1,
    },
    /**
     * The sizes of the button.
     * `extra-small, small, large, default`
     */
    size: {
      type: String,
      default: 'medium',
      validator: value => SIZE.indexOf(value) !== -1,
    },
    /**
     * Whether the button is disabled or not
     * `true , false`
     */
    isDisabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the button is block or not
     * `true , false`
     */
    isBlock: {
      type: Boolean,
      default: false,
    },
    /**
     * Whether the button is loading or not after clicked
     * `true , false`
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * Icon Name, please check this [link](https://bazaar-dweb.bukalapak.design/#/Bazaar%20Visual) for list of icon
     */
    icon: {
      type: Object,
      default: null,
    },
    /**
     * Href attribute for Button
     */
    href: {
      type: String,
      default: '',
    },
    /**
     * Target attribute if Button use href
     */
    target: {
      type: String,
      default: '',
    },
  },
  computed: {
    buttonClasses() {
      const array = ['bl-button'];
      array.push(`bl-button--${this.variation}`);
      array.push(`bl-button--${this.size}`);

      if (!this.$slots.default) {
        array.push('is-icon-only');
      }
      if (this.isBlock) {
        array.push('bl-button--block');
      }

      return array;
    },
    textVariation() {
      if (this.size === `large` || this.size === `medium`) {
        return `body-default`;
      }
      return `body-small`;
    },
    hasSlotData() {
      return this.$slots.default;
    },
  },
  methods: {
    /**
     * Event click after user clicked.
     *
     * @type function
     * @event click
     */
    handleClick(event) {
      if (!this.href) {
        this.$emit('click', event);
      }
    },
  },
};
</script>

<style src="./BlButtonV2Av.style.scss" lang="scss"></style>
