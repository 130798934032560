<template lang="pug">
.c-main-product__price(:class="classes")
  bl-price-av(
    :main="!productDetailTvInjection.hasDeal"
    :variation="productDetailTvInjection.hasDeal ? 'stroke' : 'original'"
    :number="productOriginalPrice")

  template(v-if="productDetailTvInjection.hasDeal")
    .c-main-product__price__discount
      bl-price-av(main variation="discounted" :number="productPrice")
      span(class="c-main-product__price__discount-percentage")
        | &#32;Hemat {{ Math.round(productDiscountPercentage) }}%

  .c-main-product__wholesale-label.c-wholesale-label(v-if="productDetailTvInjection.hasWholesale") Grosir

</template>

<script>
import _ from 'lodash';

import BlPriceAv from '~pdp-av/BlPriceAv';

export default {
  name: 'BlMainPriceMv',
  status: 'prototype',
  release: '0.1.0',

  components: {
    BlPriceAv,
  },

  inject: ['productDetailTvInjection'],

  computed: {
    classes() {
      return {
        '-has-deal': this.productDetailTvInjection.hasDeal,
        '-is-wholesale': this.productDetailTvInjection.hasWholesale,
      };
    },

    deal() {
      return _.get(this.selectedProduct, 'deal');
    },

    selectedProduct() {
      return this.productDetailTvInjection.selectedProduct;
    },

    productOriginalPrice() {
      return this.selectedProduct.originalPrice;
    },

    productPrice() {
      return !this.productDetailTvInjection.hasSubsidy && this.productDetailTvInjection.hasDeal
        ? this.deal.discount_price
        : this.selectedProduct.price;
    },

    productDiscountPercentage() {
      return !this.productDetailTvInjection.hasSubsidy && this.productDetailTvInjection.hasDeal
        ? this.deal.percentage
        : this.selectedProduct.discountPercentage;
    },
  },
};
</script>

<style lang="scss" src="./BlMainPriceMv.scss"></style>
