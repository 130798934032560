<template>
  <div class="c-main-product__action c-main-product__separator">
    <div
      class="c-main-product__action-buttons"
      :class="{ 'c-main-product__action--white': !!secondaryHeader }"
    >
      <BlChatButtonMv v-bind="chatButtonProps" />

      <BlCartButtonMv v-bind="cartButtonProps" @click="clickAtc" />

      <BlCheckoutButtonMv v-bind="checkoutButtonProps" @click="clickCheckout" />
    </div>

    <BlCartDialogMv
      v-if="showMiniCart"
      :cart="cart"
      :current-product-sku="productSkuId"
      :show.sync="showMiniCart"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import qs from 'qs';

import BlCartDialogMv from '~pdp-mv/BlCartDialogMv';
import loginAction from '~pdp/js/constants/login-action';
import { pickLoginParams } from '~pdp-utils/helpers';
import { atcBot } from '~shared-js/tracker/rage';
import { SET_BASE_STATE } from '~pdp/store/mutations';

import BlCartButtonMv from './BlCartButtonMv';
import BlChatButtonMv from './BlChatButtonMv';
import BlCheckoutButtonMv from './BlCheckoutButtonMv';

const { STORE = {}, document, location, history } = global;

function pdpActionHandlerFactory(context) {
  return function pdpActionHandler(event) {
    const addToCartTrigger = event.target.closest(`[data-pdp-add-to-cart-trigger]`);
    const buyNowTrigger = event.target.closest(`[data-pdp-buy-now-trigger]`);
    if (addToCartTrigger) {
      context.clickAtc(context.atcData, 'navbar');
    }
    if (buyNowTrigger) {
      context.clickCheckout(context.atcData, 'navbar');
    }
  };
}

export default {
  name: 'BlMainActionMv',

  components: {
    BlCartButtonMv,
    BlCartDialogMv,
    BlChatButtonMv,
    BlCheckoutButtonMv,
  },

  inject: ['productDetailTvInjection'],

  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
    rawProduct: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      cartHeaders: {
        'X-Atc-Referrer': 'from_pdp',
      },
      checkSellerInterval: null,
      isSellerOnline: false,
      showMiniCart: false,
      pdpActionHandler: null,
      secondaryHeader: null,
    };
  },

  computed: {
    ...mapState(['toggleVariantNotPreSelected']),
    ...mapState('cart', ['isAddingCart', 'isOnCheckout']),
    ...mapGetters('cart', ['atcData', 'productSkuId']),

    cart() {
      return STORE.state && STORE.state.cart;
    },

    cartButtonProps() {
      return {
        disabled: this.isNoStock || this.isAddingCart || this.isOnCheckout,
        isAddingCart: this.isAddingCart,
        isWhiteHeader: this.isWhiteHeader,
      };
    },

    chatButtonProps() {
      return {
        chatData: this.chatData,
        isSellerOnline: this.isSellerOnline,
        isWhiteHeader: this.isWhiteHeader,
        product: this.product,
      };
    },

    checkoutButtonProps() {
      return {
        disabled: this.isNoStock || this.isOnCheckout,
        isOnCheckout: this.isOnCheckout,
        isPreorder: this.productDetailTvInjection.isPreorder,
        isWhiteHeader: this.isWhiteHeader,
      };
    },

    chatData() {
      return {
        'data-user-id': this.product.store && this.product.store.id,
        'data-context': JSON.stringify({
          id: this.productIdInt,
          type: 'product',
        }),
        'data-online-toggle': JSON.stringify({
          id: this.product.store && this.product.store.id,
          toggleClass: 'c-btn--has-online',
        }),
        'data-track-open-chat-room': JSON.stringify({
          partnerId: this.product.store && this.product.store.id.toString(36),
          buttonReferrer: 'page',
        }),
      };
    },

    isWhiteHeader() {
      return !!this.secondaryHeader;
    },

    productIdInt() {
      return this.product.id ? parseInt(this.product.id, 36) : null;
    },

    isNoStock() {
      return this.productDetailTvInjection.isNoStock;
    },
  },

  watch: {
    'cart.isReady': {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.$nextTick(() => {
            this.actionAfterLogin();
          });
        }
      },
    },
  },

  mounted() {
    const secondaryHeader = document.getElementById('secondary-header');
    const searchParams = new URLSearchParams(global.location.search);
    const isFromCartPopover = searchParams.get('from') === 'cart_popover';
    if (isFromCartPopover) this.showValidationError();

    this.setSearchContext(searchParams);

    if (secondaryHeader) {
      const handler = pdpActionHandlerFactory(this);
      secondaryHeader.addEventListener('click', handler);
      this.secondaryHeader = secondaryHeader;
      this.pdpActionHandler = handler;
    }

    this.checkSellerInterval = global.setInterval(this.checkSellerStatus, 60000);
    this.checkSellerStatus();

    // Init tracker
    atcBot.setEvent();

    this.actionAfterLogin();
  },

  destroyed() {
    global.clearInterval(this.checkSellerInterval);
    atcBot.removeEvent();
    if (this.secondaryHeader) {
      this.secondaryHeader.removeEventListener('click', this.pdpActionHandler);
    }
  },

  methods: {
    ...mapActions([
      'fetchSellerStatus',
      'setErrorMessage',
      'updateQuantity',
      'commitFlashMessage',
      'setErrorVariantSelection',
    ]),
    ...mapActions('cart', ['atcHandler', 'checkoutHandler']),

    /**
     * Trigger this method in mounted.
     * For checking if user is redirected from /login with param force_login=atc
     * */
    actionAfterLogin() {
      // ATC if force_login = atc
      if (!this.cart.isReady) return;
      let params = qs.parse(location.search.slice(1)) || {};
      const { force_login: forceLogin, fromEl, item } = params;
      const hasForce =
        forceLogin && (forceLogin === loginAction.addToCart || forceLogin === loginAction.checkout);
      if (this.$isLogin() && hasForce) {
        const product = { id: this.product.id };
        if (item && item.product_sku_id) {
          product.sku_id = Number(item.product_sku_id);
        }
        const cartData = Object.assign({}, this.atcData, { product });
        if (forceLogin === loginAction.addToCart) {
          this.clickAtc(cartData, fromEl);
        } else if (forceLogin === loginAction.checkout) {
          this.clickCheckout(cartData, fromEl);
        }
        // Remove the additional params, so when users click back, they won't get redirect again to payment
        params = pickLoginParams(params, ['force_login', 'fromEl', 'item']);
        const url = `${location.pathname}?${qs.stringify(params)}${location.hash}`;
        history.replaceState({}, '', url);
      }
    },

    showValidationError() {
      this.setErrorVariantSelection(true);
      this.commitFlashMessage({
        message: 'Ups, kamu belum pilih variasi barang. Pilih dulu ya.',
        type: 'error',
      });
    },

    validateCartData(cartData) {
      if (!this.toggleVariantNotPreSelected) {
        return true;
      }

      this.setErrorVariantSelection(false);
      if (cartData.product.id && cartData.product.sku_id) {
        return true;
      }
      const target = document.getElementById('section-main-product');
      if (target) {
        const { top } = target.getBoundingClientRect();
        window.scrollTo({ top: top + -100, behavior: 'smooth' });
      }
      this.showValidationError();
      return false;
    },

    async clickAtc(cartData = this.atcData, fromEl = 'content') {
      if (!this.validateCartData(cartData)) {
        return;
      }
      if (!this.isNoStock) {
        await this.atcHandler({
          ...cartData,
          fromEl,
        })
          .then((res) => {
            if (res) {
              this.showMiniCart = true;
            }
          })
          .catch((e) => {
            console.error('ERROR when ATC', e);
          });
      }
    },

    clickCheckout(cartData = this.atcData, fromEl = 'content') {
      if (!this.validateCartData(cartData)) {
        return;
      }
      if (!this.isNoStock) {
        this.checkoutHandler({
          ...cartData,
          fromEl,
        });
      }
    },

    checkSellerStatus() {
      if (this.product.store) {
        const sellerId = _.get(this.product, 'store.id');
        if (sellerId) {
          this.fetchSellerStatus(sellerId.toString())
            .then((res) => {
              if (res.length && String(res[0]) === String(sellerId)) {
                this.isSellerOnline = true;
              } else {
                this.isSellerOnline = false;
              }
            })
            .catch(() => {
              this.isSellerOnline = false;
            });
        }
      }
    },

    setSearchContext(searchParams) {
      const isFromListProduct = searchParams.get('from') === 'list-product';
      let experimentContext = {};

      if (isFromListProduct && searchParams.get('search_query_id')) {
        experimentContext = {
          user_id: typeof window?.USER !== 'undefined' ? window.USER.id36 : '',
          platform: 'dweb',
          referrer: 'product_detail',
          product_slot_type: searchParams.get('product_slot_type'),
          pos: searchParams.get('pos'),
          is_keyword_typo: searchParams.get('is_keyword_typo'),
          keyword_expansion: searchParams.get('keyword_expansion'),
          search_query_id: searchParams.get('search_query_id'),
          search_result_size: searchParams.get('search_result_size'),
        };
      }

      this.$store.commit(SET_BASE_STATE, {
        name: 'experimentContext',
        payload: experimentContext,
      });
    },
  },
};
</script>

<style lang="scss" src="./BlMainActionMv.scss"></style>
