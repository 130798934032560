import { retrieveTogglesStatus } from '~shared-js/neo-toggles';

const NEW_SUPER_SELLER_BADGE_TOGGLE_ID = 'super-seller-rebranding-enabled';

function getSuperSellerBadgePromise() {
  if (!window.superSellerBadgeVersionPromise) {
    window.superSellerBadgeVersionPromise = retrieveTogglesStatus([
      NEW_SUPER_SELLER_BADGE_TOGGLE_ID,
    ]);
  }

  return window.superSellerBadgeVersionPromise;
}

export default getSuperSellerBadgePromise;
