import { isEmpty } from 'lodash';

import Tracker from '~lib/tracker';
import { convertToString } from '~lib/common-utils';
import { isOnDeal } from '~shared-js/components/BlProductCardMv/helper';
import { getSessionId } from '~shared-js/helpers/global-helper';
import { getLoadTime } from '~shared-js/helpers/common-helpers';
import { appendParams } from '~shared-js/helpers/url-helper';
import { getProductAvailabilityState } from '~shared-js/product-detail-page/helpers/product-helper';

import { getUnixTime, getDocumentReferrer, getUserId } from '../common-data';
import tracker, { TrackerPrototype } from '../tracker-prototype';

const isProd = process.env.NODE_ENV === 'production';

/**
 * Main tracker for add to cart
 */
export const atc = (({ location }) => {
  const isDiscount = (deal) => {
    if (deal.percentage) {
      return isOnDeal(deal.applied_date, deal.expired_date);
    }
    return false;
  };

  const track = async (payload, product) => {
    const categoryId = product.get('category.id');
    const sellerId = product.get('store.id');
    const reviewPositive = product.get('store.reviews.positive');
    const reviewNegative = product.get('store.reviews.negative') || 0;
    const sellerFeedbacks = reviewPositive ? reviewPositive + reviewNegative : 0;
    const data = {
      evn: 'add_to_cart',
      ui: getUserId(),
      t: getUnixTime(),
      referrer_url: payload.referrerUrl || getDocumentReferrer(),
      url: payload.url || location.href,

      // integer
      cart_id: convertToString(payload.cartId),
      // integer
      cart_item_id: convertToString(payload.cartItemId),
      platform: payload.platform || 'desktop_web',
      referrer_event: payload.referrerEvent || '',
      quantity: payload.quantity,

      category_id: convertToString(categoryId, 36),
      is_wholesales: product.wholesales.length > 0,
      is_discount: isDiscount(product.deal),
      price: convertToString(product.price),
      product_id: product.id,
      product_sku_id: convertToString(product.skuId, 36),
      product_rating: (product.rating && parseFloat(product.rating.average_rate)) || 0,
      reviews: (product.rating && product.rating.user_count) || 0,
      // integer
      seller_id: convertToString(sellerId),
      seller_feedbacks: sellerFeedbacks,
    };

    await Tracker.event(data);
  };

  return {
    track,
  };
})(global);

/**
 * Tracker for determine the behavior is user or bot
 */
export const atcBot = (() => {
  let keyboard = 3;
  let mouse = 5;
  let scroll = 7;

  return {
    track() {
      const td = {
        evn: 'add_to_cart_bot',
        platform: 'dweb',
        ui: getUserId(),
        t: getUnixTime(),
        ssa_012: keyboard + mouse + scroll,
      };

      if (Tracker) {
        Tracker.event(td);
      }
    },

    userEvent: {
      keyup() {
        keyboard = 0;
        if (global.document) {
          global.document.removeEventListener('keyup', atcBot.userEvent.keyup);
        }
      },

      mousemove() {
        mouse = 0;
        if (global.document) {
          global.document.removeEventListener('mousemove', atcBot.userEvent.mousemove);
        }
      },

      scroll() {
        scroll = 0;
        if (global.document) {
          global.document.removeEventListener('scroll', atcBot.userEvent.scroll);
        }
      },
    },

    setEvent() {
      if (global.document) {
        global.document.addEventListener('keyup', atcBot.userEvent.keyup);
        global.document.addEventListener('mousemove', atcBot.userEvent.mousemove);
        global.document.addEventListener('scroll', atcBot.userEvent.scroll);
      }
    },

    removeEvent() {
      if (global.document) {
        global.document.removeEventListener('keyup', atcBot.userEvent.keyup);
        global.document.removeEventListener('mousemove', atcBot.userEvent.mousemove);
        global.document.removeEventListener('scroll', atcBot.userEvent.scroll);
      }
    },
  };
})();

/**
 * Add to cart tracker for bhlm (if pdp accessed from bhlm page)
 */
export const bhlmAtc = (() => {
  const track = (payload) => {
    const data = {
      evn: 'bhlm_add_to_cart',
      ui: getUserId(),
      cart_id: convertToString(payload.cartId, 36),
      cart_item_id: convertToString(payload.cartItemId, 36),
      product_id: convertToString(payload.productId, 36),
      referrer_bhlm: '',
    };

    if (payload.platform) {
      data.platform = payload.platform;
    }

    if (Tracker) {
      Tracker.event(data);
    }
  };

  return { track };
})();

export const productDetailAction = (({ location }) => {
  const track = ({ action = '', campaignId } = {}) => {
    const data = {
      evn: 'product_detail_action',
      ui: getUserId(),
      t: getUnixTime(),
      referrer_url: getDocumentReferrer(),
      url: location.href,
      action,
      campaign_id: campaignId
    };

    Tracker.event(data);
  };

  return {
    track,
  };
})(global);

export const reviewFilter = (() => {
  const track = ({ product, star, withPicture, withDescription }) => {
    const data = {
      evn: 'ulasan_filter',
      ui: getUserId(),
      product_id: product.id,
      category_id: convertToString(product.category.id, 36),
      referrer_url: getDocumentReferrer(),
      emcd1: '',
      star: star > 0 ? star.toString() : '1-5',
      with_photo: withPicture ? 1 : 0,
      with_description: withDescription ? 1 : 0,
    };

    Tracker.event(data);
  };

  return {
    track,
  };
})();

export const productDetailSocialClicks = (() => {
  const track = (payload) => {
    const data = {
      evn: 'product_detail_social_clicks',
      ui: getUserId(),
      t: getUnixTime(),
      platform: 'desktop_web',
      product_id: payload.productId,
      channel: payload.channel,
    };

    Tracker.event(data);
  };

  return {
    track,
  };
})();

export const viewReviewProduct = (payload) => {
  const data = {
    evn: 'view_ulasan_barang',
    ui: getUserId(),
    session_id: getSessionId(),
    product_id: payload.productId,
    reviewer_id: convertToString(payload.reviewerId, 36),
    ulasan_id: payload.reviewId,
    ulasan_page: payload.reviewPage,
  };

  return tracker(data);
};

const ELYS = 'elysium';
export const webLoadTime = () => {
  const pretrack = (payload) => {
    const isForSale = isEmpty(getProductAvailabilityState(payload.product));
    const url = isForSale
      ? global.location.href
      : appendParams(global.location.href, { is_empty_page: true });
    return {
      load_time: payload.loadTime || getLoadTime(payload.timeOrigin),
      product_id: payload.product.id,
      url,
      type: payload.type || 'screen',
      section: payload.section || '',
      is_spa: payload.is_spa || false,
    };
  };
  const t = new TrackerPrototype({ pretrack });
  t.init({
    evn: 'web_load_time',
    url: global.location?.href,
    is_login: !!getUserId(),
    is_bot: global.USER?.agent?.isBot || false,
    connection_type: global.navigator?.connection?.effectiveType || '',
    version: isProd ? `${ELYS}-${global.elyVersion || 'unknown'}` : `${ELYS}-development`,
    ui: getUserId(),
  });

  return t;
};

export default {
  atc,
  atcBot,
  bhlmAtc,
  productDetailAction,
  reviewFilter,
  productDetailSocialClicks,
  viewReviewProduct,
};
