import { extractParams } from '@bukalapak/toolbox-helper/url-helper';
import qs from 'qs';

import to from '~lib/to';
import loginAction from '~pdp/js/constants/login-action';
import { addOrUpdateCart, getState } from '~pdp/store/global';
import { setLoginComebackUrl } from '~pdp-utils/helpers';
import { appendParams } from '~shared-js/helpers/url-helper';
import { isLogin } from '~shared-js/helpers/global-helper';
import { atc as atcTracker, atcBot, bhlmAtc, productDetailAction } from '~shared-js/tracker/rage';
import productActionType from '~shared-js/tracker/product-detail-action-type';

import { SET_BASE_CART_STATE } from './mutations';

const sendAtcTracker = async ({
  cartId = '',
  cartItemId = '',
  from,
  product,
  quantity,
  ...extraParams
}) => {
  const payload = {
    cartId,
    cartItemId,
    referrerEvent: `product_detail/${from === 'checkout' ? 'beli' : 'keranjang'}`,
    quantity,
  };
  if (extraParams.url) {
    payload.url = extraParams.url;
  }
  if (extraParams.referrerUrl) {
    payload.referrerUrl = extraParams.referrerUrl;
  }

  atcTracker.track(payload, product);
};

const prepareCartData = (atcData, newAtcData) => {
  let { product: { sku_id: skuId } = {}, quantity = 0 } = newAtcData;

  quantity = quantity > 0 ? quantity : atcData.quantity;
  skuId = skuId || atcData.product.sku_id;
  return Object.assign({}, atcData, {
    product: {
      ...atcData.product,
      sku_id: skuId,
    },
    quantity,
  });
};

const setProductAction = (from, fromEl) => {
  if (from === 'cart') {
    return fromEl === 'navbar' ? productActionType.atcNavbar : productActionType.atc;
  }
  if (from === 'checkout') {
    return fromEl === 'navbar' ? productActionType.buyNowNavbar : productActionType.buyNow;
  }
  return '';
};

const getPaymentQueryParams = params => {
  const urlParams = extractParams();
  const queryParams = {
    item_ids: `[${params.currentItem.id}]`,
    referrer_event: 'product_detail/beli',
  };

  if (urlParams && urlParams.from === 'bhlm') {
    queryParams.is_bhlm = true;
  }

  return queryParams;
};

export default {
  redirectToLogin({ getters, rootState }, { action }) {
    const { product } = rootState.productStore;
    const queryParams = {
      force_login: action,
      item: {
        product_id: product.id,
        product_sku_id: getters.productSkuId,
        quantity: product.minQuantity,
      },
    };
    global.location = setLoginComebackUrl(action, { queryParams });
    return false;
  },

  async atcHandler({ commit, dispatch, getters }, params) {
    commit(SET_BASE_CART_STATE, {
      name: 'isAddingCart',
      payload: true,
    });
    if (!isLogin()) {
      return dispatch('redirectToLogin', {
        action: loginAction.addToCart,
      });
    }
    const data = prepareCartData(getters.atcData, params);
    const [err, res] = await to(addOrUpdateCart(data));
    commit(SET_BASE_CART_STATE, {
      name: 'isAddingCart',
      payload: false,
    });
    if (res) {
      dispatch('afterAtcHandler', {
        from: 'cart',
        fromEl: params.fromEl,
        url: appendParams(global.location, { is_revamp: true }),
        quantity: data.quantity,
      });
      return res;
    }
    dispatch('setErrorMessage', err, { root: true });
    return Promise.reject(err);
  },

  async checkoutHandler({ commit, dispatch, getters, rootState }, params) {
    commit(SET_BASE_CART_STATE, {
      name: 'isOnCheckout',
      payload: true,
    });
    if (!isLogin()) {
      return dispatch('redirectToLogin', {
        action: loginAction.checkout,
      });
    }
    const { product } = rootState.productStore;
    const data = prepareCartData(getters.atcData, {
      ...params,
      quantity: product.minQuantity,
    });
    const [err, res] = await to(addOrUpdateCart(data));
    if (res) {
      await dispatch('checkoutSuccess', {
        ...params,
        quantity: data.quantity,
        skuId: data.product.sku_id,
      });
      return res;
    }
    dispatch('setErrorMessage', err, { root: true });
    return Promise.reject(err);
  },

  async checkoutSuccess({ dispatch }, params) {
    const cart = getState('cart');
    const currentItem = cart.items.find(i => i.stuff.id === params.skuId);
    const queryParams = getPaymentQueryParams({
      currentItem,
    });
    const queryString = qs.stringify(queryParams, {
      arrayFormat: 'brackets',
    });
    const url = `/payment/purchases/new?${queryString}`;
    await dispatch('afterAtcHandler', {
      from: 'checkout',
      fromEl: params.fromEl,
      url: new URL(url, global.location.origin),
      referrerUrl: appendParams(global.location.href, { is_revamp: true }),
      quantity: params.quantity,
    });
    global.location = url;
    return true;
  },

  async afterAtcHandler({ state, getters, rootState }, params) {
    /**
     * from - Indicating wheter the action is 'Tambah keranjang' or 'Beli Sekarang'
     * fromEl - Indicating the action is trigger from element, either from Navbar or the main Content
     */
    const { from = 'checkout', fromEl, ...extraParams } = params;
    try {
      const { product } = rootState.productStore;
      const cart = getState('cart') || {};
      const currentItem = getters.currentItem || {};
      const urlParams = extractParams();
      const action = setProductAction(from, fromEl);

      atcBot.track();
      sendAtcTracker({
        cartId: cart.id,
        cartItemId: currentItem.id,
        from,
        product,
        ...extraParams,
      });
      productDetailAction.track({ action });
      if (!state.isOnCheckout && urlParams && urlParams.from === 'bhlm') {
        bhlmAtc.track({
          cartId: cart.id || '',
          cartItemId: currentItem.id,
          productId: product.id,
        });
      }
    } catch (err) {
      /* eslint-disable */
      console.error('ERROR after ATC successfully');
      console.error(err);
      /* eslint-enable */
    }
  },
};
