export const DIRECTION_VARIATION = ['row', 'row-reverse', 'column', 'column-reverse', ''];

export const WRAP_VARIATION = ['nowrap', 'wrap', 'wrap-reverse', ''];

export const JUSTIFY_VARIATION = [
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-around',
  'space-evenly',
  '',
];

export const ALIGN_ITEMS_VARIATION = [
  'flex-start',
  'flex-end',
  'center',
  'baseline',
  'stretch',
  '',
];

export const ALIGN_CONTENT_VARIATION = [
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-around',
  'stretch',
  '',
];

export const GUTTER_VARIATION = [16, 20, 24, 32];
