<template lang="pug">
  .c-reviews-item(v-if="!isEmpty(item)" :data-rid="item.id")
    .c-reviews-item__head
      .c-reviews-item__head-content
        bl-rating-mv(:value="ratingVal" :max-ratings="5" variant="star" size="medium")
        h4.c-reviews-item__title.u-mrgn-top--1 {{ item.review.title }}
        p.c-reviews-item__date.u-fg--ash Ditulis {{ ulasanDate }}
      .c-reviews-item__head-img(v-if="item.images.length > 0")
        BlMediaMv.c-reviews-item__thumb(
          v-for="(img, index) in item.images"
          :key="img.id"
          :src="imgUrl(img.url)"
          :alt="item.review.title"
          @click="openDialog({ index, product: item, section })"
        )
    p(data-testid="content") {{ item.review.content }}
    .c-reviews-item__foot
      .c-reviews-item__user
        BlMediaMv.c-avatar.u-mrgn-right--2(:src="item.sender.avatar_url" :alt="item.sender.name")
        div
          a.c-link--primary--black(
            v-if="item.sender.url"
            :href="item.sender.url"
            :class="ulasanSenderClass"
            data-testid="reviewer-name"
            rel="nofollow"
          ) {{ ulasanSender }}
          span(v-else :class="ulasanSenderClass" data-testid="reviewer-name") {{ ulasanSender }}
          span.u-display-inline-block.u-valign-middle.u-fg--ash(v-if="isPrivate", data-testid="review-private")
            bl-flag-mv(type="micro")
              bl-flag-head-av
                svg(
                  version="1.1"
                  viewBox="0 0 24 24"
                  class="svg-icon svg-fill"
                  style="width: 14px; height: 14px;"
                )
                  path(
                    fill="#bbbbbb"
                    stroke="none"
                    pid="0"
                    d="M19 10h-1V7a5 5 0 0 0-5-5h-2a5 5 0 0 0-5 5v3H5a1 1 0 0 0-1 1v9.5A1.5 1.5 0 0 0 5.5 22h13a1.5 1.5 0 0 0 1.5-1.5V11a1 1 0 0 0-1-1zM9 7a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v3H9zm4 10a1 1 0 0 1-2 0v-2a1 1 0 0 1 2 0z"
                  )
              bl-flag-body-av Ulasan Privat
      ul.c-reviews-item__votes
        li(:disabled="item.votes.inProgress")
          svg(
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            data-testid="positive-vote"
            :class="item.votes.user_vote === 'positive' ? 'vote--positive' : ''"
            @click="voteReview({ id: item.id, vote: 'positive' })"
          )
            g#icon-thumb-up
              path#Fill-3(
                d="M19.98535,10.7036 C19.77385,12.2246 19.49235,14.2546 19.25685,15.9746 C19.02085,17.6991 17.52985,19.0001 15.78885,19.0001 L12.13685,19.0001 C11.26985,19.0001 9.68285,17.9861 9.14435,17.5951 L9.01335,17.5001 L7.49985,17.5001 L7.49985,10.2326 C7.90285,9.8901 8.74435,9.1551 9.00135,8.7851 C10.84535,6.1266 12.99985,4.3446 13.46885,4.0896 C13.65335,4.1006 13.78385,4.1511 13.85635,4.2396 C14.00535,4.4221 13.97435,4.7796 13.95235,4.8871 L13.94285,4.9516 C13.91635,5.2636 13.66535,5.8416 13.44385,6.3521 C13.01385,7.3436 12.67385,8.1271 13.01535,8.6471 C13.10135,8.7786 13.30885,9.0001 13.72635,9.0001 L18.49935,9.0001 C18.93435,9.0001 19.34735,9.1881 19.63235,9.5151 C19.91635,9.8411 20.04485,10.2746 19.98535,10.7036 M5.49985,18.5001 L3.99985,18.5001 L3.99985,10.0001 L6.49985,10.0001 L6.49985,17.5001 C6.49985,18.0511 6.05135,18.5001 5.49985,18.5001 M20.38685,8.8591 C19.91185,8.3131 19.22385,8.0001 18.49935,8.0001 L13.88935,7.9996 C13.95285,7.6921 14.20185,7.1186 14.36135,6.7501 C14.63185,6.1261 14.88785,5.5361 14.93635,5.0661 C14.97035,4.8836 15.07085,4.1536 14.63635,3.6141 C14.44285,3.3741 14.06985,3.0881 13.39135,3.0881 C12.75285,3.0881 10.10835,5.4341 8.17935,8.2146 C8.07835,8.3606 7.74835,8.6766 7.38935,9.0001 L2.99985,9.0001 L2.99985,19.5001 L5.49985,19.5001 C6.23685,19.5001 6.87485,19.0946 7.22185,18.5001 L8.69135,18.5001 C9.21335,18.8636 10.93835,20.0001 12.13685,20.0001 L15.78885,20.0001 C18.02685,20.0001 19.94385,18.3276 20.24735,16.1101 C20.48285,14.3911 20.76435,12.3611 20.97535,10.8411 C21.07535,10.1261 20.86035,9.4031 20.38685,8.8591"
                fill="#374057"
              )
          span(data-testid="positive-count") {{ item.votes.positive_votes }}
        li(:disabled="item.votes.inProgress")
          svg(
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            data-testid="negative-vote"
            :class="item.votes.user_vote === 'negative' ? 'vote--negative' : ''"
            @click="voteReview({ id: item.id, vote: 'negative' })"
          )
            g#icon-thumb-down
              path#Fill-3(
                d="M19.98535,10.7036 C19.77385,12.2246 19.49235,14.2546 19.25685,15.9746 C19.02085,17.6991 17.52985,19.0001 15.78885,19.0001 L12.13685,19.0001 C11.26985,19.0001 9.68285,17.9861 9.14435,17.5951 L9.01335,17.5001 L7.49985,17.5001 L7.49985,10.2326 C7.90285,9.8901 8.74435,9.1551 9.00135,8.7851 C10.84535,6.1266 12.99985,4.3446 13.46885,4.0896 C13.65335,4.1006 13.78385,4.1511 13.85635,4.2396 C14.00535,4.4221 13.97435,4.7796 13.95235,4.8871 L13.94285,4.9516 C13.91635,5.2636 13.66535,5.8416 13.44385,6.3521 C13.01385,7.3436 12.67385,8.1271 13.01535,8.6471 C13.10135,8.7786 13.30885,9.0001 13.72635,9.0001 L18.49935,9.0001 C18.93435,9.0001 19.34735,9.1881 19.63235,9.5151 C19.91635,9.8411 20.04485,10.2746 19.98535,10.7036 M5.49985,18.5001 L3.99985,18.5001 L3.99985,10.0001 L6.49985,10.0001 L6.49985,17.5001 C6.49985,18.0511 6.05135,18.5001 5.49985,18.5001 M20.38685,8.8591 C19.91185,8.3131 19.22385,8.0001 18.49935,8.0001 L13.88935,7.9996 C13.95285,7.6921 14.20185,7.1186 14.36135,6.7501 C14.63185,6.1261 14.88785,5.5361 14.93635,5.0661 C14.97035,4.8836 15.07085,4.1536 14.63635,3.6141 C14.44285,3.3741 14.06985,3.0881 13.39135,3.0881 C12.75285,3.0881 10.10835,5.4341 8.17935,8.2146 C8.07835,8.3606 7.74835,8.6766 7.38935,9.0001 L2.99985,9.0001 L2.99985,19.5001 L5.49985,19.5001 C6.23685,19.5001 6.87485,19.0946 7.22185,18.5001 L8.69135,18.5001 C9.21335,18.8636 10.93835,20.0001 12.13685,20.0001 L15.78885,20.0001 C18.02685,20.0001 19.94385,18.3276 20.24735,16.1101 C20.48285,14.3911 20.76435,12.3611 20.97535,10.8411 C21.07535,10.1261 20.86035,9.4031 20.38685,8.8591"
                transform="translate(11.999620, 11.544100) scale(-1, -1) translate(-11.999620, -11.544100) "
              )
          span(data-testid="negative-count") {{ item.votes.negative_votes }}
</template>

<script>
import BlFlagHeadAv from '@bukalapak/bazaar-dweb/dist/components/BlFlagHeadAv';
import BlFlagBodyAv from '@bukalapak/bazaar-dweb/dist/components/BlFlagBodyAv';
import BlFlagMv from '@bukalapak/bazaar-dweb/dist/components/BlFlagMv';
import BlRatingMv from '@bukalapak/bazaar-dweb/dist/components/BlRatingMv';
import { humanizeTimestamp } from '@bukalapak/toolbox-helper/date-helper';
import { mapActions } from 'vuex';
import { isEmpty } from 'lodash';

import BlMediaMv from '~shared-js/components/BlMediaMv';

const PRIVATE_SUFFIX = ' (Ulasan Privat)';

export default {
  name: 'BlReviewsItemMv',
  components: {
    BlFlagHeadAv,
    BlFlagBodyAv,
    BlFlagMv,
    BlRatingMv,

    BlMediaMv,
  },

  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
      required: true,
    },
    section: {
      type: String,
      default: 'listReview',
    },
  },

  computed: {
    ratingVal() {
      return this.item.review && this.item.review.rate * 20;
    },

    ulasanDate() {
      return humanizeTimestamp(this.item.created_at, '%dd% %mmm%, %yyyy%');
    },

    isPrivate() {
      return this.item.sender.name.endsWith(PRIVATE_SUFFIX);
    },

    ulasanSender() {
      return this.item.sender.name.replace(PRIVATE_SUFFIX, '');
    },

    ulasanSenderClass() {
      return { 'u-mrgn-right--2': this.isPrivate };
    },
  },

  methods: {
    ...mapActions('productReviews', ['openDialog', 'voteReview']),
    isEmpty,

    imgUrl(url) {
      const arr = url.split('/');
      arr[6] = 'small';
      return arr.join('/');
    },
  },
};
</script>

<style src="./BlReviewsItemMv.style.scss" lang="scss"></style>
