<template>
  <div class="c-product-gallery__share">
    <div v-if="channel.copylink" class="c-product-gallery__share__link">
      <span ref="link" class="c-product-gallery__share__link__text">{{ channel.copylink }}</span>
      <BlButtonAv class="c-product-gallery__share__copy" @click="handleCopy">
        Salin
      </BlButtonAv>
    </div>
    <div class="c-product-gallery__share__socmed">
      <p>Atau bagikan di</p>
      <div class="c-product-gallery__share__socmed__link">
        <a
          v-if="channel.facebook"
          class="c-product-gallery__share__icon"
          rel="nofollow noopener"
          @click="openNewWindow('facebook')"
        >
          <img :src="getIconUrl('images/share-facebook.png')" />
        </a>

        <a
          v-if="channel.twitter"
          class="c-product-gallery__share__icon"
          rel="nofollow noopener"
          @click="openNewWindow('twitter')"
        >
          <img :src="getIconUrl('images/share-twitter.png')" />
        </a>

        <a
          class="c-product-gallery__share__icon"
          href="url_line"
          rel="nofollow noopener"
          target="_blank"
          @click="openNewWindow('line.me')"
        >
          <img :src="getIconUrl('images/share-line.png')" />
        </a>

        <a
          class="c-product-gallery__share__icon"
          data-pin-custom="true"
          data-pin-do="buttonBookmark"
          data-pin-shape="round"
          data-pin-log="button_pinit_bookmarklet"
          data-pin-href="https://www.pinterest.com/pin/create/button/"
          rel="nofollow noopener"
          target="_blank"
          @click="trackEvent('pinterest')"
        >
          <img :src="getIconUrl('images/share-pinterest.png')" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { BlButtonAv } from '@bukalapak/bazaar-dweb';
import { mapActions, mapState } from 'vuex';
import qs from 'qs';

import { productBranchUrl } from '~pdp/js/modules/api-modules';
import to from '~lib/to';
import { copyText } from '~shared-js/helpers/common-helpers';
import { productDetailSocialClicks } from '~shared-js/tracker/rage';
import '~shared-js/social-media-scripts';

const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php';
const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';

export default {
  name: 'BlSocialShareMv',

  components: {
    BlButtonAv,
  },

  inject: ['productDetailTvInjection'],

  data() {
    return {
      channel: {},
      lineUrl: 'https://social-plugins.line.me/lineit/share?url=',
    };
  },

  computed: {
    ...mapState({
      permalink: state => state.category.urlStructure[0],
    }),
  },

  mounted() {
    this.initChannel();
  },

  methods: {
    ...mapActions(['commitFlashMessage']),

    handleCopy() {
      try {
        copyText(this.$refs.link);
        this.commitFlashMessage({
          message: 'Berhasil menyalin tautan barang',
          type: 'success',
        });
      } catch (err) {
        // eslint-disable-next-line
        console.error(`ERROR on copying share link: ${err.message}`);
      }
    },

    initChannel() {
      this.getChannelUrls()
        .then(res => {
          this.channel = res.data.reduce((acc, val) => {
            let { url } = val;
            if (val.channel === 'facebook') {
              url = `${FACEBOOK_SHARE_URL}?u=${url}`;
            } else if (val.channel === 'twitter') {
              url = `${TWITTER_SHARE_URL}?text=${this.productDetailTvInjection.product.name}&url=${url}`;
            }

            return {
              ...acc,
              [val.channel]: url,
            };
          }, {});
        })
        .catch(() => ({}));
    },

    async getChannelUrls() {
      const [err, res] = await to(
        productBranchUrl.retrieveAllProductBranchUrl(this.productDetailTvInjection.product.id),
      );
      if (!err) {
        return res;
      }
      return Promise.reject(err);
    },

    openNewWindow(channel) {
      let url = '';
      if (channel === 'facebook' || channel === 'twitter') url = this.channel[channel];
      else if (channel === 'line.me') url = this.setUrlProductShare(this.lineUrl, channel);

      global.open(
        url,
        '',
        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600',
      );

      this.trackEvent(channel);
    },

    getIconUrl(link) {
      return global.assetUrl && global.assetUrl(link);
    },

    setUrlProductShare(url, channel) {
      const queryParams = {
        utm_source: channel,
        utm_medium: 'bukalapakpageshare',
        utm_term: this.permalink,
        utm_campaign: 'productshare',
      };
      const encodedQs = encodeURIComponent(qs.stringify(queryParams));

      return `${url}${global.location.href}?${encodedQs}`;
    },

    trackEvent(channel) {
      productDetailSocialClicks.track({
        productId: this.productDetailTvInjection.product.id,
        channel,
      });
    },
  },
};
</script>

<style lang="scss" src="./BlSocialShareMv.style.scss"></style>
