export const VARIATION = [
  'primary',
  'secondary',
  'outline',
  'outline-expressive',
  'ghost',
  'destructive',
];

export const SIZE = ['large', 'medium', 'small', 'extra-small'];
