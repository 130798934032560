<template>
  <component
    :is="buttonComponent"
    v-bind="buttonProps"
    class="c-main-product__action__cart"
    @click="$emit('click')"
  >
    <BlSpinnerAv v-if="isAddingCart" size="small" />
    <template v-else>
      <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path
            d="M7 18.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M15 18.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M16.21 10.193L15.744 13H4.561L3.524 6h6.001a5.004 5.004 0 0 1 0-1h-6.15l-.254-1.72A1.509 1.509 0 0 0 1.637 2H0v1h1.637c.246 0 .459.183.495.427L3.879 15.22c.108.73.746 1.28 1.484 1.28H15.5v-1H5.363a.504.504 0 0 1-.495-.427L4.708 14h11.035a1 1 0 0 0 .986-.836l.59-3.535c-.341.233-.714.42-1.108.564"
            fill="#364058"
          />
          <path d="M19.5 5.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0zM14.5 3v5M17 5.5h-5" stroke="#364058" />
        </g>
      </svg>
      &nbsp;Masukkan Keranjang
    </template>
  </component>
</template>

<script>
import { BlButtonAv, BlSpinnerAv } from '@bukalapak/bazaar-dweb';
// import BlButtonV2Av from '@bukalapak/bazaar-dweb-v2/dist/components/BlButtonAv';
import BlButtonV2Av from '~pdp-av/BlButtonV2Av';

export default {
  name: 'BlCartButtonMv',

  components: {
    BlButtonAv,
    BlSpinnerAv,

    BlButtonV2Av,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isAddingCart: {
      type: Boolean,
      default: false,
    },
    isWhiteHeader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonComponent() {
      return this.isWhiteHeader ? 'BlButtonV2Av' : 'BlButtonAv';
    },

    buttonProps() {
      const attrs = {
        disabled: this.disabled,
      };

      if (this.isWhiteHeader) {
        attrs.variation = 'outline';
      }

      return attrs;
    },
  },
};
</script>
