<template lang="pug">
  .c-seller__header
    a.c-avatar(:href="storeUrl" rel="nofollow")
      BlMediaMv(:src="store.avatar_url" :alt="store.name")

    .c-seller__info.u-mrgn-right--4.u-mrgn-left--4
      h3.c-seller__name.u-mrgn-bottom--1
        a.c-link--primary--black(
          :href="storeUrl"
          rel="nofollow"
        ) {{ store.name }}
      a.c-seller__city.u-mrgn-bottom--2(:href="cityUrl" rel="nofollow") {{ store.address && store.address.city }}
      .c-seller__badges(v-if="isBrand || isSuperSeller || store.level")
        .c-seller__badge-brand(v-if="isBrand")
          BlMediaMv(:src="bukamallLogo" style="width: 78px")

        template(v-else-if="isSuperSeller && isFinishedFetchBadgeToggle")
          .c-badges__new-super-seller.u-mrgn-right--2(v-if="isSuperSellerNewBadgeActive")
          .c-badges__super-seller.u-mrgn-right--2(v-else) Super Seller

        .c-seller__level(v-if="!isBrand && store.level")
          BlMediaMv(:src="store.level.image_url" style="width: 24px")
          span.u-mrgn-left--1.u-txt--base {{ store.level.name }}

    div
      pdp-client-only
        bl-button-av.c-seller__admin(
          v-if="me && me.isAdUser"
          size="small"
          :href="`/policies/u/${store.username}`"
        ) Admin
      bl-button-av.c-seller__visit-link(
        :href="storeUrl"
        rel="nofollow"
      )
        i.c-btn__icon.c-icon
          svg(width='18', height='18', xmlns='http://www.w3.org/2000/svg')
            g(fill='#333', fill-rule='evenodd')
              path(d='M15.79 0H2.21L0 4.5V5a2.493 2.493 0 001.378 2.222c.086.043.172.083.263.116.064.025.132.043.199.062.068.019.136.041.207.054.147.029.297.046.453.046h13c.156 0 .306-.018.454-.046.07-.013.138-.035.207-.054.067-.019.134-.037.199-.062a2.63 2.63 0 00.353-.166A2.493 2.493 0 0018 5v-.5L15.79 0zM17 8.157a3.47 3.47 0 01-1.5.343h-3v5h1v1H5v-1h1v-5H2.5A3.47 3.47 0 011 8.157V16H0v2h18v-2h-1V8.157z')

        span.c-btn__text Kunjungi Lapak
</template>

<script>
import BlAvatarAv from '@bukalapak/bazaar-dweb/dist/components/BlAvatarAv';
import BlButtonAv from '@bukalapak/bazaar-dweb/dist/components/BlButtonAv';
import { kebabCase } from 'lodash';
import { mapState } from 'vuex';

import BlMediaMv from '~shared/javascripts/components/BlMediaMv';

export default {
  name: 'BlSellerHeaderMv',

  components: {
    BlAvatarAv,
    BlButtonAv,
    BlMediaMv,
  },

  props: {
    store: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      bukamallLogo: 'https://s1.bukalapak.com/pavilion/1587095550468/original/bukamall-logo.png',
    };
  },

  computed: {
    ...mapState(['category', 'me', 'isFinishedFetchBadgeToggle', 'isSuperSellerNewBadgeActive']),

    storeUrl() {
      return this.store.url && this.store.url.replace(/^http:\/\//i, 'https://');
    },

    cityUrl() {
      if (this.store.address && this.store.address.city) {
        const kebabCity = kebabCase(this.store.address.city);
        return `${this.category.pathname}/l-${kebabCity}`;
      }

      return '';
    },

    isBrand() {
      return this.store && this.store.brand_seller;
    },

    isSuperSeller() {
      return this.store && this.store.premium_top_seller;
    },
  },
};
</script>

<style lang="scss" src="./BlSellerHeaderMv.scss"></style>
