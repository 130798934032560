import Experiments from '@bukalapak/orchid/services/_exclusive/experiments';
import Neo from '@bukalapak/orchid/services/_exclusive/neo';
import adapter from '../request-adapter';

export const experiments = new Experiments(adapter);
export const neo = new Neo(adapter);

export default {
  experiments,
  neo,
};
