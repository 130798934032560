import Cookies from 'js-cookie';

/**
 * Get the value from the element's data attribute
 *
 * @param {String} selector
 * @param {String} name
 * @returns {String}
 */
export const getDataByElement = (selector, name) => {
  const el = global.document && global.document.querySelector(selector);

  if (el) {
    return name ? el.dataset[name] : el.dataset;
  }
  return null;
};

/**
 * For copying text from an element to user's clipboard
 *
 * @param {HTMLElement} el
 * @param {Boolean=} removeSelection
 * @returns {void}
 */
export const copyText = (el, removeSelection = true) => {
  if (document) {
    if (document.body.createTextRange) {
      const range = document && document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
      document.execCommand('copy');
    } else if (global.getSelection) {
      const selection = global.getSelection();
      const range = document && document.createRange();
      range.selectNodeContents(el);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');

      if (removeSelection) selection.removeAllRanges();
    }
  }
};

/**
 * Get the type of a variable
 *
 * @param {any} source
 * @returns {String}
 */
export const getType = source =>
  ({}.toString
    .call(source)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase());

export const isArray = source => getType(source) === 'array';

export const isObject = source => getType(source) === 'object';

export const isNumber = source => typeof source === 'number';

export const isString = source => typeof source === 'string';

export const isFunction = source => typeof source === 'function';

/**
 * Get the browser id from cookie
 * @return {String}
 */
export const getBrowserId = () => Cookies.get('browser_id') || '';

/**
 * Get the browser id from cookie
 * @return {String}
 */
export const getIdentity = () => Cookies.get('identity') || '';

/**
 * Get the session id from cookie
 * @return {String}
 */
export const getSessionId = () => Cookies.get('session_id') || '';

/**
 * Get duration from load until current time
 * @returns {Number}
 */
export const getLoadTime = (fixedTimeOrigin) => {
  const origin = fixedTimeOrigin || window.performance?.timeOrigin;
  if (origin) return Date.now() - origin;
  return -1;
};

export default {
  copyText,
  getBrowserId,
  getDataByElement,
  getIdentity,
  getSessionId,
  getType,
  isArray,
  isFunction,
  isNumber,
  isObject,
  isString,
  getLoadTime,
};
