import { getInitialState } from './state';

export const SET_BASE_REVIEW_STATE = 'SET_BASE_REVIEW_STATE';
export const RESET_REVIEW_STATE = 'RESET_REVIEW_STATE';

const initialPagination = {
  limit: 5,
  offset: 0,
};

export default {
  setFilter(state, filter) {
    state.filter = filter;
    state.pagination.offset = 0;
  },

  setPagination(state, pagination = initialPagination) {
    state.pagination = pagination;
  },

  [SET_BASE_REVIEW_STATE](state, { name, payload }) {
    state[name] = payload;
  },

  [RESET_REVIEW_STATE](state) {
    const initialState = getInitialState();
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },
};
