<template lang="pug">
  .u-mrgn-top--8
    template(v-if="store.term_and_condition")
      h3.u-txt--fair.u-txt--bold.u-mrgn-bottom--3 Catatan Pelapak
      div
        p.u-fg--ash-dark
          small
            | Catatan Pelapak tetap tunduk terhadap
            a(href="/terms" rel="noopener noreferrer nofollow") &#32;Aturan penggunaan Bukalapak.

        bl-collapsible-detail-mv
          template(slot="content")
            div(v-html="store.term_and_condition")

    a.c-seller__report(:href="reportUrl" rel="noopener nofollow noreferrer" target="_blank")
      ico-flag(color="#999")
      | &#32;Laporkan Pelapak
</template>

<script>
import { IcoFlag } from '@bukalapak/bazaar-visual-v1';

const BlCollapsibleDetailMv = () =>
  import(/* webpackChunkName: "bl-collapsible-detail-mv" */ '~pdp-mv/BlCollapsibleDetailMv');

export default {
  name: 'BlSellerTermMv',

  components: {
    BlCollapsibleDetailMv,
    IcoFlag,
  },

  props: {
    store: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      reportUrl: '',
    };
  },

  mounted() {
    if (this.store.id) {
      this.reportUrl = `/users/${this.store.id}/report`;
    }
  },
};
</script>

<style lang="scss" src="./BlSellerTermMv.scss"></style>
