export default function uniqueInstallment(installments) {
  const installmentObject = {};
  installments.forEach((installment) => {
    const bankIssuer = installment.bank_issuer;
    if (installmentObject[bankIssuer]) {
      installmentObject[bankIssuer].terms = [
        ...installmentObject[bankIssuer].terms,
        ...installment.terms,
      ];

      installmentObject[bankIssuer].terms = [...new Set(installmentObject[bankIssuer].terms)];
      installmentObject[bankIssuer].terms.sort((a, b) => a - b);
    } else {
      installmentObject[bankIssuer] = installment;
    }
  });
  return installmentObject;
}
