export const isOnDeal = (start, end) => {
  if (!start || !end) {
    return false;
  }

  const today = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);

  return today >= startDate && today <= endDate;
};

export default { isOnDeal };
