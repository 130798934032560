<template lang="pug">
  svg(
      width='28px'
      height='16px'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlns:xlink='http://www.w3.org/1999/xlink'
    )
      g(fill='none' fill-rule='evenodd')
        path(stroke='#374058' d='M17.5 11.5V.5h-16V2')
        path(stroke='#FEFEFE' d='M.5 8h2M.5 7.5h2')
        path(
          d='M11.5 5H10a.5.5 0 110-1h1.5a.5.5 0 110 1M15.5 5H14a.5.5 0 110-1h1.5a.5.5 0 110 1'
          fill='#374058'
        )
        path(stroke='#FEFEFE' d='M.5 4.5h2')
        path(
          d='M15.5 8H14V7h1a.5.5 0 100-1h-1V4h-1v5h2.5a.5.5 0 100-1M11.5 8H10V7h1a.5.5 0 100-1h-1V4H9v5h2.5a.5.5 0 100-1M4 4H1.5v4.5a.5.5 0 101 0V7h1a.5.5 0 100-1h-1V5H4a.5.5 0 100-1M6.501 6H6V5h.498c.277 0 .502.225.502.502A.499.499 0 016.501 6m-.003-2H5v4.5a.5.5 0 101 0V7h.498c.277 0 .502.225.502.502V8.5a.5.5 0 101 0v-.998c0-.386-.15-.735-.39-1.001.24-.266.39-.614.39-1C8 4.675 7.326 4 6.498 4'
          fill='#374058'
        )
        path(
          d='M20.5 13.5h2v-5l-1.703-2.555a1 1 0 00-.832-.445H17.5V12M3.5 13.5h-2V11M16.5 13.5h-9'
          stroke='#374058'
        )
        path(
          d='M18.5 11a2.5 2.5 0 100 5 2.5 2.5 0 000-5m0 1c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5-1.5-.673-1.5-1.5.673-1.5 1.5-1.5M5.5 11a2.5 2.5 0 100 5 2.5 2.5 0 000-5m0 1c.827 0 1.5.673 1.5 1.5S6.327 15 5.5 15 4 14.327 4 13.5 4.673 12 5.5 12'
          fill='#374058'
        )
</template>

<script>
export default {
  name: 'FreeShippingSvg',
};
</script>