import { render, staticRenderFns } from "./BlMainWholesaleMv.vue?vue&type=template&id=9570baf6&lang=pug&"
import script from "./BlMainWholesaleMv.vue?vue&type=script&lang=js&"
export * from "./BlMainWholesaleMv.vue?vue&type=script&lang=js&"
import style0 from "./BlMainWholesaleMv.style.scss?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports