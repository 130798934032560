import Addresses from '@bukalapak/orchid/services/addresses';
import ChatOnlines from '@bukalapak/orchid/services/chat/onlines';
import Favorites from '@bukalapak/orchid/services/favorites';
import FeedMnm from '@bukalapak/orchid/services/feeds/mnm';
import GeocoderAddressGroups from '@bukalapak/orchid/services/geocoders/addresses-groups';
import Me from '@bukalapak/orchid/services/me';
import Products from '@bukalapak/orchid/services/products';
import ProductReviews from '@bukalapak/orchid/services/product-reviews';
import ProductVariantDetails from '@bukalapak/orchid/services/product-variants/details';
import PromotedPushBudget from '@bukalapak/orchid/services/promoted-pushes/budget';
import PromotedPushProducts from '@bukalapak/orchid/services/promoted-pushes/products';
import PushPrice from '@bukalapak/orchid/services/pushes/prices';
import PushBalance from '@bukalapak/orchid/services/pushes/balance';
import StoreLabels from '@bukalapak/orchid/services/stores/labels';
import StoreMeProducts from '@bukalapak/orchid/services/stores/me/products';
import StoreVoucher from '@bukalapak/orchid/services/stores/premium-vouchers';
import ProductBranchUrl from '@bukalapak/orchid/services/_exclusive/products/branch-urls';
import Neo from '@bukalapak/orchid/services/_exclusive/neo';
import RelatedKeywords from '@bukalapak/orchid/services/searches/related-keywords';
import PushWhitelist from '@bukalapak/orchid/services/paid-promotion/pushes/whitelists';
import ShippingFees from '@bukalapak/orchid/services/discounts/shipping-fees';
import DigitalBankingUsers from '@bukalapak/orchid/services/_exclusive/digital-banking/users';
import PromoCoupons from '@bukalapak/orchid/services/promos/coupons';
import ClaimCampaign from '@bukalapak/orchid/services/_exclusive/claim-campaigns';

import adapter from '~shared-js/modules/request-adapter';

import interfaces from './extra-interfaces';

const wrapSecureClientInterface = global.API && global.API.wrap;
export const extraApi = wrapSecureClientInterface ? wrapSecureClientInterface(interfaces) : {};

export const addresses = new Addresses(adapter);
export const chatOnlines = new ChatOnlines(adapter);
export const favorites = new Favorites(adapter);
export const feedMnm = new FeedMnm(adapter);
export const geocoderAddressGroups = new GeocoderAddressGroups(adapter);
export const me = new Me(adapter);
export const pushPrice = new PushPrice(adapter);
export const pushBalance = new PushBalance(adapter);
export const productBranchUrl = new ProductBranchUrl(adapter);
export const productService = new Products(adapter);
export const productReviews = new ProductReviews(adapter);
export const productVariantDetails = new ProductVariantDetails(adapter);
export const promotedPushBudget = new PromotedPushBudget(adapter);
export const promotedPushProducts = new PromotedPushProducts(adapter);
export const storeLabels = new StoreLabels(adapter);
export const storeMeProducts = new StoreMeProducts(adapter);
export const storeVoucher = new StoreVoucher(adapter);
export const neo = new Neo(adapter);
export const relatedKeywords = new RelatedKeywords(adapter);
export const pushWhitelist = new PushWhitelist(adapter);
export const shippingFees = new ShippingFees(adapter);
export const digitalBankingUser = new DigitalBankingUsers(adapter);
export const promoCoupons = new PromoCoupons(adapter);
export const claimCampaign = new ClaimCampaign(adapter);

export default {
  extraApi,
  addresses,
  chatOnlines,
  favorites,
  feedMnm,
  geocoderAddressGroups,
  me,
  pushPrice,
  pushBalance,
  productBranchUrl,
  productService,
  productReviews,
  productVariantDetails,
  promotedPushBudget,
  promotedPushProducts,
  storeLabels,
  storeMeProducts,
  storeVoucher,
  neo,
  relatedKeywords,
  pushWhitelist,
  shippingFees,
  digitalBankingUser,
  promoCoupons,
  claimCampaign,
};
