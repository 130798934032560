export const getInitialState = () => ({
  id: null,
  base36Id: null,
  product: {},
  rawProduct: {},
  selectedVariant: {
    id: null,
    name: '',
    product: {},
  },
  defaultVariant: {
    id: null,
    name: '',
    product: {},
  },
  loading: 0,
  isAddingCart: false,
  isOnCheckout: false,
});

export default getInitialState;
