<template lang="pug">
  .c-reviews__summary.section-main-content
    .c-reviews__summary-pie
      .summary-pie
        bl-progress-circular-av(
          :value="productRatePercentage"
          stroke-color="#ffc53e"
          :size="80"
        )
          span.summary__score {{ rateNumber }}

      .c-star-percentage
        bl-rating-mv(:value="productRatePercentage" :max-ratings="5" size="medium")
        p dari {{ totalRating }} ulasan

    .c-reviews__summary-list(
      v-if="ratings.length"
      :class="{ 'c-reviews__summary-list--filtered': isFiltered }"
    )
      temp-var(
        v-for="(rating, index) in ratings"
        :key="index"
        :vars=`{
          starNum: 5 - index,
          ratePercent: countRatePercentage(rating),
        }`
      )
        .c-reviews__summary-list__item(
          slot-scope="{ starNum, ratePercent }"
          v-scroll-to="scrollEl"
          :class="setListItemClass(starNum, ratePercent)"
          @click="commitFilter(starNum)"
        )
          .list-item__star
            span.c-product-details-rating__num {{ starNum }}
            bl-rating-mv(:value="100" :max-ratings="1" variant="star")
          .list-item__bar
            bl-progress-bar-av(:percentage="ratePercent" color="yellow")
          .list-item__percentage {{ ratePercent }}%
</template>

<script>
import { mapActions } from 'vuex';
import BlProgressBarAv from '@bukalapak/bazaar-dweb/dist/components/BlProgressBarAv';
import BlRatingMv from '@bukalapak/bazaar-dweb/dist/components/BlRatingMv';

import BlProgressCircularAv from '~pdp-av/BlProgressCircularAv';

export default {
  name: 'BlReviewsSummaryMv',

  components: {
    BlProgressCircularAv,
    BlRatingMv,
    BlProgressBarAv,
  },

  props: {
    rate: {
      type: Number,
      default: 0,
    },
    review: {
      type: Object,
      required: true,
    },
    star: {
      type: [Number, Boolean],
      default: 0,
    },
  },

  data() {
    return {
      scrollEl: {
        el: '.c-reviews__body',
        easing: 'ease-in-out',
        offset: -100,
      },
    };
  },

  computed: {
    productRatePercentage() {
      if (this.rate) {
        return (this.rate / 5).toFixed(2) * 100;
      }

      return 0;
    },
    rateNumber() {
      if (this.rate) {
        return this.rate.toFixed(1);
      }

      return 0;
    },
    isFiltered() {
      return this.star !== 0 && this.star < 6;
    },
    ratings() {
      return this.review.ratings || (this.review.meta && this.review.meta.aggregates.ratings) || [];
    },
    totalRating() {
      return this.review.total || (this.review.meta && this.review.meta.total);
    },
  },

  methods: {
    ...mapActions('productReviews', ['commitFilter']),

    countRatePercentage(rate = 0) {
      if (this.review.total || (this.review.meta && this.review.meta.total)) {
        return parseFloat(
          (
            (rate / (this.review.total || (this.review.meta && this.review.meta.total))) *
            100
          ).toFixed(1),
        );
      }
      return 0;
    },

    setListItemClass(starNum, rate) {
      if (!this.isFiltered || !this.star) {
        return '';
      }

      if (starNum === this.star) {
        return 'item--selected';
      }

      return {
        'item--dark': rate > 0,
        'item--light': rate === 0,
      };
    },
  },
};
</script>

<style src="./BlReviewsSummaryMv.style.scss" lang="scss"></style>
