import Tracker from '~lib/tracker';

const isDev = process.env.NODE_ENV !== 'production';

/**
 * TODO: Remove this prototype if all tracker have been replaced by 'class TrackerPrototype'
 */
const tPrototype = {
  /**
   * Initialize instance 'data'
   * @param {Object} data
   */
  init(data) {
    this.data = data;
  },
  get() {
    return this.data;
  },
  async track() {
    try {
      if (navigator.sendBeacon) {
        const data = {
          ...this.data,
          ...Tracker.$uaData,
        };
        return navigator.sendBeacon(`${Tracker.$config.baseUrl}/e`, JSON.stringify(data));
      }

      return Tracker.event(this.data).catch(e => {
        this.log(e);
      });
    } catch (e) {
      this.log(e);
    }
    return Promise.reject(false); // eslint-disable-line
  },
};

const tracker = data => {
  function TrackerPrototype() {}
  TrackerPrototype.prototype = tPrototype;
  const f = new TrackerPrototype();
  f.init(data);
  return f;
};

export class TrackerPrototype {
  constructor({ pretrack } = {}) {
    // A function to trigger before do track
    this.pretrack = pretrack || null;
  }

  init(data) {
    this.data = data;
  }

  set(name, value) {
    this.data[name] = value;
  }

  setBulk(data) {
    Object.assign(this.data, data);
  }

  /* eslint-disable prefer-promise-reject-errors */
  track(payload) {
    try {
      const data = {
        ...this.data,
        ...(this.pretrack ? this.pretrack(payload) : {}),
      };

      if (navigator.sendBeacon) {
        Object.assign(data, Tracker.$uaData);
        return navigator.sendBeacon(`${Tracker.$config.baseUrl}/e`, JSON.stringify(data));
      }

      return Tracker.event(data).catch(e => {
        this.log(e);
      });
    } catch (e) {
      this.log(e);
    }
    return Promise.reject(false);
  }

  trackBulk(payload = []) {
    if (payload.length) {
      try {
        const data = payload.map(item => ({
          ...this.data,
          ...(this.pretrack ? this.pretrack(item) : {}),
        }));

        if (navigator.sendBeacon) {
          data.forEach(item => Object.assign(item, Tracker.$uaData));
          return navigator.sendBeacon(`${Tracker.$config.baseUrl}/es`, JSON.stringify(data));
        }

        return Tracker.eventBulk(data).catch(e => {
          this.log(e);
        });
      } catch (e) {
        this.log(e);
      }
      return Promise.reject(false);
    }
    return Promise.resolve(true);
  }

  pixel(payload) {
    try {
      const data = {
        ...this.data,
        ...(this.pretrack ? this.pretrack(payload) : {}),
      };
      return Tracker.sendPixel(data).catch(e => {
        this.log(e, 'pixel');
      });
    } catch (e) {
      this.log(e, 'pixel');
    }
    return Promise.reject(false);
  }

  log(e, type = 'event') {
    if (isDev) {
      /* eslint-disable no-console */
      console.group(`ERROR Tracker - ${type}`);
      console.warn(`Failed request on Tracker "${this.data.evn}"`);
      console.warn(e);
      console.groupEnd();
    }
  }
}

export default tracker;
