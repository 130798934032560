import { neo } from '~shared-js/modules/api-modules';

export const retrieveTogglesStatus = (ids, platform = 'bl_web') => {
  if (ids) {
    if (!Array.isArray(ids)) {
      throw new Error(`Must be array of toggle ids`);
    }
    if (ids.length === 0) {
      throw new Error(`Required toggle ids`);
    }

    return neo.$toggles.retrieveTogglesStatus({
      data: {
        id: ids,
        platform,
      },
    });
  }
  return Promise.resolve();
};

export default {
  retrieveTogglesStatus,
};
