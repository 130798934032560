export const SET_BASE_SHIPPING_STATE = 'SET_BASE_SHIPPING_STATE';
export const SET_FEES = 'SET_FEES';
export const SET_GEOCODER_ADDRESSES = 'SET_GEOCODER_ADDRESSES';

const mutations = {
  [SET_BASE_SHIPPING_STATE](state, { name, payload }) {
    state[name] = payload;
  },

  [SET_FEES](state, shippings) {
    state.fees = shippings.reduce((list, courier) => {
      const services = courier.services.map(subService => ({
        ...subService,
        eta: subService.eta && subService.eta.toString(),
        courierName: courier.courier_name,
      }));

      return list.concat(services);
    }, []);
    state.feesGroups = shippings;
  },

  [SET_GEOCODER_ADDRESSES](state, geocoderAddresses) {
    const flattenAddress = geocoderAddresses.reduce(
      (list, groupAddress) =>
        list.concat(
          groupAddress.addresses.map(address => ({
            ...address,
            groupName: groupAddress.group_name,
            maxScore: groupAddress.max_score,
          })),
        ),
      [],
    );

    state.geocoderAddressesGroups = geocoderAddresses;
    state.geocoderAddresses = flattenAddress;
  },
};

export default mutations;
