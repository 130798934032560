<template lang="pug">
  bl-flex-container-mv.u-mrgn-top--3(align-items="center")
    bl-flex-item-av
      bl-expansions-mv.c-courier-sort(
        :active.sync="isShow"
        ref="courierList"
      )
        template(v-if="activeCourier.courierName" slot="trigger")
          CourierListMv(
            :value="activeCourier"
            :hasFee="hasFee"
            :class="hasFee ? '' : 'without-fee'"
          )
          span.c-courier-sort__button-arrow
            i.c-icon.c-icon--medium(:class="isShow ? 'c-icon--chevron-up' : 'c-icon--chevron-down'")

        template(slot="content")
          bl-sort-table-mv(
            type="div"
            class="c-courier-sort__table"
            :values="values"
            asc-icon="<i class='c-icon c-icon--chevron-up' />"
            desc-icon="<i class='c-icon c-icon--chevron-down' />"
            ref="sortTable"
          )
            .c-courier__head(slot="head" v-if="hasFee")
              .c-courier__head-content
                span Kurir
              .c-courier__head-content.-sortable
                bl-sort-link-mv(name="etaNumber") Waktu Kirim
              .c-courier__head-content.-sortable
                bl-sort-link-mv(name="price") Harga
            .c-courier__body(
              slot="body"
              slot-scope="sort"
              ref="sortBody"
            )
              CourierListMv(
                v-for="(value, index) in sort.values"
                :key="index"
                :class="hasFee ? '' : 'without-fee'"
                :value="value"
                :hasFee="hasFee"
                @click="selectCourier"
              )

    bl-flex-item-av.u-mrgn-left--4(v-if="!hasFee && values.length" basis="unset")
      div Tersedia {{ values.length }} kurir

</template>

<script>
import BlPriceAv from '~pdp/components/atoms/BlPriceAv';
import BlExpansionsMv from '~pdp-mv/BlExpansionsMv';
import { BlFlexContainerMv, BlFlexItemAv } from '~pdp-mv/BlFlexMv';
import { BlSortTableMv, BlSortLinkMv } from '~pdp-mv/BlSortTableMv';

import CourierListMv from '../CourierListMv';

export default {
  name: 'CourierSortMv',
  components: {
    BlFlexItemAv,
    BlPriceAv,
    BlFlexContainerMv,

    BlSortTableMv,
    BlSortLinkMv,
    BlExpansionsMv,
    CourierListMv,
  },

  props: {
    activeCourier: {
      type: Object,
      default: () => ({}),
    },

    hasFee: {
      type: Boolean,
      default: true,
    },

    values: {
      type: Array,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      isShow: false,
    };
  },

  methods: {
    checkHasContent(refName, target) {
      return this.$refs[refName].contains(target);
    },

    selectCourier(service) {
      this.isShow = false;
      const activeCourier = this.values.find(val => val.service === service);

      this.$emit('update:activeCourier', activeCourier);
    },
  },
};
</script>

<style src="./CourierSortMv.scss"></style>
