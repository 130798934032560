import { simpleNumber } from '@bukalapak/toolbox-helper/number-helper';
import { humanizeTimestamp } from '@bukalapak/toolbox-helper/date-helper';
import _ from 'lodash';
import qs from 'qs';

import loginAction from '~pdp/js/constants/login-action';
import { isArray } from '~shared-js/helpers/common-helpers';

export const isOnDeal = (start, end) => {
  if (!start || !end) {
    return false;
  }

  const today = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);

  return today >= startDate && today <= endDate;
};

/**
 * weightConverter
 *
 * @param {number} weight - weight number in 'gram'
 * @param {string} type - type to convert from 'gram'
 * @param {boolean} concat - if true, value of weight will be concatenated with METRIC_NAME.
 * @returns {number|string}
 */
export const weightConverter = (weight, type = 'gr', concat = false) => {
  let w = weight;
  const METRIC_NAME = {
    mg: 'miligram',
  };

  if (type === 'mg') {
    w *= 1000;
  }

  if (!concat) {
    return w;
  }

  switch (type) {
    case 'mg':
      return `${w} ${METRIC_NAME[type]}`;

    default:
      return simpleNumber(w, [' gram', ' kilogram']);
  }
};

/**
 * getSingleTimeFormat
 * Minimizing time seconds to minutes or to hours or to days if possible
 * if seconds is bigger than 59 convert to minutes
 * if minutes bigger than 59 convert to hours
 * if hours bigger than 23 convert to days
 *
 * @param {Number} secondsValue - Time value in seconds
 * @returns {String} - Formatted time string
 */
export function getSingleTimeFormat(secondsValue) {
  let convertedTime = Math.round(secondsValue);
  let timeFlag = 'detik';

  if (secondsValue > 59) {
    // to minutes
    convertedTime = Math.round(secondsValue / 60);
    timeFlag = 'menit';
  }
  if (convertedTime > 59) {
    // to hours
    convertedTime = Math.round(convertedTime / 60);
    timeFlag = 'jam';
  }
  if (convertedTime > 23) {
    // to days
    convertedTime = Math.round(convertedTime / 24);
    timeFlag = 'hari';
  }

  return `${convertedTime} ${timeFlag}`;
}

/**
 * Get safe format for product description
 * Wraps unsafe link
 *
 * @param {String} text - Product description
 * @returns {String} - Formatted product description
 */
export const formatDescription = (text, className) => {
  const blUrlRegex =
    /(?![^<]*>|[^<>]*<\/)(https?:\/\/)?(\w*\.)?(bukalapak\.com|bl\.id)((\?|\/)[-\w@:%_+.~#?&//=]*)?/g;

  const linkNode = (match) =>
    `<a href="${match}" class="${className}" target="_blank" rel="noopener noreferrer">${match}</a>`;
  // replace Url
  return text.replace(blUrlRegex, linkNode);
};

/**
 * Cleanup possibility of repetitive queryParams
 * currently from /login could return query params like:
 *   ?from=localhost/p/something?from=localhost/p/anotherthing?foo=bar&bar=false&from=anotherlocalhost?foo=bar
 * above should be parse into from=localhost/p/something&foo=bar&bar=false
 *
 * @todo Should provide deep unique checking ??
 *
 * @param {Object} params query params from url
 * @param {Array} toOmitParams array of params to be omitted
 * @returns {Object}
 */
export const pickLoginParams = (params, toOmitParams = []) => {
  let newParams = {};
  // Get unique params
  if (!_.isEmpty(params)) {
    Object.keys(params).forEach((key) => {
      const val = params[key];
      // if val is array, that means there are params with same key, get the 1st one only
      if (isArray(val)) {
        const uniq = _.uniq(val);
        if (uniq[0]) {
          newParams[key] = uniq[0];
        }
      } else {
        newParams[key] = val;
      }
    });
  }
  newParams = _.omit(newParams, toOmitParams);
  return newParams;
};

/**
 * Redirect user to login page with comeback query string
 * The comeback url should be in encoded format
 *
 * @param {String} from variant of 'from' from loginAction
 * @param {Object} queryParams object of query to params to append in comebackUrl
 *
 * @returns {String}
 *
 */
export const setLoginComebackUrl = (from, { customPath = '', queryParams } = {}) => {
  const { location: { origin, pathname, search } = {} } = global;

  // need extra 'escape' for query string
  const queryString = encodeURIComponent(
    qs.stringify(queryParams, { arrayFormat: 'brackets', encode: false }),
  );

  /* Note about encode param
   * %26 = &, %3f = ?, %5B = [, %5D = ], %25 = %
   */
  let comebackUrl = '';
  if (
    from === loginAction.checkout ||
    from === loginAction.addToCart ||
    from === loginAction.favorite
  ) {
    const pdpQueryString = search ? `${encodeURIComponent(search)}%26` : '%3f';
    comebackUrl = encodeURIComponent(`${origin}${pathname}`);
    comebackUrl = `${comebackUrl}${pdpQueryString}${queryString}`;
  } else if (from === loginAction.chat && customPath) {
    comebackUrl = encodeURIComponent(`${origin}/${customPath}`);
    comebackUrl = `${comebackUrl}%3f${queryString}`;
  }

  return `/login?comeback=${comebackUrl}`;
};

const DEFAULT_STORE_CLOSED = 'Yah, Lapaknya Tutup Sementara';
const DEFAULT_NO_STOCK = 'Stok Habis';
export const getProductAvailabilityState = (product) => {
  const state = {};

  if (!_.isEmpty(product)) {
    const { store = {} } = product;
    if (store.closing?.closed) {
      state.reason = store.closing.closed_reason || DEFAULT_STORE_CLOSED;
      state.reopenDate = store.closing.reopen_date
        ? humanizeTimestamp(store.closing.reopen_date, '%dd% %month% %yyyy%')
        : '';
    }

    if (product.stateDescription?.length) {
      state.reason = product.stateDescription[0];
    } else if (_.isEmpty(state)) {
      const noStock = product.hasVariant === false && product.stock < product.minQuantity;

      if (noStock || !product.forSale) {
        state.reason = DEFAULT_NO_STOCK;
      }
    }
  }

  return !_.isEmpty(state) && state;
};

const getSkuPrice = ({ product, selectedSku = null }) => {
  if (!selectedSku) {
    return product.price;
  }
  if (selectedSku.discount > 0) {
    return selectedSku.price - selectedSku.discount;
  }
  return selectedSku.price;
};

const isDiscounted = ({ product, defaultSku = null, subsidyEnabled }) => {
  const { deal } = product;
  if (subsidyEnabled) {
    return defaultSku ? defaultSku.discount > 0 : product.discountPercentage > 0;
  }
  return (
    (deal && isOnDeal(deal.applied_at, deal.expired_at) && deal.percantage > 0) ||
    deal.original_price !== deal.discount_price
  );
};

// Technically this is the same getPrice method like in `product.js` but we considering the variant price also in this method
// The param `product` should be fetched from store since in the store it has more attributes
// If any you assigned to refactoring this logic, I suggest you to store all the attribtues in `product.js` so we can inject method this method or any additional method in the future
export const getProductPrice = ({ product, subsidyEnabled, productSku }) => {
  if (subsidyEnabled) {
    return getSkuPrice({ product, selectedSku: productSku });
  }
  if (isDiscounted({ product, defaultSku: productSku, subsidyEnabled })) {
    return product.deal.discount_price;
  }

  return product.price;
};

export const getDefaultSku = ({ product }) =>
  (product.hasVariant && product.variants.find((variant) => variant.default_sku)) || null;

export const getSla = ({ product }) => {
  if (product.sla?.type && product.sla?.type !== '') {
    return product.sla;
  }
  return product.store.sla;
};

export const getCouriers = ({ product }) => {
  if (product.couriers?.length > 0) {
    return product.couriers;
  }
  return product.store.carriers;
};

export default {
  getSingleTimeFormat,
  isOnDeal,
  weightConverter,
  formatDescription,
  getProductPrice,
  getDefaultSku,
  getSla,
  getCouriers,
};
