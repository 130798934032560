<template lang="pug">
  .c-reviews
    .c-reviews__header
      slot(name="reviews-header")
        bl-reviews-summary-mv(
          :rate="reviewRate"
          :review="reviewSummary"
          :star="filter.star"
        )
        .c-reviews__img-collection.u-mrgn-top--9(v-if="!isEmpty(reviewWithPicture.reviews)")
          h3.c-reviews__subtitle Kumpulan Foto Pembeli
          bl-carousel-mv(
            v-if="this.reviewWithPicture.reviews.length > 3"
            :slides-to-show="3.5"
            :slides-to-scroll="1"
            :infinite="false"
            :dots="false"
          )
            bl-carousel-slide-mv(
              v-for="(item, index) in reviewWithPicture.reviews"
              v-if="index < 6" :key="item.id"
            )
              bl-media-mv(
                :aspect-ratio="1.4"
                :src="resizeImgUrl(item.url)"
                :webp="true"
                cover
                @click="openDialog({ index })"
              )
                template(slot="placeholder")
                  div.c-media__placeholder__content
                    bl-spinner-av(size="medium")
            .c-reviews__img-collection__more.u-txt--large(
              v-if="reviewWithPicture.reviews.length > 6"
              @click="openDialog({ index: 0 })"
            )
              .c-reviews__img-collection__more-txt Lihat semua
          .c-review__images.bl-flex-container(v-else)
            template(v-for="(item, index) in reviewWithPicture.reviews")
              bl-media-mv(
                class="c-review__image bl-flex-item is-col-3"
                :aspect-ratio="1.4"
                :webp="true"
                :src="resizeImgUrl(item.url)"
                :lazy-src="imageLazy('medium')"
                @click="openDialog({ index })"
                cover
              )
                template(slot="placeholder")
                  div.c-media__placeholder__content
                    bl-spinner-av(size="medium")

    .c-reviews__body.u-border-top--1--ash-light.u-mrgn-top--6.u-pad-top--4
      slot(name="review-body")
        h3.c-reviews__subtitle Daftar Ulasan

        bl-reviews-filter-mv(:filter="filter" :commitFilter="commitFilter")

        template(v-if="isLoading")
          bl-reviews-item-shimmer-mv(v-for="i in 4" :key="i")

        bl-reviews-item-mv(
          v-if="!isLoading && review.reviews.length"
          v-for="item in review.reviews"
          :key="item.id"
          :item="item"
        )

        bl-pagination-mv(
          v-if="review.reviews.length"
          :limit="pagination.limit"
          :offset="pagination.offset"
          :total="review.meta.total"
          @changePage="setPagination"
        )

        bl-reviews-empty-av(v-else)
</template>

<script>
import { BlPaginationMv, BlSpinnerAv } from '@bukalapak/bazaar-dweb';
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce, isEmpty, isEqual } from 'lodash';

import BlReviewsEmptyAv from '~pdp-av/BlReviewsEmptyAv';
import imageHelper from '~pdp/mixins/image-helper';
import { BlCarouselMv, BlCarouselSlideMv } from '~shared-js/components/BlCarouselMv';
import BlMediaMv from '~shared-js/components/BlMediaMv';
import { reviewFilter } from '~shared-js/tracker/rage';

import BlReviewsItemMv from './components/BlReviewsItemMv';
import BlReviewsItemShimmerMv from './components/BlReviewsItemShimmerMv';
import BlReviewsSummaryMv from './components/BlReviewsSummaryMv';
import BlReviewsFilterMv from './components/BlReviewsFilterMv';

export default {
  name: 'BlReviewsMv',

  components: {
    BlReviewsEmptyAv,
    BlSpinnerAv,
    BlReviewsFilterMv,
    BlReviewsItemMv,
    BlReviewsItemShimmerMv,
    BlReviewsSummaryMv,
    BlMediaMv,
    BlPaginationMv,
    BlCarouselMv,
    BlCarouselSlideMv,
  },

  mixins: [imageHelper],

  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      dialogActive: false,
      isLoading: false,
      isSetLatency: false,
    };
  },

  computed: {
    ...mapState('productReviews', [
      'filter',
      'pagination',
      'review',
      'reviewSummary',
      'reviewWithPicture',
    ]),

    reviewRate() {
      const { rating } = this.product;
      return rating && rating.average_rate ? Number(rating.average_rate) : 0;
    },
  },

  watch: {
    filter(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.isLoading = true;
        this.preGetReviews(false);
        this.sendReviewTracker();
      }
    },

    'pagination.offset': function watchOffset(value, oldValue) {
      if (value !== oldValue) {
        this.preGetReviews(true);
        this.sendReviewTracker();
      }
    },

    isLoading(newValue) {
      if (newValue && !this.isSetLatency) {
        this.setLatencyRequest({ productReviews: false });
        this.isSetLatency = true;
      }
    },
  },

  mounted() {
    this.preGetReviews(false);
    this.getReviewHead(this.product.id);
    this.sendReviewTracker();
  },

  methods: {
    ...mapActions(['setLatencyRequest']),
    ...mapActions('productReviews', [
      'commitFilter',
      'fetchReviews',
      'getReviewHead',
      'openDialog',
    ]),
    ...mapMutations('productReviews', ['setPagination']),
    isEmpty,

    preGetReviews(isScroll = false) {
      this.isLoading = true;
      this.getReviews(isScroll);
    },

    getReviews: debounce(
      function getReviews(isScroll) {
        this.fetchReviews({ id: this.product.id })
          .then(() => {
            if (isScroll) {
              this.$scrollTo('.c-reviews__body', 500, {
                offset: -150,
              });
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      400,
      { leading: true, trailing: false },
    ),

    resizeImgUrl(url) {
      return url.replace('/large/', '/s-172-129/');
    },

    sendReviewTracker() {
      const { star, withDescription, withPicture } = this.filter;
      reviewFilter.track({
        product: this.product,
        star,
        withPicture,
        withDescription,
      });
    },
  },
};
</script>

<style src="./BlReviewsMv.style.scss" lang="scss"></style>
