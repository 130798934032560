<template lang="pug">
  .bl-shimmer-group-mv(:style="style")
    slot
</template>

<script>
export default {
  name: 'BlShimmerGroupMv',
  status: 'prototype',
  release: '0.1.0',

  props: {
    flexWrap: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number | String,
      default: 'auto',
    },
  },
  computed: {
    rowHeight () {
      var height = this.$children.reduce((acc, next) => {
        return next.height ? (next.height > acc ? next.height : acc) : acc
      }, 0)
      var height2 = this.$slots.default.reduce((acc, next) => {
        if (!next.componentOptions) return acc
        let height = next.componentOptions.propsData.height
        return height ? (height > acc ? height : acc) : acc
      }, 0)
      return height2 > height ? height2 : height
    },
    style () {
      return {
        'flex-wrap': this.flexWrap ? 'wrap' : 'nowrap',
        width: this.width,
      }
    },
  },
}
</script>
