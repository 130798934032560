import Tracker from '~lib/tracker';

import { getBrowserId, getSessionId } from '~shared-js/helpers/common-helpers';

import { getDocumentReferrer, getUserId } from '../common-data';

export const batPodProduct = (() => {
  const track = (payload) => {
    const data = {
      evn: 'bat_pod_product_availability',
      ui: getUserId(),
      product_id: payload.product.id,
      product_stock_pdp: payload.product.stock,
    };

    Tracker.event(data);
  };

  return {
    track,
  };
})();

export const marketplaceFunnelEvent = (({ location }) => {
  const track = async (payload) => {
    const data = {
      evn: 'gmt_marketplace_funnel_event',
      browser_id: getBrowserId() || '',
      funnel_event: payload.event,
      product_id: payload.productId,
      referrer_url: getDocumentReferrer(),
      session_id: getSessionId() || '',
      ui: getUserId(),
      url: location.href,
    };

    return Tracker.event(data);
  };

  return {
    track,
  };
})(global);

export const productDetailsEvent = (product) => {
  if (!window) return;

  window.dataLayer = window?.dataLayer || [];

  const generalAttribute = {
    content_type: 'product',
    currency: 'IDR',
  };

  const generalProductAttributeSingle = {
    id: product.id,
    quantity: 1,
    price: product.price,
    name: product.name,
    category: product.category?.structure?.[0] || '-',
    dimension20: product.store?.id?.toString(36),
    dimension24: product.category?.structure?.[1] || '',
    dimension25: product.category?.structure?.[2] || '',
    sellerName: product.store?.name,
    sellerId: product.store?.id,
    brand: product.specs?.brand || '-',
    sku: product.specs?.sku || '-',
  };
  window.dataLayer.push({
    event: 'productDetails',
    ecommerce: {
      detail: {
        products: [{ ...generalAttribute, ...generalProductAttributeSingle }],
      },
    },
  });
};

export const isUtmParametersExists = (searchParams) => {
  const isUtmSourceExist = searchParams.has('utm_source');
  const isUtmMediumExist = searchParams.has('utm_medium');
  const isUtmCampaignExist = searchParams.has('utm_campaign');
  return isUtmSourceExist && isUtmMediumExist && isUtmCampaignExist;
};

export default {
  batPodProduct,
  marketplaceFunnelEvent,
  productDetailsEvent,
  isUtmParametersExists,
};
