import { neo } from './api-modules';

export const postConfigs = ids => {
  if (ids) {
    if (!Array.isArray(ids)) {
      throw new Error(`Must be array of config ids`);
    }
    if (ids.length === 0) {
      throw new Error(`Required config ids`);
    }

    return neo.$configs.postConfigs({
      data: {
        id: ids,
        platform: 'bl_web',
      },
    });
  }
  return Promise.resolve();
};

export default {
  postConfigs,
};
