<template>
  <svg version="1.1" viewBox="0 0 24 24">
    <path
      :fill="color"
      data-testid="icon-path"
      stroke="none"
      pid="0"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 18h10V4H7v14zm5 3a1 1 0 1 1 0-2 1 1 0 1 1 0 2zm4.5-20h-9A2.5 2.5 0 0 0 5 3.5v17A2.5 2.5 0 0 0 7.5 23h9a2.5 2.5 0 0 0 2.5-2.5v-17A2.5 2.5 0 0 0 16.5 1z"
      _fill="#000"
    />
  </svg>
</template>

<script>
export default {
  name: 'PhoneIphoneSvg',
  props: {
    color: {
      type: String,
      default: '#ea5164',
    },
  },
};
</script>
