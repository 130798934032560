<template lang="pug">
  .c-courier__list(@click="$emit('click', value.service)")
    .c-courier__image-wrapper.c-courier__list-content
      img.c-courier__image(
        :src="getLogoSrc(value.courierName)"
        :alt="value.courierName"
      )
    .c-courier__name.c-courier__list-content
      span {{ value.service }}
    template(v-if="hasFee")
      template(v-if="!value.error")
        .c-courier__eta.c-courier__list-content
          span {{ value.eta }} {{value.eta_info}}
        .c-courier__price.c-courier__list-content
          span(v-if="value.price === 0") Gratis
          bl-price-av(v-else :number="value.price")
      .c-courier__error.c-courier__list-content(v-else) {{ value.error }}
</template>

<script>
import BlPriceAv from '~pdp/components/atoms/BlPriceAv';

const COURIER_LOGO_BASE_URL = 'https://static-fragment.static-assets.io/cavalier/images/couriers';
export default {
  name: 'CourierListMv',
  components: {
    BlPriceAv,
  },

  props: {
    hasFee: {
      type: Boolean,
      default: true,
    },

    value: {
      type: Object,
      required: true,
      default: null,
    },
  },

  methods: {
    getLogoSrc(courier) {
      return `${COURIER_LOGO_BASE_URL}/${courier}.png`;
    },
  },
};
</script>
