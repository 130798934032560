<template lang="pug">
  .c-seller-voucher.u-mrgn-top--6(v-if="hasValidVoucher")
    h3.u-txt--fair.u-txt--bold.u-mrgn-bottom--2 Voucher Pelapak
    temp-var(
      v-for="(voucher, index) in vouchers"
      v-if="voucher.show"
      :key="voucher.voucher_code"
      :vars=`{
        label: setDealText(voucher),
      }`
    )
      .c-seller-voucher__content.u-mrgn-top--2(slot-scope="{ label }")
        .c-seller-voucher__content__discount.u-txt--break-word
          h3.u-txt--bold.u-txt--base {{ label.text }}
          p.u-mrgn-top--1.u-mrgn-bottom--0(v-if="label.subText") {{ label.subText }}
          p.u-mrgn-top--2.u-mrgn-bottom--0 Untuk &#32;
            span(v-if="voucher.label") barang-barang dalam etalase
              b &#32;{{ voucher.label.name }}
            span(v-else) semua barang
            | &#32;dari
            b &#32;{{ store.name }}
            | &#32;dengan minimum transaksi
            b &#32;Rp{{ voucher.min_purchase | delimiter }}
        .c-seller-voucher__content__code
          h3(ref="code").u-mrgn-bottom--1.u-txt--bold {{ voucher.voucher_code.toUpperCase() }}
          bl-button-av(@click="handleCopy($refs.code[index])") Salin
</template>

<script>
import BlButtonAv from '@bukalapak/bazaar-dweb/dist/components/BlButtonAv';
import { delimiter } from '@bukalapak/toolbox-helper/number-helper';
import { mapActions, mapState } from 'vuex';

import { isOnDeal } from '~pdp/utils/product-helper';
import { copyText } from '~shared-js/helpers/common-helpers';
import { copyPremiumSellerVoucher } from '~shared-js/tracker/rev-pap';

const VoucherEnum = {
  fixedPrice: 'fixed_price',
  percentage: 'percentage',
  shipping: 'shipping',
};

export default {
  name: 'BlSellerVoucherMv',

  components: {
    BlButtonAv,
  },

  props: {
    store: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      hasValidVoucher: false,
      vouchers: [],
    };
  },

  computed: {
    ...mapState('productStore', ['product']),
  },

  mounted() {
    this.fetchStoreVouchers(this.store.id)
      .then((res) => {
        let hasValidVoucher = false;
        const vouchers = res.data.reduce((acc, voucher) => {
          const show = this.checkVoucherValid(voucher);
          if (!hasValidVoucher) {
            hasValidVoucher = show;
          }

          if (show) {
            return acc.concat({
              ...voucher,
              show,
            });
          }

          return acc;
        }, []);

        this.hasValidVoucher = hasValidVoucher;
        // Temporary display only one voucher;
        this.vouchers.splice(0, 1, vouchers[0]);
      })
      .finally(() => {
        this.setLatencyRequest({ vouchers: false });
      });
  },

  methods: {
    ...mapActions(['commitFlashMessage', 'setLatencyRequest']),
    ...mapActions('productStore', ['fetchStoreVouchers']),

    checkVoucherValid(voucher) {
      const isDeal = isOnDeal(voucher.start_time, voucher.end_time);
      if (voucher.label && this.product.labels) {
        let hasLabel = false;
        this.product.labels.forEach((label) => {
          if (label.name === voucher.label.name) {
            hasLabel = true;
          }
        });

        if (!hasLabel) {
          return false;
        }
      }

      return isDeal;
    },

    setDealText(voucher) {
      const type = voucher.premium_voucher_type;
      let text = '';
      let subText = '';

      switch (type) {
        case VoucherEnum.fixedPrice:
          text = `Potongan Rp${delimiter(voucher.amount)}`;
          break;
        case VoucherEnum.percentage: {
          const percentage = voucher.percentage_setting;
          text = `Diskon ${percentage.percentage_value}%`;
          subText = `maksimal hingga Rp${delimiter(percentage.maximum_discount_amount)}`;
          break;
        }
        case VoucherEnum.shipping: {
          const shipping = voucher.shipping_setting;
          text = `Gratis Ongkir Rp${delimiter(shipping.discount_amount)}`;
          subText = `dengan ${shipping.allowed_courier[0]}`;
          break;
        }
        default:
          text = '';
      }

      return {
        text,
        subText,
      };
    },

    handleCopy(el) {
      this.trackClickCopy(el.textContent);

      try {
        copyText(el);
        this.commitFlashMessage({
          message: 'Berhasil menyalin kode voucher',
          type: 'success',
        });
      } catch (err) {
        this.commitFlashMessage({
          message: 'Maaf, ada kesalahan pada saat menyalin voucher.',
          type: 'error',
        });
      }
    },

    trackClickCopy(voucherCode) {
      copyPremiumSellerVoucher.track({
        voucherCode,
      });
    },
  },
};
</script>
<style lang="scss" src="./BlSellerVoucherMv.style.scss"></style>
