export const courierStructs = [
  {
    regex: /^ninja.+/,
    name: 'ninjavan',
  },
  {
    regex: /^sicepat.+/,
    name: 'sicepat',
  },
  {
    regex: /^j&t.+/,
    name: 'jnt',
  },
  {
    regex: /^paxel.+/,
    name: 'paxel',
  },
  {
    regex: /^lion.+/,
    name: 'lionparcel',
  },
  {
    regex: /^grab.+/,
    name: 'grab',
  },
  {
    regex: /^rocket.+/,
    name: 'rocket',
  },
  {
    regex: /^wahana.+/,
    name: 'wahana',
  },
  {
    regex: /^jne.+/,
    name: 'jne',
  },
  {
    regex: /^tiki.+/,
    name: 'tiki',
  },
  {
    regex: /^go.+/,
    name: 'gojek',
  },
  {
    regex: /^alfatrex.+/,
    name: 'alfatrex',
  },
  {
    regex: /^pos.+/,
    name: 'poskilat',
  },
  {
    regex: /^ambil.+/,
    name: 'pickupservice',
  },
  {
    regex: /^rpx.+/,
    name: 'rpx',
  },
  {
    regex: /^kurir.+/,
    name: 'lapakservice',
  },
  {
    regex: /^janio.*/,
    name: 'janio',
  },
  {
    regex: /^indah.*/,
    name: 'indah',
  },
  {
    regex: /^bukaexpress.+/,
    name: 'whitelabel',
  },
  {
    regex: /^anteraja.+/,
    name: 'anteraja',
  },
];
