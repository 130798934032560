/**
 * Dialog Property
 * This state mostly will be use for BlReviewDialogMv(modal)
 * @typedef {object} Dialog
 * @property {boolean} active If active value is true the modal will show up
 * @property {object[]} reviewImages Contain the reviews data that has images
 * @property {string} section ['imgCollection', 'mostLiked', 'listReview'], A marker for modal, the behavior for modal might be different based on section.
 * @property {object} selectedReviewInfo Contain a review information that is being selected
 *
 */

/**
 * Product Review State
 * @typedef {object} State
 * @property {Dialog} dialog Property of {@link Dialog}
 *
 */

/**
 * getInitialState
 *
 * @returns {State}
 */
export const getInitialState = () => ({
  dialog: {
    active: false,
    activeIndex: -1,
    reviewImages: [],
    section: 'imgCollection',
    selectedReviewInfo: {},
  },
  review: {
    reviews: [],
    meta: {},
  },
  reviewSummary: {
    ratings: new Array(5),
    total: 0,
  },
  reviewWithPicture: {
    reviews: [],
    pagination: {
      limit: 6,
      offset: 0,
      total: 0,
    },
  },
  reviewMostLiked: {},
  filter: {
    star: false,
    withPicture: false,
    withDescription: false,
  },
  pagination: {
    limit: 5,
    offset: 0,
  },
});

export default getInitialState;
