<template>
  <div class="c-product-gallery">
    <template v-if="selectedProduct.images.length > 0">
      <pdp-client-only>
        <Portal>
          <BlLightboxMv
            v-if="lightboxStartIndex !== null && lightboxStartIndex >= 0"
            ref="lightbox"
            :images="selectedProduct.images"
            :n-thumbs="selectedProduct.images.length"
            :start-at.sync="lightboxStartIndex"
          />
        </Portal>
      </pdp-client-only>

      <pdp-client-only>
        <BlCarouselMv
          ref="main"
          class="c-product-gallery__main"
          :options="carouselOption"
          :as-nav-for="asNavFor1"
          :infinite="selectedProduct.images.length > 1"
          @navigation-click="handleNavigation"
        >
          <BlCarouselSlideMv
            v-for="(img, index) in selectedProduct.images"
            :key="index"
            class="c-carousel-mv__slide--main"
            :class="`slide--${index}`"
          >
            <div class="c-product-gallery__main-image" @click="openGallery(index)">
              <BlMediaMv
                :aspect-ratio="1"
                :alt="product.name"
                :transition="false"
                :src="index === 0 && !isMouseMoved ? imageUrl(img, 'small') : img"
                :ssr="true"
                :webp="true"
                :lazy-src="imageLazy('big')"
              >
                <template slot="placeholder">
                  <div class="c-media__placeholder__content">
                    <BlSpinnerAv size="medium" />
                  </div>
                </template>
              </BlMediaMv>
            </div>
          </BlCarouselSlideMv>
        </BlCarouselMv>

        <div
          slot="placeholder"
          class="u-mrgn-bottom--2"
          style="position: relative; width: 100%; height: 0; padding-bottom: 100%"
        >
          <div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0">
            <img
              :src="`${imageUrl(selectedProduct.images[0], 'small')}.webp`"
              style="height: 100%; object-fit: cover"
            />
          </div>
        </div>
      </pdp-client-only>

      <div v-if="selectedProduct.smallImages.length > 1" class="c-product-gallery__thumbnails">
        <div
          v-for="(img, index) in selectedProduct.smallImages"
          :key="`${img}-${index}`"
          :class="{ 'c-product-gallery__thumbnail--active': activeThumbnail === index }"
          class="c-product-gallery__thumbnail"
          @click="activeThumbnail = index"
        >
          <BlImageAv
            :alt="`${product.name} - ${index + 1}`"
            :src="imageUrl(img, 'small')"
            class="c-product-gallery__thumbnail-img"
            object-fit="cover"
            width="100%"
            height="100%"
            is-eager
          />
        </div>
      </div>
    </template>

    <div v-else class="o-ratio u-bg--sand-dark">
      <IcoImage class="u-position-absolute u-position-center" />
    </div>

    <pdp-client-only>
      <BlPreviewFooterMv :product="product" />
    </pdp-client-only>
  </div>
</template>

<script>
import { BlSpinnerAv } from '@bukalapak/bazaar-dweb';
import { IcoImage, BlImageAv } from '@bukalapak/bazaar-visual-v1';
import { Portal, setSelector } from '@linusborg/vue-simple-portal';
import { mapState } from 'vuex';

import BlLightboxMv from '~pdp-mv/BlLightboxMv';
import imageHelper from '~pdp-mixins/image-helper';
import { BlCarouselMv, BlCarouselSlideMv } from '~shared-js/components/BlCarouselMv';
import BlMediaMv from '~shared-js/components/BlMediaMv';

import BlPreviewFooterMv from './components/BlPreviewFooterMv';
import { SET_BASE_STATE } from '~pdp/store/mutations';

import { neo } from '~pdp/js/modules/api-modules';

setSelector('product-details-page');
export default {
  name: 'BlMainPreviewMv',

  components: {
    BlImageAv,
    BlSpinnerAv,
    BlMediaMv,
    IcoImage,

    BlCarouselMv,
    BlCarouselSlideMv,
    BlLightboxMv,
    BlPreviewFooterMv,
    Portal,
  },

  mixins: [imageHelper],

  inject: ['productDetailTvInjection'],

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isMouseMoved: false,
      activeThumbnail: 0,
      asNavFor1: [],
      carouselOption: {
        dots: false,
        navButtons: true,
      },
      lightboxStartIndex: null,
      shareIcon: global.assetUrl && global.assetUrl('images/ic-share.png'),
    };
  },

  computed: {
    ...mapState(['toggleVariantImageRevamp']),
    ...mapState('productStore', ['selectedVariant']),

    hasSelectedVariant() {
      return this.selectedVariant && this.selectedVariant.id;
    },

    selectedProduct() {
      let theProduct = this.product;
      if (this.hasSelectedVariant) {
        theProduct = this.selectedVariant.product;
      }

      const getRevampedVariantImageUrl = (images, variable = 'large_urls', variantImageId) => {
        const copyImages = JSON.parse(JSON.stringify(images));
        let indexOfVariantImage = copyImages.ids.indexOf(variantImageId);
        indexOfVariantImage =
          indexOfVariantImage >= 0 ? indexOfVariantImage : copyImages.ids.length - 1;
        return [...copyImages[variable].splice(indexOfVariantImage, 1), ...copyImages[variable]];
      };

      const { images, variant_image_ids: variantImageIds = [] } = theProduct;
      const {
        images: { large_urls: largeUrls = [] },
      } = theProduct;
      let {
        images: { small_urls: smallUrls = [] },
      } = theProduct;

      if (largeUrls.length > smallUrls.length) {
        smallUrls = [...smallUrls, ...largeUrls.slice(smallUrls.length)];
      } else if (largeUrls.length < smallUrls.length) {
        smallUrls = smallUrls.slice(0, largeUrls.length);
      }
      if (this.hasSelectedVariant) {
        return {
          images: this.toggleVariantImageRevamp
            ? getRevampedVariantImageUrl(images, 'large_urls', variantImageIds[0])
            : [...largeUrls.slice(1), largeUrls[0]],
          smallImages: this.toggleVariantImageRevamp
            ? getRevampedVariantImageUrl(images, 'small_urls', variantImageIds[0])
            : [...smallUrls.slice(1), smallUrls[0]],
        };
      }

      return {
        images: largeUrls,
        smallImages: smallUrls,
      };
    },
  },

  watch: {
    activeThumbnail(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== this.$refs.main.currentSlide) {
        this.$refs.main.goTo(newValue);
      }
    },
    selectedVariant() {
      if (this.hasSelectedVariant) {
        this.activeThumbnail = 0;
      }
    },
  },

  mounted() {
    this.getImageVariantRevampNeoConfig();
    document.addEventListener('mousemove', this.loadImage);
  },

  methods: {
    async getImageVariantRevampNeoConfig() {
      const configId = 'msx-variant-image-revamp-config';
      const configDataId = 'pdp-enabled';

      try {
        const { data } = await neo.$configs.postConfigs({
          data: {
            id: [configId],
          },
        });
        const isPdpEnabled = data?.[0]?.data?.[configDataId] || false;
        this.$store.commit(SET_BASE_STATE, {
          name: 'toggleVariantImageRevamp',
          payload: isPdpEnabled,
        });
      } catch (err) {
        console.log(err);
      }
    },
    loadImage() {
      this.isMouseMoved = true;
      document.removeEventListener('mousemove', this.loadImage);
    },

    handleNavigation(direction) {
      const { smallImages } = this.selectedProduct;
      const len = smallImages.length - 1;
      if (direction && direction === 'backward') {
        this.activeThumbnail -= this.activeThumbnail === 0 ? -len : 1;
      } else {
        this.activeThumbnail = (this.activeThumbnail + 1) % (len + 1);
      }
    },

    openGallery(index) {
      this.lightboxStartIndex = index;
    },
  },
};
</script>

<style langs="scss" src="./BlMainPreviewMv.scss" />
