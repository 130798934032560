<template lang="pug">
  .bl-flex-container(:class="flexContainerClasses")
    slot
</template>

<script>
import {
  DIRECTION_VARIATION,
  WRAP_VARIATION,
  JUSTIFY_VARIATION,
  ALIGN_ITEMS_VARIATION,
  ALIGN_CONTENT_VARIATION,
  GUTTER_VARIATION,
} from './type';

export default {
  name: 'BlFlexContainerMv',
  status: 'ready',
  release: '2.3.0',
  props: {
    /**
     * This establishes the main-axis, thus defining the direction flex items are placed in the flex container
     * `row, row-reverse, column, column-reverse`
     */
    direction: {
      type: String,
      validator(value) {
        return DIRECTION_VARIATION.indexOf(value) > -1;
      },
      default: '',
    },
    /**
     * By default, flex items will all try to fit onto one line. You can change that and allow the items to wrap as needed with this property.
     * `nowrap, wrap, wrap-reverse`
     */
    wrap: {
      type: String,
      validator(value) {
        return WRAP_VARIATION.indexOf(value) > -1;
      },
      default: '',
    },
    /**
     * This defines the default behavior for how flex items are laid out along the cross axis on the current line
     * `flex-start, flex-end, center, space-between, space-around, space-evenly`
     */
    justifyContent: {
      type: String,
      validator(value) {
        return JUSTIFY_VARIATION.indexOf(value) > -1;
      },
      default: '',
    },
    /**
     * This defines the alignment along the main axis. It helps distribute extra free space left over when either all the flex items on a line are inflexible, or are flexible but have reached their maximum size.
     * `flex-start, flex-end, center, stretch, baseline`
     */
    alignItems: {
      type: String,
      validator(value) {
        return ALIGN_ITEMS_VARIATION.indexOf(value) > -1;
      },
      default: '',
    },
    /**
     * This aligns a flex container's lines within when there is extra space in the cross-axis
     * `flex-start, flex-end, center, stretch, baseline`
     */
    alignContent: {
      type: String,
      validator(value) {
        return ALIGN_CONTENT_VARIATION.indexOf(value) > -1;
      },
      default: '',
    },
    /**
     * The margins between column (`BlFlexItemAv`)
     * `16, 20, 24, 32`
     */
    gutter: {
      type: Number,
      validator(value) {
        return GUTTER_VARIATION.indexOf(value) > -1;
      },
      default: undefined,
    },
  },
  computed: {
    flexContainerClasses() {
      return [
        this.direction !== '' ? `direction-${this.direction}` : '',
        this.wrap !== '' ? `flex-${this.wrap}` : '',
        this.justifyContent !== '' ? `justify-${this.justifyContent}` : '',
        this.alignItems !== '' ? `align-items-${this.alignItems}` : '',
        this.alignContent !== '' ? `align-content-${this.alignContent}` : '',
        this.gutter !== undefined ? `is-gutter-${this.gutter}` : '',
      ];
    },
  },
};
</script>

<style src="./BlFlexContainerMv.style.scss" lang="scss"></style>
