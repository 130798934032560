/**
 * List of requests to mark end of tracking time if all value become false
 *  for tracker web_load_time
 */
export const SECTIONS = {
  variant: ['variantInfo', 'variantOptions', 'skus'],
  favorite: ['favorite'],
  catalogs: ['catalogs'],
  shipping: ['addresses', 'shippingFees'],
  productReviews: ['productReviews'],
  similarProducts: ['similarProducts'],
  vouchers: ['vouchers'],
  sellerMain: ['sellerMain'],
  sellerSecondary: ['sellerSecondary'],
  chat: ['chat']
};

export default {
  variantInfo: true, // store - product/actions getProductHasVariant
  variantOptions: true, // store - product/actions getProductVariantOptions
  skus: true, // store - product/actions getProductVariant
  favorite: true, // store - product/actions getFavoriteState
  catalogs: true, // store - product/actions getProductCatalog
  addresses: true, // store - shipping/actions getUserAddresses
  shippingFees: true, // store - shipping/actions getShippingPrice
  productReviews: true, // store - product-reviews/actions fetchReviews, entry-client - initLatencyRequestState
  similarProducts: true, // store - actions fetchSimilarProducts
  vouchers: true, // BlSellerVoucherMv - mounted
  me: true, // store - actions fetchMe, entry-client - initLatencyRequestState
  chat: true, // store - actions fetchSellerStatus, entry-client - initLatencyRequestState
  sellerSecondary: true,
  sellerMain: true,
};
