// Facebook
(function facebookScript(d, s, id) {
  if (!d) return;
  if (d.getElementById(id)) return;
  const fjs = d.getElementsByTagName(s)[0];
  const js = d.createElement(s);
  js.id = id;
  js.src = '//connect.facebook.net/id_ID/sdk.js#xfbml=1&version=v2.5';
  js.async = true;
  if (fjs) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    document.body.append(js);
  }
})(global.document, 'script', 'facebook-jssdk');

// Pinterest
(function pinterestScript(d) {
  if (!d) return;
  const f = d.getElementsByTagName('script')[0];
  const p = d.createElement('script');
  p.type = 'text/javascript';
  p.async = true;
  p.src = '//assets.pinterest.com/js/pinit.js';
  f.parentNode.insertBefore(p, f);
})(global.document);
