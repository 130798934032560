/**
 * Get timestamp in Epoch format
 * @return {Number}
 */
export const getUnixTime = () => Math.floor(new Date().getTime() / 1000);

/**
 * Get the referrer url of current page
 * @return {String}
 */
export const getDocumentReferrer = () => global.document?.referrer || '';

/**
 * Get the user id from global variable westeros
 * @return {String}
 */
export const getUserId = () => global.USER?.id36 || '';
