/**
 * Handlers methods for mouse/touch events
 */
const mixin = {
  methods: {
    handleMouseDown(e) {
      if (!e.touches) {
        e.preventDefault();
      }
      this.mouseDown = true;
      this.dragStartX = e.type == 'touchstart' ? e.touches[0].pageX : e.clientX;
      this.dragStartY = e.type == 'touchstart' ? e.touches[0].pageY : e.clientY;
    },

    handleMouseMove(e) {
      const positionX = e.type == 'touchmove' ? e.originalEvent.touches[0].pageX : e.clientX;
      const positionY = e.type == 'touchmove' ? e.originalEvent.touches[0].pageY : e.clientY;
      const dragDistanceX = Math.abs(positionX - this.dragStartX);
      const dragDistanceY = Math.abs(positionY - this.dragStartY);
      if (dragDistanceX > 3 * dragDistanceY) {
        this.disableScroll();
        this.dragDistance = positionX - this.dragStartX;
      }
    },

    handleMouseUp() {
      this.mouseDown = false;
      this.enableScroll();
    },

    handleMouseOver(element) {
      if (this.settings.autoplay) {
        if (
          (element === 'dot' && this.settings.pauseOnDotsHover) ||
          (element === 'track' && this.settings.pauseOnHover)
        ) {
          this.pauseAutoPlay = true;
        }
      }
    },

    handleMouseOut(element) {
      if (this.settings.autoplay) {
        if (
          (element === 'dot' && this.settings.pauseOnDotsHover) ||
          (element === 'track' && this.settings.pauseOnHover)
        ) {
          this.pauseAutoPlay = false;
        }
      }
    },
  },
};

export default mixin;
