<template lang="pug">
  div.c-product-price(:class="[`-${variation}`, { '-main': main }]")
    span {{ money(number) }}
</template>

<script>
import { money } from '@bukalapak/toolbox-helper/number-helper';

export default {
  name: 'BlPriceAv',
  status: 'prototype',
  release: '0.1.0',
  props: {
    variation: {
      type: String,
      default: 'original',
      required: false,
      validator: value => value.match(/(original|discount|stroke)/),
    },

    main: {
      type: Boolean,
      default: false,
    },

    number: {
      type: Number || String,
      default: 0,
      required: true,
    },
  },

  methods: {
    money,
  },
};
</script>

<style lang="scss" src="./BlPriceAv.style.scss"></style>
