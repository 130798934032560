import { getState } from '~pdp/store/global';

const defaultCartHeaders = {
  'X-Atc-Referrer': 'from_pdp',
};

export default {
  atcData(state, getters, rootState) {
    const { minQuantity, maxQuantity } = rootState.productStore.product;
    const { currentItem } = getters;
    let quantity = minQuantity;
    if (currentItem && currentItem.stuff.stock > 0) {
      quantity = Math.min(currentItem.quantity + 1, currentItem.stuff.stock, maxQuantity);
    }

    let cartData = {
      product: {
        ...rootState.productStore.rawProduct,
        sku_id: getters.productSkuId,
      },
      quantity,
      headers: defaultCartHeaders,
    };
    // eslint-disable-next-line camelcase
    if (rootState.experimentContext?.search_query_id) {
      cartData.headers['X-Search-Sort-Default'] = true;
      cartData = { ...cartData, experiment_context: rootState.experimentContext };
    }

    return cartData;
  },

  currentItem: (state, getters) => {
    const cart = getState('cart');
    if (cart.items) return cart.items.find((i) => i.stuff.id === getters.productSkuId);
    return null;
  },

  productSkuId(state, getters, rootState) {
    const { product, selectedVariant = {} } = rootState.productStore;
    if (rootState.toggleVariantNotPreSelected) {
      return product.hasVariant ? selectedVariant.id || null : product.skuId;
    }
    return selectedVariant.id || product.skuId;
  },
};
