import { simpleNumber } from '@bukalapak/toolbox-helper/number-helper';

export const isOnDeal = (start, end) => {
  if (!start || !end) {
    return false;
  }

  const today = new Date();
  const startDate = new Date(start);
  const endDate = new Date(end);

  return today >= startDate && today <= endDate;
};

/**
 * weightConverter
 *
 * @param {number} weight - weight number in 'gram'
 * @param {string} type - type to convert from 'gram'
 * @param {boolean} concat - if true, value of weight will be concatenated with METRIC_NAME.
 * @returns {number|string}
 */
export const weightConverter = (weight, type = 'gr', concat = false) => {
  let w = weight;
  const METRIC_NAME = {
    mg: 'miligram',
  };

  if (type === 'mg') {
    w *= 1000;
  }

  if (!concat) {
    return w;
  }

  switch (type) {
    case 'mg':
      return `${w} ${METRIC_NAME[type]}`;

    default:
      return simpleNumber(w, [' gram', ' kilogram']);
  }
};

/**
 * getSingleTimeFormat
 * Minimizing time seconds to minutes or to hours or to days if possible
 * if seconds is bigger than 59 convert to minutes
 * if minutes bigger than 59 convert to hours
 * if hours bigger than 23 convert to days
 *
 * @param {Number} secondsValue - Time value in seconds
 * @returns {String} - Formatted time string
 */
export function getSingleTimeFormat(secondsValue) {
  let convertedTime = Math.round(secondsValue);
  let timeFlag = 'detik';

  if (secondsValue > 59) {
    // to minutes
    convertedTime = Math.round(secondsValue / 60);
    timeFlag = 'menit';
  }
  if (convertedTime > 59) {
    // to hours
    convertedTime = Math.round(convertedTime / 60);
    timeFlag = 'jam';
  }
  if (convertedTime > 23) {
    // to days
    convertedTime = Math.round(convertedTime / 24);
    timeFlag = 'hari';
  }

  return `${convertedTime} ${timeFlag}`;
}

export default {
  getSingleTimeFormat,
  isOnDeal,
  weightConverter,
};
