<template lang="pug">
  .c-main-product__head
    .c-main-product__head--left
      .c-main-product__unavailable(v-if="!product.forSale")
        span(:class="unavailableState.className") {{ unavailableState.label }}

      h1.c-main-product__title.u-txt--large(:title="product.name", :aria-label="product.name") {{ product.name }} {{ selectedVariantName }}
      .c-main-product__reviews
        .c-main-product__rating.u-mrgn-right--2(
          v-if="product.rating && product.rating.average_rate"
          v-scroll-to="scrollEl"
        )
          bl-rating-mv.u-mrgn-right--1(
            :value="ratingVal"
            :max-ratings="5"
            variant="star"
          )
          | {{ product.rating.user_count | delimiter }} Ulasan
        span(v-if="product.stats.sold_count > 0")
          | {{ product.stats.sold_count | delimiter }} Terjual

    .c-main-product__head--right
      pdp-client-only
        .c-main-product__admin(v-if="me.isAdProduct")
          bl-button-av(size="small" :href="`${adminUrl}/policies/p/${product.id}`") Admin
      .bukamall(v-if="isBrand")
        img(:src="bukamallLogo" style="width: 78px;")
        br
        small Pelapak Brand Resmi
      template(v-else-if="isSuperSeller && isFinishedFetchBadgeToggle")
        .c-badges__new-super-seller.u-mrgn-bottom--1(v-if="isSuperSellerNewBadgeActive")
        .c-badges__super-seller.u-mrgn-bottom--1(v-else) Super Seller
</template>

<script>
import { mapState } from 'vuex';

import BlButtonAv from '@bukalapak/bazaar-dweb/dist/components/BlButtonAv';
import BlRatingMv from '@bukalapak/bazaar-dweb/dist/components/BlRatingMv';

export default {
  name: 'BlMainHeadMv',

  components: {
    BlButtonAv,
    BlRatingMv,
  },

  props: {
    me: {
      type: Object,
      default: () => ({}),
    },

    product: {
      type: Object,
      default: () => ({}),
    },
  },

  inject: ['productDetailTvInjection'],

  data() {
    return {
      bukamallLogo: 'https://s1.bukalapak.com/pavilion/1587095550468/original/bukamall-logo.png',
      scrollEl: {
        el: '.c-reviews__body',
        easing: 'ease-in-out',
        offset: -180,
      },
    };
  },

  computed: {
    ...mapState('productStore', ['selectedVariant']),
    ...mapState([
      'toggleVariantNotPreSelected',
      'isFinishedFetchBadgeToggle',
      'isSuperSellerNewBadgeActive',
    ]),

    isBrand() {
      return this.product.store && this.product.store.brand_seller;
    },

    isSuperSeller() {
      return this.product.store && this.product.store.premium_top_seller;
    },

    ratingVal() {
      const { rating } = this.product;
      if (rating && rating.average_rate) {
        return rating.average_rate * 20;
      }
      return 0;
    },

    unavailableState() {
      const { notSaleState } = this.productDetailTvInjection;
      if (notSaleState && notSaleState.reason) {
        let className = '';
        let label = '';
        if (notSaleState.reason.toLowerCase() === 'melanggar') {
          className = 'c-main-product__sold-out';
          label = 'Melanggar';
        } else if (this.product.stock === 0) {
          className = 'c-main-product__sold-out';
          label = 'Stok Habis';
        }

        return {
          className,
          label,
        };
      }

      return {};
    },

    selectedVariantName() {
      return this.toggleVariantNotPreSelected && this.selectedVariant.name
        ? `- ${this.selectedVariant.name}`
        : '';
    },

    adminUrl() {
      const originUrl = global?.location?.origin || 'www.bukalapak.com'
      return originUrl.replace('www', 'admin')
    }
  },
};
</script>

<style lang="scss" src="./BlMainHeadMv.scss"></style>
