import { lowerCase, startCase, kebabCase, isBoolean } from 'lodash';

import { isArray } from '~shared-js/helpers/common-helpers';

/**
 * createLink
 *
 * @param {function} h - Function for create element in functional way
 * @param {object} anchorObj
 * @param {string} anchorObj.label - For displaying anchor tag content
 * @param {string} anchorObj.url - For href value
 * @param {string} anchorObj.target - For anchor's target behavior
 * @returns {function} - Function in JSX format
 */
const createLink = (h, { label, url, target = '_blank', isNoFollow = false }) => {
  let relAttr = 'noopener noreferer';

  if (isNoFollow) {
    relAttr += ' nofollow';
  }

  return (
    <a href={url} target={target} rel={relAttr}>
      {label}
    </a>
  );
};

export default {
  name: 'RowSpecificationAv',

  functional: true,

  props: {
    label: {
      type: String,
      default: '',
    },

    value: {
      type: null,
      default: '',
    },
  },

  render(h, { props }) {
    const lowerLabel = lowerCase(props.label);
    let labelContent = startCase(props.label);
    let valueContent = isArray(props.value) ? props.value.join(', ') : props.value;

    if (lowerLabel === 'sim card') {
      labelContent = 'SIM Card';
    } else if (lowerLabel === 'kategori') {
      valueContent = createLink(h, {
        label: valueContent.name,
        url: valueContent.url,
        isNoFollow: valueContent.isNoFollow,
      });
    } else if (lowerLabel === 'katalog') {
      valueContent = createLink(h, {
        label: valueContent.title,
        url: `/pc/${valueContent.slug}?from=product_detail_catalog_spec`,
      });
    } else if (lowerLabel === 'brand' || lowerLabel === 'merek' || lowerLabel === 'merk') {
      if (!isBoolean(valueContent) && lowerCase(valueContent) !== 'merek lainnya') {
        valueContent = createLink(h, {
          label: startCase(valueContent),
          url: `/brand/${kebabCase(valueContent)}`,
          isNoFollow: true,
        });
      }
    } else {
      labelContent = startCase(labelContent);
    }

    if (isBoolean(valueContent)) {
      valueContent = '-';
    }

    return (
      <tr>
        <th class="u-align-left u-txt--normal">{labelContent}</th>
        <td>:</td>
        <td>{valueContent || '-'}</td>
      </tr>
    );
  },
};
