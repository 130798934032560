import _ from 'lodash';
import qs from 'qs';

import loginAction from '~pdp/js/constants/login-action';
import { isArray } from '~shared-js/helpers/common-helpers';

export function convertToUnit(str, unit = 'px') {
  if (str == null || str === '') {
    return undefined;
  }
  // eslint-disable-next-line
  if (isNaN(+str)) {
    return String(str);
  }
  return `${Number(str)}${unit}`;
}

/**
 * Cleanup possibility of repetitive queryParams
 * currently from /login could return query params like:
 *   ?from=localhost/p/something?from=localhost=/p/anotherthing?foo=bar&bar=false&from=anotherlocalhost?foo=bar
 * above should be parse into from=localhost/p/something&foo=bar&bar=false
 *
 * @todo Should provide deep unique checking ??
 *
 * @param {Object} params query params from url
 * @param {Array} toOmitParams array of params to be omitted
 * @returns {Object}
 */
export const pickLoginParams = (params, toOmitParams = []) => {
  let newParams = {};
  // Get unique params
  if (!_.isEmpty(params)) {
    Object.keys(params).forEach(key => {
      const val = params[key];
      // if val is array, that means there are params with same key, get the 1st one only
      if (isArray(val)) {
        const uniq = _.uniq(val);
        if (uniq[0]) {
          newParams[key] = uniq[0];
        }
      } else {
        newParams[key] = val;
      }
    });
  }
  newParams = _.omit(newParams, toOmitParams);
  return newParams;
};

/**
 * Redirect user to login page with comeback query string
 * The comeback url should be in encoded format
 *
 * @param {String} from variant of 'from' from loginAction
 * @param {Object} queryParams object of query to params to append in comebackUrl
 *
 * @returns {String}
 *
 */
export const setLoginComebackUrl = (from, { customPath = '', queryParams } = {}) => {
  const { location: { origin, pathname, search } = {} } = global;

  // need extra 'escape' for query string
  const queryString = encodeURIComponent(qs.stringify(queryParams, { encode: false }));

  /* Note about encode param
   * %26 = &, %3f = ?, %5B = [, %5D = ], %25 = %
   */
  let comebackUrl = '';
  if (
    from === loginAction.checkout ||
    from === loginAction.addToCart ||
    from === loginAction.favorite
  ) {
    const pdpQueryString = search ? `${encodeURIComponent(search)}%26` : '%3f';
    comebackUrl = encodeURIComponent(`${origin}${pathname}`);
    comebackUrl = `${comebackUrl}${pdpQueryString}${queryString}`;
  } else if (from === loginAction.chat && customPath) {
    comebackUrl = encodeURIComponent(`${origin}/${customPath}`);
    comebackUrl = `${comebackUrl}%3f${queryString}`;
  }

  return `/login?comeback=${comebackUrl}`;
};
