import to from '~lib/to';
import { promoCoupons, claimCampaign, storeVoucher } from '~pdp/js/modules/api-modules';
import { SET_BASE_VOUCHERS } from './mutations';
import { voucherSellerType } from '~shared-js/helpers/voucher-seller-type';
import { getCouriers } from '~shared-js/product-detail-page/helpers/product-helper';

const actions = {
  async fetchClaimedCoupons(
    { commit },
    {
      offset,
      limit,
      sort,
      withs,
      couriers,
      purchaseExpierences,
      sellers,
      sellerType,
      productTypes,
      productCategories,
      platforms,
    },
  ) {
    const [err, res] = await to(
      promoCoupons.$claims.getClaimedCoupon({
        queryParams: {
          offset,
          limit,
          sort,
          'with[]': withs,
          'couriers[]': couriers,
          'purchase_expierences[]': purchaseExpierences,
          'sellers[]': sellers,
          seller_type: sellerType,
          'product_types[]': productTypes,
          'product_categories[]': productCategories,
          'platforms[]': platforms,
        },
      }),
    );

    if (!err) {
      commit(SET_BASE_VOUCHERS, {
        name: 'claimedCoupons',
        payload: res.data,
      });

      return res.data;
    }

    return Promise.reject(err);
  },

  async fetchPremiumVouchers({ commit }, storeId) {
    const [err, res] = await to(storeVoucher.retrieveStorePremiumVouchers(storeId));

    if (!err) {
      commit(SET_BASE_VOUCHERS, {
        name: 'premiumVouchers',
        payload: res.data,
      });

      return res.data;
    }

    return Promise.reject(err);
  },

  async fetchClaimableVouchers({ commit }, storeId) {
    const [err, res] = await to(
      claimCampaign.$selectedSellers.getListVoucherBySelectedSeller(storeId),
    );

    if (!err) {
      commit(SET_BASE_VOUCHERS, {
        name: 'claimableVouchers',
        payload: res.data,
      });

      return res.data;
    }

    return Promise.reject(err);
  },

  async fetchAvailableVouchers({ commit, dispatch }, { product }) {
    const couriers = getCouriers({ product });
    commit(SET_BASE_VOUCHERS, {
      name: 'isAvailableVouchersLoaded',
      payload: false,
    });
    commit(SET_BASE_VOUCHERS, {
      name: 'isAvailableVouchersError',
      payload: false,
    });

    const claimedCouponsRequest = dispatch('fetchClaimedCoupons', {
      offset: 0,
      limit: 100,
      sort: 'type',
      withs: ['amount', 'couriers', 'sellers', 'seller_type'],
      couriers,
      sellers: [product.store.id],
      seller_type: voucherSellerType(product.store),
      product_types: ['marketplace'],
      product_categories: [product.category.id], // need to change to its root category id
    });
    const premiumVouchersRequest = dispatch('fetchPremiumVouchers', product.store.id);
    const claimableVouchersRequest = dispatch('fetchClaimableVouchers', product.store.id);

    try {
      const [claimedCouponsResult, premiumVouchersResult, claimableVouchersResult] =
        await Promise.all([
          claimedCouponsRequest,
          premiumVouchersRequest,
          claimableVouchersRequest,
        ]);
      const availableVouchers = {
        claimedCoupons: claimedCouponsResult,
        premiumVouchers: premiumVouchersResult,
        claimableVouchers: claimableVouchersResult,
      };
      commit(SET_BASE_VOUCHERS, {
        name: 'availableVouchers',
        payload: availableVouchers,
      });
      commit(SET_BASE_VOUCHERS, {
        name: 'isAvailableVouchersLoaded',
        payload: true,
      });

      return availableVouchers;
    } catch (error) {
      commit(SET_BASE_VOUCHERS, {
        name: 'isAvailableVouchersLoaded',
        payload: true,
      });
      commit(SET_BASE_VOUCHERS, {
        name: 'isAvailableVouchersError',
        payload: true,
      });
      return error;
    }
  },
};

export default actions;
