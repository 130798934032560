import { RESET_LATENCY_TRACKER, SET_BASE_STATE } from '~shared/javascripts/product-detail-page/store/mutations';

const saveProduct = product => {
  sessionStorage.setItem(product.id, JSON.stringify(product));
};

export default ({ app, commitProductStore, init, reset, pdpEventBus }) => {
  const isSameProduct = productId => productId === app.$store.state.productStore.base36Id;

  const spaReset = (product, title) => {
    reset();
    app.$store.commit(RESET_LATENCY_TRACKER);
    app.$store.commit(SET_BASE_STATE, { name: 'latencyTimeOrigin', payload: Date.now() });
    commitProductStore({ name: 'base36Id', payload: product.id });
    commitProductStore({ name: 'id', payload: parseInt(product.id, 36) });
    document.title = title;
    if (init) {
      init(product);
    }
  };

  pdpEventBus.subscribe('changeProduct', e => {
    const { product: newProduct } = e.detail;
    window.scroll({ top: 0 });

    if (isSameProduct(newProduct.id)) return;

    const title = `Jual ${newProduct.name} di Lapak ${newProduct.store.name} | Bukalapak`;
    window.STORE.commit('pdp/setProduct', newProduct);
    window.history.pushState(
      {
        product: newProduct,
        title,
      },
      title,
      `${newProduct.url.slice(newProduct.url.indexOf('/p/'))}`,
    );
    spaReset(newProduct, title);
    saveProduct(newProduct);
  });

  window.addEventListener('popstate', e => {
    if (isSameProduct(e.state.product.id)) return;

    let product = sessionStorage.getItem(e.state.product.id);
    product = product ? JSON.parse(product) : e.state.product;

    window.STORE.commit('pdp/setProduct', product);
    pdpEventBus.publish('popChangeProduct', { product });
    spaReset(product, e.state.title);
  });

  // Save product on the first load
  saveProduct(app.$store.state.productStore.product);
};
