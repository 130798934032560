<template lang="pug">
  .c-affix(:style="wrapStyle")
    div(:class="{'c-affix--active': affixed}", :style="styles")
      slot
</template>

<script>
export default {
  name: 'BlAffixMv',
  props: {
    offset: {
      type: Number,
      default: 0,
    },
    onAffix: {
      type: Function,
      default() {},
    },
    boundary: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      affixed: false,
      styles: {},
      affixedClientHeight: 0,
      wrapStyle: {},
    };
  },
  computed: {
    offsets() {
      if (this.boundary) return 0;
      return this.offset;
    },
  },
  mounted() {
    this.affixedClientHeight = this.$el.children[0].clientHeight;
    this.wrapStyle = { height: `${this.affixedClientHeight}px` };
    global.addEventListener('scroll', this.handleScroll);
    global.addEventListener('resize', this.handleScroll);
  },
  beforeDestroy() {
    global.removeEventListener('scroll', this.handleScroll);
    global.removeEventListener('resize', this.handleScroll);
  },
  methods: {
    getScroll(w, top) {
      let ret = w[`page${top ? 'Y' : 'X'}Offset`];
      const method = `scroll${top ? 'Top' : 'Left'}`;
      if (typeof ret !== 'number') {
        const d = w.document;
        // ie6,7,8 standard mode
        ret = d.documentElement[method];
        if (typeof ret !== 'number') {
          // quirks mode
          ret = d.body[method];
        }
      }
      return ret;
    },

    getOffset(element) {
      const rect = element.getBoundingClientRect();
      const { body } = document;
      const clientTop = element.clientTop || body.clientTop || 0;
      const clientLeft = element.clientLeft || body.clientLeft || 0;
      // const clientHeight = element.clientHeight || 0;
      const scrollTop = this.getScroll(global, true);
      const scrollLeft = this.getScroll(global);
      return {
        top: rect.bottom + scrollTop - clientTop - this.affixedClientHeight,
        left: rect.left + scrollLeft - clientLeft,
      };
    },

    handleScroll() {
      const scrollTop = this.getScroll(global, true) + this.offsets; // handle setting offset
      const elementOffset = this.getOffset(this.$el);
      const headerEl = global.document && global.document.querySelector('header');
      const isHeaderNavHidden = headerEl.classList.contains('c-header--pdp');

      if (!this.affixed && scrollTop > elementOffset.top) {
        this.affixed = true;
        this.styles = {
          top: `${this.offsets}px`,
          left: `${elementOffset.left}px`,
          width: `${this.$el.offsetWidth}px`,
          paddingTop: isHeaderNavHidden ? '68px' : `${headerEl.offsetHeight}px`,
        };
        this.onAffix(this.affixed);
      }

      // if setting boundary
      if (this.boundary && scrollTop > elementOffset.top) {
        const el = global.document && global.document.querySelector(this.boundary);
        if (el) {
          const boundaryOffset = this.getOffset(el);
          if (scrollTop + this.offsets > boundaryOffset.top) {
            const top = scrollTop - boundaryOffset.top;
            this.styles.top = `-${top}px`;
          }
        }
      }

      if (this.affixed) {
        this.$set(
          this.styles,
          'paddingTop',
          isHeaderNavHidden ? '68px' : `${headerEl.offsetHeight + 20}px`,
        );
      }

      if (this.affixed && scrollTop < elementOffset.top) {
        this.affixed = false;
        this.$set(this, 'styles', {});
        this.onAffix(this.affixed);
      }

      if (this.affixed && this.boundary) {
        const el = global.document && global.document.querySelector(this.boundary);
        if (el) {
          const boundaryOffset = this.getOffset(el);
          if (scrollTop + this.offsets <= boundaryOffset.top) {
            this.styles.top = 0;
          }
        }
      }
    },
  },
};
</script>

<style src="./BlAffixMv.style.scss" lang="scss"></style>
