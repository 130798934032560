<template>
  <BlProductDetailTv v-if="showProductDetail" @updated="reload" />

  <BlMainProductShimmerOv v-else-if="isLoading" />

  <h2 v-else class="u-pad--10">
    Produk sedang dicari atau Produk tersebut tidak ditemukan...
  </h2>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { isEmpty } from 'lodash';

import getSuperSellerBadgePromise from '~shared-js/helpers/super-seller-badge-promise';
import { batPodProduct, productDetailsEvent, isUtmParametersExists } from '~shared-js/tracker/gmt';
import { pageviews } from '~shared-js/tracker/pageviews';

import { SET_BASE_STATE } from './store/mutations';
import BlProductDetailTv from './components/templates/BlProductDetailTv';

const BlMainProductShimmerOv = () =>
  import(
    /* webpackChunkName: 'bl-main-product-shimmer-ov' */ './components/organisms/BlMainProductShimmerOv'
  );

export default {
  name: 'ProductDetail',

  components: {
    BlMainProductShimmerOv,
    BlProductDetailTv,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState(['me']),
    ...mapState('productStore', ['base36Id', 'product']),

    showProductDetail() {
      return !(this.isLoading || isEmpty(this.product));
    },
  },

  watch: {
    base36Id(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.reload();
      }
    },
  },

  mounted() {
    this.init();
  },

  destroyed() {
    this.clearAllStates();
  },

  methods: {
    ...mapActions(['clearAllStates', 'fetchMe', 'getToggles']),
    ...mapActions('productStore', ['getProduct']),
    ...mapMutations('productStore', ['SET_BASE_PRODUCT_STATE']),

    init() {
      if (this.base36Id) {
        if (isEmpty(this.product)) {
          this.isLoading = true;
          this.getProduct().finally(() => {
            this.isLoading = false;
            this.trackExternalPdp();
          });
        } else {
          this.trackExternalPdp();
        }

        if (this.$isLogin()) {
          this.fetchMe();
        }

        this.getToggles();
      }

      // [GMT] - bat pod product availability
      const searchParams = new URLSearchParams(global.location.search);
      const fromCampaign = searchParams.get('blca');
      const outOfStock = this.product.stock <= 0;

      if ((fromCampaign || isUtmParametersExists(searchParams)) && outOfStock) {
        batPodProduct.track({ product: this.product });
      }

      this.getNewSuperSellerBadgeStatus();
    },

    reload() {
      this.SET_BASE_PRODUCT_STATE({ name: 'product', payload: {} });
      this.SET_BASE_PRODUCT_STATE({ name: 'rawProduct', payload: {} });

      this.isLoading = true;
      this.getProduct(true).finally(() => {
        this.isLoading = false;
        this.trackExternalPdp();
        pageviews();
      });
    },

    trackExternalPdp() {
      if (!isEmpty(this.product)) {
        productDetailsEvent(this.product);
      }
    },

    getNewSuperSellerBadgeStatus() {
      const promise = getSuperSellerBadgePromise();
      promise
        .then(res => {
          this.$store.commit(SET_BASE_STATE, {
            name: 'isSuperSellerNewBadgeActive',
            payload: res.data[0] ? res.data[0].active : false,
          });
        })
        .finally(() => {
          this.$store.commit(SET_BASE_STATE, {
            name: 'isFinishedFetchBadgeToggle',
            payload: true,
          });
        });
    },
  },
};
</script>
