<template lang="pug">
  .c-main-product__installment.u-txt--base
    bl-popover-av(position="left" keepPopover)
      .c-popover__trigger
        span.c-popover__trigger__text Pilihan Cicilan Beragam
        span.c-popover__trigger__icon.c-icon
      .c-main-product__installment__popover(slot="content")
        .c-main-product__installment__price
          bl-flex-container-mv(v-for="i in [3, 6, 12]" :class="i !== 12 && 'u-mrgn-bottom--2'" :key="i")
            bl-flex-item-av(:col="3")
              b {{ i }} Bulan
            bl-flex-item-av
              bl-price-av(:number="countInstallmentPrice(i)" :withSpace="false")
              | /bulan
            bl-flex-item-av Cicilan 0%
        hr
        .c-main-product__installment__banks(data-test-installment-banks)
          | Bank penyedia cicilan
          bl-flex-container-mv.c-main-product__installment__banks-wrapper
            bl-flex-item-av.c-main-product__installment__bank-image(
              v-for="[key, installment] in Object.entries(uniqueInstallments)"
              :key="key"
              basis="20%"
            )
              BlMediaMv(
                :src="replaceLogoHost(installment.logo_url)"
                :alt="installment.bank_issuer"
                :title="installment.bank_issuer_name"
              )

        hr
        .c-main-product__installment__without-cc
          | Cicilan Tanpa Kartu Kredit
          a.c-main-product__installment__learn(
            href="/faq/sebagai-pembeli/pembayaran-transaksi/cicilan-tanpa-kartu-kredit"
            target="_blank"
            rel="noopener noreferer"
          ) Pelajari
          bl-flex-container-mv(wrap="wrap", style="width: 100%;")
            bl-flex-item-av(v-for="i in withoutCCPayments" :col="3" :key="i")
              span.c-logo-external(:class="`c-logo-external--${i}`")

</template>

<script>
import { BlPopoverAv } from '@bukalapak/bazaar-dweb';

import BlPriceAv from '~pdp-av/BlPriceAv';
import { BlFlexContainerMv, BlFlexItemAv } from '~pdp-mv/BlFlexMv';
import BlMediaMv from '~shared-js/components/BlMediaMv';
import uniqueInstallments from '~shared/javascripts/product-detail-page/helpers/unique-installment';

export default {
  name: 'BlMainInstallmentMv',

  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlPriceAv,
    BlPopoverAv,

    BlMediaMv,
  },

  props: {
    installments: {
      type: Array,
      default: () => ({}),
    },

    selectedProduct: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      withoutCCPayments: ['akulaku', 'kredivo', 'bri-ceria'],
    };
  },

  computed: {
    uniqueInstallments() {
      return uniqueInstallments(this.installments);
    },
  },

  methods: {
    countInstallmentPrice(period) {
      return parseInt(this.selectedProduct.price / period, 10);
    },

    replaceLogoHost(url) {
      return url ? url.replace(/:\/\/(www.)?/, '://s1.') : '';
    },
  },
};
</script>

<style lang="scss" src="./BlMainInstallmentMv.style.scss" />
