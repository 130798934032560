import EventBus from '~shared-js/event-bus';
import InstallSpa from '~shared-js/product-detail-page/spa';

import { createApp } from './app';
import { initialApp, initialAppId, mountPointId } from './app-constant';
import { SET_BASE_STATE } from './store/mutations';
import { SET_BASE_PRODUCT_STATE, RESET_PRODUCT_STATE } from './store/modules/product/mutations';
import {
  SET_BASE_REVIEW_STATE,
  RESET_REVIEW_STATE,
} from './store/modules/product-reviews/mutations';
import initialLatencyRequest from './store/constants/latency-request';

window.elyVersion = process.env.ELYSIUM_VERSION;

const { Vue, Vuex } = window;
const pdpFragment = document.getElementById('pdp-fragment-data');
const pdpEventBus = new EventBus();
let product;
let reviewResponse;
let user;
let relatedKeywords;
let subsidyEnabled = false;
let merchandiseSellerIds = [];

if (window[initialApp]) {
  const dataString = window[initialApp];

  try {
    ({ product, reviewResponse, user, relatedKeywords, subsidyEnabled, merchandiseSellerIds } =
      JSON.parse(decodeURI(dataString)));
  } catch (e) {
    /** */
  }
}

/** @type Vue */
let app;

const createVueApp = () => {
  if (app && app.$destroy) {
    app.$destroy();
  }
  ({ app } = createApp({ Vue, Vuex }));
};

const initLatencyRequestState = (productData) => {
  const isLogin = !!user?.id;
  const isFirstLoad = !!global[initialApp];
  const isSeller = isLogin && String(user.id) === String(productData.store?.id);
  const state = {
    ...initialLatencyRequest,
    favorite: isLogin,
    me: isLogin && isFirstLoad,
    addresses: isLogin,
    productReviews: productData.rating?.user_count > 0, // eslint-disable-line camelcase
    chat: !isSeller,
    sellerMain: isSeller,
    sellerSecondary: isSeller,
  };
  app.$store.commit(SET_BASE_STATE, {
    name: 'latencyRequest',
    payload: state,
  });
};
const commitProductStore = (payload) => {
  app.$store.commit(`productStore/${SET_BASE_PRODUCT_STATE}`, payload);
};
const initStoreData = () => {
  commitProductStore({ name: 'base36Id', payload: product.id });
  commitProductStore({ name: 'id', payload: parseInt(product.id, 36) });
  app.$store.commit(SET_BASE_STATE, { name: 'relatedKeywords', payload: relatedKeywords });
  if (user) {
    app.$store.commit(SET_BASE_STATE, { name: 'me', payload: user });
  }
  app.$store.dispatch('productStore/commitProduct', product);
  app.$store.commit(SET_BASE_STATE, {
    name: 'toggleSubsidyPdp',
    payload: subsidyEnabled,
  });
  app.$store.commit(SET_BASE_STATE, {
    name: 'merchandiseSellerIds',
    payload: merchandiseSellerIds,
  });
  if (reviewResponse) {
    app.$store.commit(`productReviews/${SET_BASE_REVIEW_STATE}`, {
      name: 'review',
      payload: {
        reviews: reviewResponse.data || [],
        meta: reviewResponse.meta || {},
      },
    });
  }
  initLatencyRequestState(product);
};

const removeInitialData = () => {
  let initPdpScript = document.getElementById(initialAppId);
  if (initPdpScript) {
    initPdpScript.parentElement.removeChild(initPdpScript);
    initPdpScript = null;
    delete global[initialApp];
  }
};

if (product) {
  createVueApp();
  initStoreData();
  removeInitialData();
  product = null;
  reviewResponse = null;
  relatedKeywords = null;
} else if (pdpFragment) {
  // fallback to CSR if SSR went wrong
  const productId = Number(pdpFragment && pdpFragment.dataset.productId);
  if (productId) {
    commitProductStore({ name: 'base36Id', payload: productId.toString(36) });
    commitProductStore({ name: 'id', payload: productId });
  }
}

const reset = () => {
  app.$store.commit(`productStore/${RESET_PRODUCT_STATE}`);
  app.$store.commit(`productReviews/${RESET_REVIEW_STATE}`);
};

const init = (productData) => {
  initLatencyRequestState(productData);
};

InstallSpa({
  app,
  commitProductStore,
  pdpEventBus,
  reset,
  init,
});

window.pdpEventBus = pdpEventBus;
app.$mount(`#${mountPointId}`);
