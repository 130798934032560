import ClientOnly from 'vue-client-only';
import VueScrollTo from 'vue-scrollto';

import VueGlobal from '~shared-js/vue-global';

import ProductDetail from './ProductDetail';
import createStore from './store';

// eslint-disable-next-line
export const createApp = ({ Vue, Vuex, clientArgs = {}, ssr = false }) => {
  if (!ssr) {
    Vue.use(VueScrollTo);
    Vue.component('pdp-client-only', ClientOnly);
    VueGlobal(Vue);
  }

  const store = createStore(Vuex);
  /** @type Vue */
  const app = new Vue({
    name: 'ProductDetailApp',
    store,

    components: {
      ProductDetail,
    },

    template: `<product-detail />`,

    ...clientArgs,
  });

  return { app, store };
};
