<template lang="pug">
  component.c-sort-table(:is="type")
    slot
    slot(name="head")
    slot(name="body" :values="sortedValues")
    slot(name="foot")
</template>

<script>
export default {
  name: 'BlSortTableMv',
  status: 'prototype',
  release: '0.1.0',

  props: {
    type: {
      type: String,
      default: 'table',
    },
    values: {
      type: Array,
      required: true,
      default: null,
    },
    dir: {
      type: String,
      default: 'asc',
    },
    sort: {
      type: String,
      default: 'id',
    },
    ascIcon: {
      type: String,
      default: '',
    },
    descIcon: {
      type: String,
      default: '',
    },
    onSort: {
      type: null,
      default: null,
    },
  },

  // use `provide` to avoid `Slide` being nested with other components
  provide() {
    return {
      sorttable: this,
    };
  },

  data() {
    return {
      currentDir: this.dir,
      currentSort: this.sort,
    };
  },

  computed: {
    sortedValues() {
      if (this.onSort) {
        return this.values;
      }

      return this.values.slice().sort((a, b) => {
        let modifier = 1;
        const valueA = a[this.currentSort];
        const valueB = b[this.currentSort];
        if (this.currentDir === 'desc') {
          modifier = -1;
        }

        if (valueA < valueB) {
          return -1 * modifier;
        }
        if (valueA > valueB) {
          return 1 * modifier;
        }
        if (valueA === undefined || valueA === null) {
          return 1;
        }
        if (valueB === undefined || valueB === null) {
          return -1;
        }
        return 0;
      });
    },
  },

  methods: {
    getCurrentSort() {
      return this.currentSort;
    },

    sortBy(s) {
      // if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentDir = this.currentDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;

      this.$emit('sort-table', this.currentSort, this.currentDir);

      if (this.onSort) {
        this.onSort(this.currentSort, this.currentDir);
      }
    },
  },
};
</script>

<style lang="scss" src="./BlSortTableMv.scss"></style>
