<template>
  <div class="c-main-product__information-list">
    <div v-for="(struct, index) in structs" :key="index" class="c-main-product__information">
      <BlIconAv
        class="c-icon"
        :icon="struct.icon || productDetailTvInjection.icon.icoInfoMinor"
        :color="struct.color"
      />
      <BlShimmerMv v-if="struct.busy" data-testid="loading-indicator">
        <BlShimmerBoxMv slot="groups" width="20em" :r-width="20" :r-height="1" />
      </BlShimmerMv>
      <a v-else-if="struct.link" :href="struct.link || null">
        {{ struct.label }}
      </a>
      <span v-else>
        {{ struct.label }}
      </span>
    </div>
  </div>
</template>

<script>
import { BlIconAv, IcoVerifiedMinor } from '@bukalapak/bazaar-visual-v1';
import { isEmpty } from 'lodash';
import { mapState } from 'vuex';

import BlShimmerMv from '~pdp-mv/BlShimmerMv/BlShimmerMv';
import BlShimmerBoxMv from '~pdp-mv/BlShimmerMv/components/BlShimmerBoxMv';

import DateHelper from '~pdp-utils/date-helper';
import { slaType } from '~shared-js/product-detail-page/constants/sla';

export default {
  name: 'BlMainActionInformation',

  components: {
    BlShimmerMv,
    BlShimmerBoxMv,
    BlIconAv,
  },

  inject: ['productDetailTvInjection'],

  props: {
    product: {
      type: Object,
      required: true,
    },

    minimumQuantity: {
      type: Number,
      default: 1,
    },

    sla: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      neoSlaValue: null,
      loadingNeoSla: false,
    };
  },

  computed: {
    ...mapState(['merchandiseSellerIds']),

    isBLOfficialMerch() {
      const storeId = this.product.store.id;
      return !!this.merchandiseSellerIds?.find(id => String(id) === String(storeId));
    },

    lastOrder() {
      const currentDay = new Date();
      if (!isEmpty(this.product.store.last_order_schedule)) {
        const day = DateHelper.days[currentDay.getDay()].toLowerCase();
        const lastHour = this.product.store.last_order_schedule[day]; // format value= "15:00"
        if (lastHour) {
          const [hour, minute] = lastHour.split(':');
          const currentHour = currentDay.getHours();
          const currentMinute = currentDay.getMinutes();
          const isInRange =
            currentHour < Number(hour) ||
            (currentHour === Number(hour) && currentMinute <= Number(minute));

          return isInRange && lastHour;
        }

        return false;
      }
      return false;
    },

    structs() {
      const structs = [
        {
          label: 'Official Bukalapak, Garansi keaslian barang merupakan produksi Bukalapak resmi',
          icon: IcoVerifiedMinor,
          color: '#ea5164',
          show: this.isBLOfficialMerch,
        },
        {
          label: 'Product Guarantee, 100% uang kembali, bila rusak atau gak asli',
          link:
            'https://www.bukalapak.com/bantuan/sebagai-pembeli/fitur-pembeli/klaim-garansi-barang-bukalapak',
          icon: this.productDetailTvInjection.icon.icoGuaranteeMinor,
          color: '#e31e52',
          show: this.product.assurance,
        },
        {
          label: `Bayar sebelum jam ${this.lastOrder} WIB agar barang dikirim hari ini`,
          show: this.lastOrder,
        },
        {
          label: this.minMaxInformation,
          show: !!this.minMaxInformation,
        },
        {
          label: this.slaInfoHumanized,
          show: !!this.slaInfoHumanized,
          busy: this.loadingNeoSla,
        },
      ];

      return structs.filter(item => item.show);
    },

    slaInfoHumanized() {
      const actualSlaValue =
        this.sla.type === slaType.normal && this.neoSlaValue ? this.neoSlaValue : this.sla.value;
      if (!actualSlaValue) {
        return '';
      }
      switch (this.sla.type) {
        case slaType.sameday:
          return `Waktu Proses Maks ${actualSlaValue} Jam Kerja`;
        case slaType.custom:
          return `Waktu Proses ${actualSlaValue} Hari Kerja`;
        case slaType.normal:
          return '';
        case slaType.preorder:
          return `Waktu Proses Pre-order ${actualSlaValue} Hari`;
        default:
          return '';
      }
    },

    minMaxInformation() {
      const minText = this.minimumQuantity > 1 ? `minimal ${this.minimumQuantity} pcs ` : '';
      const maxText =
        this.product.maxQuantity < 1001 ? `maksimal ${this.product.maxQuantity} pcs` : '';

      return minText || maxText ? `Pembelian ${minText}${maxText}` : '';
    },
  },
};
</script>
