import { isEmpty } from 'lodash';
import qs from 'qs';

/**
 * Append additional query string into the url
 *
 * @param {URL|String} sourceUrl
 * @param {Object} additionalParams
 * @returns {String}
 */
export const appendParams = (sourceUrl, additionalParams, options = {}) => {
  if (!sourceUrl || isEmpty(sourceUrl)) {
    return '';
  }
  const url = new URL(sourceUrl.href || sourceUrl);

  const { hash, pathname } = url;
  const search = url.search ? url.search.slice(1) : '';
  const baseUrl = `${url.origin}${pathname.length > 1 ? pathname : ''}`;
  const queryParams = Object.assign({}, qs.parse(search), additionalParams);
  const queryString = qs.stringify(queryParams, options);

  return `${baseUrl}?${queryString}${hash}`;
};

export const convertToMobileUrl = sourceUrl => {
  if (!sourceUrl || isEmpty(sourceUrl)) {
    return '';
  }
  const url = new URL(sourceUrl.href || sourceUrl);
  const mobileOrigin =
    url.origin.indexOf('preprod') > -1
      ? 'https://m.preproduction.bukalapak.com'
      : url.origin.replace('www', 'm');
  const mobileUrl = new URL(url.pathname, mobileOrigin);

  return `${mobileUrl}${url.search}${url.hash}`;
};
