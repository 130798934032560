import to from '~lib/to';
import { me } from '~pdp/js/modules/api-modules';
import { retrieveTogglesStatus } from '~shared-js/modules/neo-toggles';
import { isString } from '~shared-js/helpers/common-helpers';
import sharedActions from '~shared-js/product-detail-page/store/actions';

import { SET_BASE_STATE, RESET_STATE } from './mutations';

import { RESET_PRODUCT_STATE } from './modules/product/mutations';
import { RESET_REVIEW_STATE } from './modules/product-reviews/mutations';

export default (() => {
  function fetchMe({ commit, dispatch, state }) {
    me.retrieveCurrentUser()
      .then((res) => {
        commit(SET_BASE_STATE, {
          name: 'me',
          payload: {
            ...state.me,
            ...res.data,
          },
        });
      })
      .finally(() => {
        dispatch('setLatencyRequest', { me: false });
      });
  }

  function commitFlashMessage({ commit }, { message, type } = {}) {
    commit(SET_BASE_STATE, {
      name: 'flashMessage',
      payload: {
        message,
        type,
      },
    });
  }

  function setErrorMessage(context, error) {
    const { response } = error;
    let message = '';
    if (!response) {
      message = 'Oops! Sepertinya anda terputus dengan kami. Yuk dicoba lagi';
    } else if (response.status === 401 || response.status === 403) {
      message = `(${response.status}) Sepertinya ada kesalahan pada akun anda, mohon untuk coba login kembali!`;
    } else {
      message = error.response.data.errors.reduce(
        (res, val) => (res ? val.message : `${res}\n${val.message}`),
        '',
      );
    }

    if (isString(error)) {
      message = error;
    }

    this.dispatch('commitFlashMessage', { message, type: 'error' });
  }

  function clearAllStates({ commit }) {
    commit(RESET_STATE);
    commit(`productStore/${RESET_PRODUCT_STATE}`);
    commit(`productReviews/${RESET_REVIEW_STATE}`);
  }

  function setErrorVariantSelection({ commit }, payload) {
    commit(SET_BASE_STATE, {
      name: 'errorVariantSelection',
      payload,
    });
  }

  async function getToggles({ commit }) {
    const toggleIds = {
      toggleVariantNotPreSelected: 'buyer-choose-variant-option-no-prefilled-enabled',
    };

    const [err, res] = await to(retrieveTogglesStatus(Object.values(toggleIds)));

    if (!err && res.data && res.data.length) {
      res.data.forEach((val, index) => {
        const { active } = val;
        commit(SET_BASE_STATE, {
          name: Object.keys(toggleIds)[index],
          payload: active,
        });
      });
    }
  }

  return {
    ...sharedActions,
    clearAllStates,
    commitFlashMessage,
    fetchMe,
    setErrorMessage,
    setErrorVariantSelection,
    getToggles,
  };
})();
