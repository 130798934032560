<template lang="pug">
  .c-sort-link(@click="clickSort(name)")
    slot
    .c-sort-link__icon
      span(v-html="sorttable.ascIcon")
      span(v-html="sorttable.descIcon")
</template>

<script>
export default {
  name: 'BlSortLinkMv',
  props: {
    name: {
      type: String,
      required: true,
      default: '',
    },
  },

  inject: ['sorttable'],

  methods: {
    clickSort(name) {
      this.sorttable.sortBy(name);
    },
  },
};
</script>

<style src="./BlSortLinkMv.scss"></style>
