<template lang="pug">
  .c-reviews-filter
    .c-reviews-filter__modifiers.u-mrgn-bottom--2
      .c-reviews-filter__item(
        v-for="mod in modifiers"
        :class="checkIsActive(mod.value)"
        @click="selectFilter(mod.value)"
      ) {{ mod.label }}

    .c-reviews-filter__stars
      .c-reviews-filter__item(
        v-for="i in [1, 2, 3, 4, 5]"
        :key="i"
        :class="checkIsActive(i)"
        @click="selectFilter(i)"
      )
        bl-rating-mv(:value="100")
        | &#32;{{ i }}
</template>

<script>
import _ from 'lodash';
import BlRatingMv from '@bukalapak/bazaar-dweb/dist/components/BlRatingMv';

export default {
  name: 'BlReviewsFilterMv',

  components: {
    BlRatingMv,
  },

  props: {
    commitFilter: {
      type: Function,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modifiers: [
        {
          label: 'Semua',
          value: 0,
        },
        {
          label: 'Dengan Foto',
          value: 6,
        },
        {
          label: 'Dengan Deskripsi',
          value: 7,
        },
      ],
    };
  },

  computed: {
    normalizedFilter() {
      return _.pickBy(this.filter, _.identity);
    },
  },

  methods: {
    checkIsActive(num) {
      let isActive = false;
      switch (true) {
        case num === 0:
          isActive = _.isEmpty(this.normalizedFilter);
          break;
        case num <= 5:
          isActive = this.normalizedFilter.star === num;
          break;
        case num === 6:
          isActive = this.normalizedFilter.withPicture;
          break;
        default:
          isActive = this.normalizedFilter.withDescription;
          break;
      }
      return isActive ? 'c-reviews-filter--active' : '';
    },

    selectFilter(num) {
      this.commitFilter(num);
    },
  },
};
</script>

<style src="./BlReviewsFilterMv.style.scss" lang="scss"></style>
