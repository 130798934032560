<template lang="pug">
  .c-reviews-item
    .c-reviews-item__head.u-mrgn-bottom--3
      .c-reviews-item__head-content
        bl-flex-container-mv
          bl-flex-item-av(v-for="i in 5" :key="i")
            bl-preloader-av(
              :wrapper-style="{ width: '24px', marginRight: '6px' }"
              :loading-style="{ height: '24px' }"
              loading-class="u-border-radius--50"
            )
        bl-preloader-av(
          :wrapper-style="{ width: '120px', marginBottom: '2px' }"
          :loading-style="{ height: '16px' }"
          loading-class="u-border-radius--10"
        )
        bl-preloader-av(
          :wrapper-style="{ width: '80px' }"
          :loading-style="{ height: '12px' }"
          loading-class="u-border-radius--10"
        )
      .c-reviews-item__head-img
        bl-preloader-av(
          v-for="i in 3"
          :key="i"
          :wrapper-style="{ width: '64px', marginLeft: '12px' }"
          :loading-style="{ height: '64px' }"
          loading-class="u-border-radius--10"
        )
    bl-preloader-av(
      :wrapper-style="{ width: '80%' }"
      :loading-style="{ height: '12px' }"
      loading-class="u-border-radius--10"
    )
    bl-preloader-av(
      :wrapper-style="{ width: '40%', marginTop: '2px' }"
      :loading-style="{ height: '12px' }"
      loading-class="u-border-radius--10"
    )
    .c-reviews-item__foot.u-mrgn-top--3
      .c-reviews-item__user
        bl-preloader-av.u-mrgn-right--2(
          :wrapper-style="{ width: '32px' }"
          :loading-style="{ height: '32px' }"
          loading-class="u-border-radius--50"
        )
        bl-preloader-av(
          :wrapper-style="{ width: '120px' }"
          :loading-style="{ height: '18px' }"
          loading-class="u-border-radius--10"
        )
</template>

<script>
import { BlPreloaderAv } from '@bukalapak/bazaar-dweb';

import BlFlexItemAv from '~pdp-av/BlFlexItemAv';
import BlFlexContainerMv from '~pdp-mv/BlFlexContainerMv';

export default {
  name: 'BlReviewsItemShimmerMv',

  components: {
    BlFlexItemAv,
    BlPreloaderAv,
    BlFlexContainerMv,
  },
};
</script>
