import getInitialSharedState from '~shared-js/product-detail-page/store/state';
import latencyRequest, { SECTIONS } from './constants/latency-request';

export const getInitialState = () => ({
  ...getInitialSharedState(),
  category: {},
  flashMessage: {
    message: '',
    type: '',
  },
  me: {},
  relatedKeywords: [],
  similarProducts: [],
  merchandiseSellerIds: [],
  errorVariantSelection: false,
  toggleVariantNotPreSelected: false,
  toggleSubsidyPdp: false,
  toggleVariantImageRevamp: false,
  isFinishedFetchBadgeToggle: false,
  isSuperSellerNewBadgeActive: false,
  latencyRequest,
  latencyRequestSections: SECTIONS,
});

export default getInitialState;
