
<template lang="pug">
  .bl-shimmer-container
    .bl-shimmer-wrapper(:style="widthStyle")
      slot(name="groups")
        bl-shimmer-group-mv
          slot
            bl-shimmer-line-mv
</template>

<script>
import BlShimmerGroupMv from './components/BlShimmerGroupMv.vue'
import BlShimmerCircleMv from './components/BlShimmerCircleMv.vue'
import BlShimmerGapMv from './components/BlShimmerGapMv.vue'
import BlShimmerLineMv from './components/BlShimmerLineMv.vue'

import mixin from './mixins'

export default {
  components: {
    BlShimmerGroupMv,
    BlShimmerCircleMv,
    BlShimmerGapMv,
    BlShimmerLineMv,
  },
  mixins: [ mixin ],
  props: {
    height: {
      type: Number,
      default: 16,
    },
    width: {
      type: Number | String,
      default: '100%',
    },
  },
}
</script>

<style lang="scss" src="./BlShimmerMv.style.scss"></style>

