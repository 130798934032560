<template>
  <div class="c-bl-breadcrumb">
    <ul class="c-bl-breadcrumb__list">
      <li class="c-bl-breadcrumb__item" @click="clickItem({ label: 'home' })">
        <a class="c-bl-breadcrumb__item-text u-fg-hover--red-brand" href="/" rel="nofollow">Home</a>
      </li>

      <template v-for="(path, index) in paths">
        <li :key="path.url" class="c-bl-breadcrumb__item" @click="clickItem(path, index + 1)">
          <BlIconAv class="c-bl-breadcrumb__delimeter" variant="chevron-right" />

          <template v-if="path.url && !checkCurrentUrl(path.url)">
            <a
              :href="path.url"
              class="c-bl-breadcrumb__item-text u-fg-hover--red-brand"
              rel="nofollow"
              >{{ path.label }}</a
            >
          </template>

          <template v-else>
            <span class="c-bl-breadcrumb__item-text active">
              {{ path.label }}
            </span>
          </template>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import BlIconAv from '@bukalapak/bazaar-dweb/dist/components/BlIconAv';

export default {
  name: 'BlBreadcrumbMv',

  components: {
    BlIconAv,
  },

  props: {
    paths: {
      type: Array,
      required: true,
    },
  },

  methods: {
    checkCurrentUrl(url) {
      const { location } = global;
      if (location) {
        return (
          url === location.href ||
          url === location.pathname ||
          url === `${location.pathname}${location.search}`
        );
      }
      return false;
    },

    clickItem(path, index) {
      this.$emit('click', {
        path,
        index,
      });
    },
  },
};
</script>

<style src="./BlBreadcrumbMv.scss" lang="scss"></style>
