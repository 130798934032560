/**
 * Get document's cookie by name 🍪
 *
 * @param {String} cname cookie name
 * @return {String}
 *
 */
export const getCookie = cname => {
  const match = document.cookie.match(new RegExp(`(^| )${cname}=([^;]+)`));
  if (match) {
    return match[2] || '';
  }
  return '';
};

export default {
  getCookie,
};
