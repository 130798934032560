/**
 * Mini Docs
 *
 * This fetch neo config mixin is used to fetch neo config as client side rendering
 * Rules to consume:
 * - create neoConfigSettings in vue data
 * - the data should be following this example:
 *   {
        'neo-config-id-one': {
          dataName: 'oneNeoConfigNameOnVueData',
          map: config => {
            // the mapper, return expected mapped config
          },
        },
      }
  * - map function is optional, if falsy, then use the config as-is
 */

import to from '~lib/to';
import { postConfigs } from '~shared-js/modules/neo-configs';

export default {
  mounted() {
    this.fetchNeoConfigs();
  },
  methods: {
    async fetchNeoConfigs() {
      const [, res] = await to(postConfigs(Object.keys(this.neoConfigSettings)));

      if (res?.data?.length) {
        res.data.forEach(({ data: config, id }) => {
          const { dataName, map } = this.neoConfigSettings[id];
          if (config) this[dataName] = (map ? map(config) : config) || config;
        });
      }
    },
  },
};
