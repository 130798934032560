<template>
  <section class="section section--bottom">
    <pdp-client-only>
      <BlProductListMv
        v-if="similarProducts.length >= 6"
        class="c-product-details-listing--similar"
        title="Barang Terkait"
        :data-testid="forSale ? 'btn-more' : undefined"
        :more-url="forSale ? moreUrl : ''"
        rel="nofollow"
      >
        <BlFlexContainerMv wrap="wrap">
          <BlFlexItemAv v-for="item in similarProducts" :key="item.id" :col="2">
            <BlProductCardMv
              :product="item"
              :is-finished-fetch-badge-toggle="isFinishedFetchBadgeToggle"
              :is-super-seller-new-badge-active="isSuperSellerNewBadgeActive"
              @click="productSimilarClick(item)"
            />
          </BlFlexItemAv>
        </BlFlexContainerMv>
        <div v-if="!forSale" :style="{ 'text-align': 'center' }">
          <BlButtonAv data-testid="btn-more-unavailable" :href="moreUrl" rel="nofollow">
            Lihat Semua Barang Terkait
          </BlButtonAv>
        </div>
      </BlProductListMv>
      <template slot="placeholder">
        <BlProductListMv
          class="c-product-details-listing--similar"
          title="Barang Terkait"
          :more-url="moreUrl"
          rel="nofollow"
        >
          <BlProductCardPlaceholderMv />
        </BlProductListMv>
        <BlProductListMv class="c-product-details-listing--similar" title="Rekomendasi Buat Kamu">
          <BlProductCardPlaceholderMv />
        </BlProductListMv>
      </template>
    </pdp-client-only>
  </section>
</template>

<script>
import { BlButtonAv } from '@bukalapak/bazaar-dweb';
import { mapActions, mapState } from 'vuex';
import { lowerCase } from 'lodash';

import BlFlexItemAv from '~pdp-av/BlFlexItemAv';
import BlFlexContainerMv from '~pdp-mv/BlFlexContainerMv';
import BlProductListMv from '~pdp-mv/BlProductListMv';
import { promotedPushProducts } from '~pdp/js/modules/api-modules';

import BlProductCardPlaceholderMv from '~shared-js/components/BlProductCardPlaceholderMv';
import { parseRuntimeEnv } from '~shared-js/runtime-parser';

const BlProductCardMv = () =>
  import(
    /* webpackChunkName: 'bl-product-card-mv' */ '~shared-js/components/BlProductCardMv/BlProductCardMv'
  );

export default {
  name: 'BlSimilarProductOv',

  components: {
    BlButtonAv,
    BlFlexItemAv,
    BlFlexContainerMv,
    BlProductCardMv,
    BlProductCardPlaceholderMv,
    BlProductListMv,
  },

  inject: ['productDetailTvInjection'],

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      similarProducts(state) {
        const total = state.similarProducts.length;
        const n = total - (total % 6);
        return state.similarProducts.slice(0, Math.min(n, 18));
      },
    }),

    ...mapState(['isFinishedFetchBadgeToggle', 'isSuperSellerNewBadgeActive']),

    forSale() {
      return !this.productDetailTvInjection.notSaleState;
    },

    moreUrl() {
      if (this.product.id) {
        const name = lowerCase(this.product.name).replace(/ /g, '-');
        const productUrl = `${this.product.id}-jual-${name}/related`;
        return `/products/${productUrl}`;
      }

      return '';
    },

    isAboveCutoffDate() {
      return (
        new Date(this.product.createdAt) >=
        new Date(
          typeof window === 'undefined'
            ? process.env.PRODUCT_NOINDEX_CUTOFFDATE
            : parseRuntimeEnv().PRODUCT_NOINDEX_CUTOFFDATE,
        )
      );
    },
  },

  mounted() {
    this.fetchSimilarProducts({
      id: this.product.id,
      limit: this.forSale ? 6 : 18,
    });
  },

  methods: {
    ...mapActions(['fetchSimilarProducts']),

    productSimilarClick(product) {
      promotedPushProducts.$charge.chargeProductSPromotedPush(product.id, {
        data: { section: 'desktop similar products' },
        headers: { 'X-Identity': global.IDENTITY },
      });
    },
  },
};
</script>
