<template lang="pug">
  .c-cell-info(:class="{'c-cell-info__separator': separator}")
    .c-cell-info__label {{ label }}
    .c-cell-info__content.u-txt--base(:title="title")
      slot(name="icon")
      .u-mrgn-left--1
        .c-cell-info__title {{ title }}
        .c-cell-info__desc {{ description }}
</template>

<script>
export default {
  name: 'BlCellInfoMv',
  props: {
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    separator: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" src="./BlCellInfoMv.scss"></style>
