<template>
  <div class="c-delivery c-main-product__separator">
    <div class="c-main-product__label">Pengiriman</div>
    <div class="c-delivery__wrapper">
      <DeliveryLocationOv v-bind="locationProps" @onSelectAddress="getAndSetShipping" />

      <BlPreloaderAv
        v-if="isLoading"
        class="u-mrgn-top--3"
        :wrapper-style="{ maxWidth: '520px' }"
        :loading-style="{ height: '44px' }"
        loading-class="u-border-radius--3"
      />
      <CourierSortMv
        v-else-if="!isLoading && courierList.length"
        :active-courier.sync="selectedCourier"
        :has-fee="fees.length > 0"
        :values="courierList"
      />
    </div>
  </div>
</template>

<script>
import { BlPreloaderAv } from '@bukalapak/bazaar-dweb';
import { mapActions, mapState } from 'vuex';
import { isEmpty } from 'lodash';

import CourierSortMv from '~pdp-mv/CourierSortMv';
import DeliveryLocationOv from '~pdp-ov/DeliveryLocationOv';

import { DEFAULT_ADDRESS } from './constants/address';
import { courierStructs } from './constants/courier';

export default {
  name: 'DeliveryInfoOv',
  components: {
    BlPreloaderAv,
    CourierSortMv,
    DeliveryLocationOv,
  },
  inject: ['productDetailTvInjection'],
  props: {
    me: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEmptyUserAddresses: false,
      isLoading: false,
      selectedCourier: {},
    };
  },
  computed: {
    ...mapState('shipping', ['addresses', 'fees', 'selectedAddress']),

    currentAddress() {
      if (!isEmpty(this.selectedAddress)) {
        return this.selectedAddress;
      }

      if (isEmpty(this.selectedAddress) && this.me.address?.city) {
        return this.me.address;
      }

      return DEFAULT_ADDRESS;
    },

    courierList() {
      if (this.fees.length) {
        const fees = this.fees.reduce((list, val, index) => {
          const res = [].concat(list, Object.assign({}, val));
          if (val.eta) {
            const hasDash = /[,-]/.test(val.eta);
            const eta = Number(val.eta.split('-')[0]);

            if (val.eta_info === 'hari') {
              if (hasDash) {
                res[index].etaNumber = eta * 24 + 1;
              } else {
                res[index].etaNumber = eta * 24;
              }
            } else if (val.eta_info === 'jam') {
              res[index].etaNumber = eta;
            } else {
              res[index].etaNumber = 720;
            }
          }

          if (val.error) {
            res[index].price = undefined;
            res[index].etaNumber = undefined;
          }
          return res;
        }, []);
        return fees;
      }

      if (this.product.store && this.product.store.carriers) {
        return this.getStoreCouriers();
      }

      return [];
    },

    locationProps() {
      return {
        currentAddress: this.currentAddress,
        product: this.product,
      };
    },

    product() {
      return this.productDetailTvInjection.product;
    },
  },

  mounted() {
    // Get User addresses
    if (this.$isLogin()) {
      this.fetchUserAddresses();
    } else {
      this.getAndSetShipping();
    }
  },

  methods: {
    ...mapActions('shipping', ['getShippingPrice', 'getUserAddresses']),

    fetchUserAddresses() {
      this.getUserAddresses()
        .then((res) => {
          if (res.data.length < 1) {
            this.isEmptyUserAddresses = true;
          }
        })
        .catch(() => {
          this.isEmptyUserAddresses = true;
        })
        .finally(() => {
          this.getAndSetShipping();
        });
    },

    getAndSetShipping(address) {
      this.isLoading = true;
      const params = {
        address: address?.district ? address : this.currentAddress,
        quantity: this.product.min_quantity || 1,
      };
      this.getShippingPrice(params)
        .then(() => {
          this.selectedCourier = this.fees[0];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getStoreCouriers() {
      const { store } = this.product;
      const couriers = store.carriers
        .filter((service) => !service.match(/bayar/i))
        .map((service) => {
          let matchedCourier = {};
          for (let i = 0; i < courierStructs.length; i += 1) {
            const res = courierStructs[i].regex.exec(service.toLowerCase());
            if (res && res.index >= 0) {
              matchedCourier = {
                courierName: courierStructs[i].name,
                service,
              };
              break;
            }
          }
          return matchedCourier;
        });

      this.selectedCourier = couriers[0];
      return couriers;
    },
  },
};
</script>

<style lang="scss" src="./DeliveryInfoOv.scss"></style>
