export function parseRuntimeEnv() {
  if (process.server) return {};

  const fragment = document.getElementsByClassName('elysium')[0];
  let runtimeEnv = {};

  if (fragment) {
    const runtimeEnvString = fragment.getAttribute('data-runtime-env');

    try {
      runtimeEnv = JSON.parse(decodeURIComponent(runtimeEnvString));
    } catch (e) {
      // invalid runtime-env data attribute
    }
  }

  return runtimeEnv;
}
