<template lang="pug">
#product-details-page
  transition(name='slide')
    BlFlashMessageMv(v-if='flashMessage.message', size-icon='base', withIcon)

  pdp-client-only
    BlBuyNowHeaderOv(v-if='!isSeller && !notSaleState && product')

  #inner-sections
    section.section.section--top.u-mrgn-bottom--4
      BlBreadcrumbMv(v-if='breadcrumbPaths.length', :paths='breadcrumbPaths')
      pdp-client-only
        BlUnavailableMv(v-if='notSaleState')
      BlMainProductOv(@updated='$emit("updated")')

    section.section.section--middle.u-mrgn-bottom--4
      BlInformationOv(v-if='!isEmpty(product)', :product='product')

      BlSectionMv#section-informasi-pelapak(v-if='!isEmptyStore', :aside-affix='false')
        template(slot='aside')
          h2.c-product-details-section__aside--sticky Informasi Pelapak
        template(slot='main')
          BlSellerOv(:store='product.store')

      BlSectionMv#section-ulasan-barang(
        v-if='product.rating && product.rating.user_count > 0',
        :aside-affix='false'
      )
        template(slot='aside')
          h2.c-product-details-section__aside--sticky Ulasan Barang
        template(slot='main')
          BlReviewsMv(:product='product')

  BlSimilarProductOv(:product='product')

  BlReviewsDialogMv(v-if='dialog.active')
</template>

<script>
import BlPreloaderAv from '@bukalapak/bazaar-dweb/dist/components/BlPreloaderAv';
import { humanizeTimestamp } from '@bukalapak/toolbox-helper/date-helper';
import {
  IcoChevronRightMinor,
  IcoChevronLeftMinor,
  IcoFlag,
  IcoFreeshipping,
  IcoInfoMinor,
  IcoGuaranteeMinor,
  IcoHeart,
  IcoHeartOutline,
} from '@bukalapak/bazaar-visual-v1';
import _ from 'lodash';
import { ReactiveProvideMixin } from 'vue-reactive-provide';
import { mapState, mapActions } from 'vuex';

import BlReviewsMv from '~pdp-mv/BlReviewsMv';
import BlMainProductOv from '~pdp-ov/BlMainProductOv';
import BlInformationOv from '~pdp-ov/BlInformationOv';
import BlSellerOv from '~pdp-ov/BlSellerOv';
import { isOnDeal } from '~pdp-utils/product-helper';
import { BlCarouselMv, BlCarouselSlideMv } from '~shared-js/components/BlCarouselMv';
import BlBreadcrumbMv from '~shared-mv/BlBreadcrumbMv';
import BlBuyNowHeaderOv from '~pdp-ov/BlBuyNowHeaderOv';

import BlSimilarProductOv from './components/BlSimilarProductOv';

const BlProductListMv = () =>
  import(/* webpackChunkName: "bl-product-list-mv" */ '~pdp-mv/BlProductListMv');
const BlSectionMv = () => import(/* webpackChunkName: "bl-section-mv" */ '~pdp-mv/BlSectionMv');
const BlReviewsDialogMv = () =>
  import(
    /* webpackChunkName: "bl-review-dialog-mv" */ '~pdp-mv/BlReviewsMv/components/BlReviewsDialogMv'
  );
const BlUnavailableMv = () =>
  import(/* webpackChunkName: "bl-unavailable-mv" */ '~pdp-mv/BlUnavailableMv');
const BlFlashMessageMv = () =>
  import(/* webpackChunkName: "bl-flash-message-mv" */ '~pdp-mv/BlFlashMessageMv');

const { USER } = global;
const DEFAULT_STORE_CLOSED = 'Yah, Lapaknya Tutup Sementara';
const DEFAULT_NO_STOCK = 'Stok Habis';
const ICONS = {
  icoChevronRightMinor: IcoChevronRightMinor,
  icoChevronLeftMinor: IcoChevronLeftMinor,
  icoFlag: IcoFlag,
  icoFreeshipping: IcoFreeshipping,
  icoInfoMinor: IcoInfoMinor,
  icoGuaranteeMinor: IcoGuaranteeMinor,
  icoHeart: IcoHeart,
  icoHeartOutline: IcoHeartOutline,
};

export default {
  name: 'BlProductDetailTv',

  components: {
    BlPreloaderAv,
    BlBreadcrumbMv,
    BlCarouselMv,
    BlCarouselSlideMv,
    BlFlashMessageMv,
    BlProductListMv,
    BlReviewsMv,
    BlReviewsDialogMv,
    BlUnavailableMv,
    BlSectionMv,
    BlBuyNowHeaderOv,
    BlInformationOv,
    BlMainProductOv,
    BlSellerOv,
    BlSimilarProductOv,
  },

  mixins: [
    ReactiveProvideMixin({
      name: 'productDetailTvInjection',
      include: [
        'icon',
        'hasDeal',
        'hasSubsidy',
        'hasWholesale',
        'isLogin',
        'isNoStock',
        'isPreorder',
        'isSeller',
        'minimumQuantity',
        'notSaleState',
        'product',
        'selectedProduct',
        'defaultVariantProduct',
      ],
    }),
  ],

  data() {
    return {
      icon: ICONS,
    };
  },

  computed: {
    ...mapState(['category', 'flashMessage', 'relatedKeywords', 'me', 'toggleSubsidyPdp']),
    ...mapState('productStore', [
      'base36Id',
      'product',
      'rawProduct',
      'selectedVariant',
      'defaultVariant',
    ]),
    ...mapState('productReviews', ['dialog']),

    breadcrumbPaths() {
      if (this.category.url) {
        const { structure, urlStructure } = this.category;
        let categoryUrl = '';
        const paths = structure.map((struct, index) => {
          categoryUrl = `${categoryUrl}/${urlStructure[index]}`;

          return {
            label: struct,
            url: `/c${categoryUrl}`,
          };
        });
        paths.push({
          label: this.product.name,
          url: global.location && global.location.href,
        });

        return paths;
      }

      return [];
    },

    isEmptyStore() {
      return this.product && _.isEmpty(this.product.store);
    },

    notSaleState() {
      let state = {};

      if (!_.isEmpty(this.product)) {
        state = this.checkStore();
        const descReason = this.product.get('stateDescription[0]');
        if (descReason) {
          state.reason = descReason;
        } else if (_.isEmpty(state)) {
          state = this.checkProduct();
        }
      }

      return !_.isEmpty(state) && state;
    },

    hasDeal() {
      if (this.toggleSubsidyPdp) {
        return this.selectedProduct.discountPercentage > 0;
      }

      if (!_.isEmpty(this.selectedProduct.deal)) {
        const { deal } = this.selectedProduct;

        return !!deal.percentage && isOnDeal(deal.applied_date, deal.expired_date);
      }

      return false;
    },

    hasSubsidy() {
      return this.toggleSubsidyPdp && !!this.selectedProduct.discountSubsidy;
    },

    hasWholesale() {
      return (
        this.selectedProduct.wholesales && this.selectedProduct.wholesales.length && !this.hasDeal
      );
    },

    isLogin() {
      return USER && !!USER.id;
    },

    isNoStock() {
      return this.productStock < this.minimumQuantity;
    },

    isSeller() {
      if (global.USER && this.product?.get) {
        const storeId = this.product.get('store.id');
        return global.USER.id && storeId && storeId.toString() === USER.id.toString();
      }
      return false;
    },

    hasSelectedVariant() {
      return this.product.hasVariant && this.selectedVariant.id && this.selectedVariant.product;
    },

    productStock() {
      return this.hasSelectedVariant
        ? this.selectedVariant.product.stock
        : this.defaultVariantProduct.stock;
    },

    minimumQuantity() {
      return this.product.minQuantity || 1;
    },

    sla() {
      const { sla, store } = this.product;
      if (sla || store) {
        return (sla.type && sla) || (store && store.sla);
      }
      return null;
    },

    isPreorder() {
      if (this.sla && this.sla.type === 'preorder') {
        return true;
      }
      return this.sla && this.sla.type === 'preorder';
    },

    defaultVariantProduct() {
      return this.defaultVariant.id
        ? Object.assign({}, this.product, this.defaultVariant.product, {
            deal: {
              ...this.product.deal,
              ...this.defaultVariant.product.deal,
            },
          })
        : this.product;
    },

    selectedProduct() {
      return this.selectedVariant.id
        ? Object.assign({}, this.defaultVariantProduct, this.selectedVariant.product, {
            deal: {
              ...this.selectedVariant.product.deal,
            },
          })
        : this.defaultVariantProduct;
    },
  },

  mounted() {
    this.setNotForSaleLatencyRequest();
  },

  methods: {
    isEmpty: _.isEmpty,
    ...mapActions(['setLatencyRequest']),

    checkStore() {
      if (!this.isEmptyStore) {
        const { store } = this.product;
        const closed = this.product.get('store.closing.closed');
        if (closed) {
          const d = store.closing.reopen_date
            ? humanizeTimestamp(store.closing.reopen_date, '%dd% %month% %yyyy%')
            : '';

          return {
            reason: store.closing.closed_reason || DEFAULT_STORE_CLOSED,
            reopenDate: d,
          };
        }
      }
      return {};
    },

    checkProduct() {
      const { hasVariant, variants } = this.product;
      const isNoStock = hasVariant === false && this.product.stock < this.product.minQuantity;
      let isNoVariant = false;
      if (hasVariant && variants) {
        isNoVariant = variants.every(
          (v) => v.stock < this.product.minQuantity || v.state !== 'active',
        );
      }

      if (isNoStock || isNoVariant) {
        return {
          reason: DEFAULT_NO_STOCK,
        };
      }
      return {};
    },

    setNotForSaleLatencyRequest() {
      if (this.notSaleState) {
        // Set this latency to false as the component doesn't get render when product not for sale
        this.setLatencyRequest({
          ...this.$store.state.latencyRequest,
          chat: false,
        });
      }
    },
  },
};
</script>
