<template lang="pug">
  bl-flex-container-mv
    bl-flex-item-av(
      v-for="(i, index) in 6"
      :key="i"
      :col="2"
      :grow="1"
      :class="index === 0 ? '' : 'u-mrgn-left--4'"
    )
      bl-preloader-av(
        :wrapper-style="{width: '100%', marginBottom: '12px'}"
        :loading-style="{ paddingBottom: '100%' }"
      )
      bl-preloader-av(
        :wrapper-style="{ width: '50%', marginBottom: '12px' }"
        :loading-style="{ height: '12px' }"
        :loading-class="loadingClass"
      )
      bl-preloader-av(
        :wrapper-style="{ width: '50%', marginBottom: '12px' }"
        :loading-class="loadingClass"
      )
      bl-preloader-av(
        :wrapper-style="{ width: '100%', marginBottom: '6px' }"
        :loadingStyle="{ height: '6px' }"
        :loading-class="loadingClass"
      )
      bl-preloader-av(
        :wrapper-style="{ width: '100%' }"
        :loadingStyle="{ height: '9px' }"
        :loading-class="loadingClass"
      )
</template>

<script>
import { BlPreloaderAv } from '@bukalapak/bazaar-dweb';

import BlFlexItemAv from '~pdp-av/BlFlexItemAv';
import BlFlexContainerMv from '~pdp-mv/BlFlexContainerMv';

export default {
  name: 'BlProductCardPlaceholderMv',

  components: { BlPreloaderAv, BlFlexItemAv, BlFlexContainerMv },

  data() {
    return {
      loadingClass: 'u-border-radius--2',
    };
  },
};
</script>
