<template lang="pug">
  bl-flex-container-mv.c-product-tag
    bl-flex-item-av(v-for="tag in tags" :key="tag.url")
      bl-button-av.u-txt--capitalize(:href="tag.url" target="_blank" rel="nofollow") {{ tag.name }}
</template>

<script>
import { BlButtonAv } from '@bukalapak/bazaar-dweb';

import BlFlexItemAv from '~pdp-av/BlFlexItemAv';
import BlFlexContainerMv from '~pdp-mv/BlFlexContainerMv';

export default {
  name: 'BlTagMv',

  components: {
    BlFlexItemAv,
    BlButtonAv,
    BlFlexContainerMv,
  },

  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" src="./BlTagMv.style.scss"></style>
