<template lang="pug">
  bl-section-mv.c-main-product(id="section-main-product" :aside-affix="false")
    template(slot="aside")
      bl-main-preview-mv(:product="product")

    template(slot="main" v-if="!isEmpty(product)")
      bl-ads-mv
      bl-main-head-mv(:product="product" :me="me")
      bl-flex-container-mv
        bl-flex-item-av(:col="isSeller ? 7 : 12")
          bl-main-price-mv
          pdp-client-only
            bl-main-wholesale-mv(v-if="hasWholesale" :prices="selectedProduct.wholesales")
            bl-main-installment-mv(
              v-if="hasInstallment && isForSale"
              :installments="product.installments"
              :selected-product="selectedProduct"
            )
            bl-popover-av
              img(v-if="Object.keys(labelExtraDiscount).length != 0"
                :key="labelExtraDiscount.id"
                :src="labelExtraDiscount.icon_url"
                :alt="labelExtraDiscount.alt"
                style="display: block; height: 20px; cursor: pointer;"     
              ) 
              template(slot="content" style="max-width: 600px;")
                div(
                  v-html="tncConfig.content" 
                  style="width: 100%; height: 300px; overflow-y: scroll; padding: 15px;"
                )
        pdp-client-only
          bl-flex-item-av(v-if="isSeller" :col="5")
            bl-main-seller-action-mv(
              :product="product"
              :seller-frozen="me && me.unfreezing && me.unfreezing.frozen"
              @updated="$emit('updated')"
            )

      pdp-client-only
        transition(name="slide")
          bl-main-variant-mv(
            v-if="hasVariant"
            :product="product"
            :show-promo-snackbar="productDetailTvInjection.hasSubsidy"
          )
        bl-stock-av(
          v-if="!notSaleState"
          :stock="selectedProduct.stock"
          :min-quantity="product.minQuantity || 1"
          :variant-name="selectedVariant.name"
        )
        bl-cell-info-mv.c-main-product__itemku(
          v-if="isItemkuProduct"
          :label="itemkuPowered.label"
          :title="itemkuPowered.title"
          :description="itemkuPowered.description"
          separator
        )
          template(v-slot:icon="")
            img.c-main-product__itemku__image(
              :src="itemkuPowered.imageUrl"
              alt="itemku-image"
            )
        template(v-if="isDigitalGoods")
          bl-cell-info-mv(
            :label="withoutDelivery.label"
            :title="withoutDelivery.title"
            :description="withoutDelivery.description"
            :separator="!isItemkuProduct"
          )
            template(v-slot:icon="")
              free-shipping-svg
          bl-cell-info-mv(
            :label="productType.label"
            :title="productType.title"
            :description="productType.description"
          )
            template(v-slot:icon="")
              phone-iphone-svg

        DeliveryInfoOv(v-if="!isDigitalGoods" :me="me" :product="product")

        bl-seller-secondary-action-mv(
          v-if="isSeller"
          :product="product"
          :for-sale="!notSaleState"
          @updated="$emit('updated')"
        )

        div(slot="placeholder")
          bl-preloader-av.u-mrgn-bottom--2(
            :wrapper-style="{width: '250px'}"
            :loading-style="{height: '30px'}"
            :loading-class="'u-border-radius--2'"
          )
          bl-preloader-av.u-mrgn-bottom--2(
            :wrapper-style="{width: '250px'}"
            :loading-style="{height: '30px'}"
            :loading-class="'u-border-radius--2'"
          )
          bl-preloader-av.c-main-product__separator(
            :wrapper-style="{width: '100%'}"
            :loading-style="{height: '90px'}"
            :loading-class="'u-border-radius--2'"
          )

        bl-main-action-mv(
          v-if="!isSeller && isForSale"
          :product="product"
          :rawProduct="rawProduct"
        )

        BlMainActionInformationMv(v-bind="informationProps")

        bl-main-scan-mv(:product="{'name':product.name, 'img':product.images.small_urls[0], 'id': product.id}")
</template>

<script>
import { BlButtonAv, BlPopoverAv } from '@bukalapak/bazaar-dweb';
import { shippingFees, neo } from '~pdp/js/modules/api-modules';
import to from '~lib/to';
import { retrieveTogglesStatus } from '~shared-js/modules/neo-toggles';
import { isEmpty } from 'lodash';
import BlPreloaderAv from '@bukalapak/bazaar-dweb/dist/components/BlPreloaderAv';
import { mapState } from 'vuex';

import BlFlexItemAv from '~pdp-av/BlFlexItemAv';
import BlStockAv from '~pdp-av/BlStockAv';
import BlSectionMv from '~pdp-mv/BlSectionMv';
import BlFlexContainerMv from '~pdp-mv/BlFlexContainerMv';
import {
  BlMainActionMv,
  BlMainHeadMv,
  BlMainPreviewMv,
  BlMainPriceMv,
  BlMainWholesaleMv,
  BlMainInstallmentMv,
  BlMainScanMv,
} from '~pdp-mv/BlMainProductMv';
import BlMainActionInformationMv from '~pdp-mv/BlMainProductMv/components/BlMainActionMv/BlMainActionInformation';
import DeliveryInfoOv from '~pdp-ov/DeliveryInfoOv';
import BlCellInfoMv from '~shared-js/components/BlCellInfoMv';
import PhoneIphoneSvg from '~shared-js/components/PhoneIphoneSvg';

import ItemkuTagMixin from '~pdp-shared-js/mixins/itemku-tag';
import FetchNeoConfigMixin from '~pdp-shared-js/mixins/fetch-neo-config';

import FreeShippingSvg from './FreeShippingSvg';

const BlMainVariantMv = () =>
  import(/* webpackChunkName: "bl-main-variant-mv" */ '~pdp-mv/BlMainVariantMv');

const BlMainSellerActionMv = () =>
  import(/* webpackChunkName: "bl-main-seller-action-mv" */ '~pdp-mv/BlMainSellerActionMv');
const BlSellerSecondaryActionMv = () =>
  import(
    /* webpackChunkName: "bl-seller-secondary-action-mv" */ '~pdp-mv/BlSellerSecondaryActionMv'
  );
const BlAdsMv = () => import(/* webpackChunkName: "bl-ads-mv" */ '~pdp-mv/BlAdsMv');

export default {
  name: 'BlMainProductOv',

  components: {
    BlButtonAv, 
    BlPopoverAv,
    BlFlexItemAv,
    BlPreloaderAv,
    BlFlexContainerMv,
    BlStockAv,
    BlMainActionMv,
    BlMainActionInformationMv,
    BlSectionMv,
    BlMainHeadMv,
    BlMainPreviewMv,
    BlMainPriceMv,
    BlMainWholesaleMv,
    BlMainInstallmentMv,
    BlMainScanMv,
    BlMainVariantMv,
    BlMainSellerActionMv,
    BlSellerSecondaryActionMv,
    BlCellInfoMv,
    BlAdsMv,
    DeliveryInfoOv,
    PhoneIphoneSvg,
    FreeShippingSvg,
  },

  inject: ['productDetailTvInjection'],

  mixins: [ItemkuTagMixin, FetchNeoConfigMixin],

  data() {
    return {
      labelExtraDiscount: {},
      tncConfig: {},
      withoutDelivery: {
        label: 'Pengiriman',
        title: 'Tanpa Ongkir',
        description:
          'Pelapak akan kirim pesanan kamu lewat Chat Bukalapak atau sesuai kesepakatan saat transaksi.',
      },
      productType: {
        label: 'Tipe Barang',
        title: 'Produk Digital',
        description:
          'Produk akan kamu terima dalam bentuk digital seperti dokumen digital, kode voucher, dan lain-lain.',
      },
    };
  },

  computed: {
    ...mapState(['me']),
    ...mapState('productStore', ['base36Id', 'product', 'rawProduct', 'selectedVariant']),

    isDigitalGoods() {
      return this.product.digitalProduct;
    },

    hasInstallment() {
      return this.product.installments && this.product.installments.length;
    },

    hasWholesale() {
      return this.productDetailTvInjection.hasWholesale;
    },

    hasVariant() {
      // Make sure both of these exists
      return (
        this.product.options &&
        this.product.options.length > 0 &&
        this.product.variants &&
        this.product.variants.length > 0
      );
    },
    notSaleState() {
      return this.productDetailTvInjection.notSaleState;
    },
    informationProps() {
      return {
        minimumQuantity: this.productDetailTvInjection.minimumQuantity,
        product: this.product,
        sla: this.sla,
      };
    },
    isForSale() {
      return !this.notSaleState || !this.notSaleState.reason;
    },
    isSeller() {
      return this.productDetailTvInjection.isSeller;
    },
    selectedProduct() {
      return this.productDetailTvInjection.selectedProduct;
    },
    sla() {
      const { sla, store } = this.product;
      if (sla || store) {
        return (sla.type && sla) || (store && store.sla);
      }
      return null;
    },
  },

  methods: {
    isEmpty,
    async isFullfillExtraDiscountCriteria(criterias) {
      let result = false
      criterias.some(({ type, values }) => {
        if (type === 'platforms') {
          result = values.includes('web')
        }
      });

      if (!result) {
        return false
      }

      const res =  await shippingFees.retrieveCurrentShippingFeeDiscount();
      if (res.data.length === 0) {
        return false;
      }

      for (let i = 0; i < res.data.length; i++){
        // check seller_criteria
        const sellerCriteria = res.data[i].seller_criteria
        sellerCriteria.every(element => {
          const premiumTopSeller = element.premium_top_seller === undefined ? false : element.premium_top_seller
          const brandSeller = element.brand_seller === undefined ? false : element.brand_seller
          const sellerCities = element.seller_cities === undefined ? [] : element.seller_cities

          result = premiumTopSeller === this.product.store.premium_top_seller
          result = result && brandSeller === this.product.store.brand_seller
          if (sellerCities.length > 0) {
            result = result && sellerCities.includes(this.product.store.address.city)
          }
          
          if (result) {
            return false
          }
          return true;
        });
        
        if (!result) {
          continue
        }

        //check couriers
        const courierConfig = res.data[i].discount_shipping_fees.courier_services
        let sameCourier = []
        if (this.product.couriers.length > 0) {
          sameCourier = courierConfig.filter(value => this.product.couriers.includes(value));
        } else {
          sameCourier = courierConfig.filter(value => this.product.store.carriers.includes(value));
        }
        result = result && sameCourier.length > 0

        if (!result) {
          continue
        }

        //check categories
        result = false
        res.data[i].product_categories.every(element => {
          result = element.category_name === this.product.category.structure[0]
          if (result) {
            return false
          }
          return true;
        });

        if (result) {
          break
        }
      }
      return result;
    },
    async setExtraDiscountLabel() {
      const isToggleActive = await this.isToggleExtraDiscountActive()
      if (!isToggleActive) {
        return
      }
      
      const labelConfig = await this.getExtraDiscountLabelConfig()
      if (labelConfig === undefined) {
        return
      }
      this.labelExtraDiscount = labelConfig
    },
    async isToggleExtraDiscountActive() {
      let isActive = false
      const [err, res] = await to(retrieveTogglesStatus(['search-product-label-config-enabled']));
      if (!err && res.data && res.data.length) {
        isActive = res.data[0].active;
      }
      return isActive
    },
    async getExtraDiscountLabelConfig() {
      const key = 'search-product-label-config'
      const configs = await neo.$configs.postConfigs({
          data: { id: [key] },
        });
      const labelConfig = configs.data.find((c) => c.id === key)?.data.labels;

      for (let i = 0; i < labelConfig.length; i++) {
        let cfg = labelConfig[i]
        if (cfg.id === 'potongan_ekstra' && cfg.enabled && await this.isFullfillExtraDiscountCriteria(cfg.criterias)) {
          let discountLabel = 
            {
              id: cfg.id,
              icon_url: `https://s2.bukalapak.com/m-ast/web-drawable/drawable-mdpi/${cfg.icon}`,
              title: cfg.title,
              alt: `${cfg.title} Logo`,
              data_testid: `${cfg.id}-logo`,
            };
          return discountLabel
        }
      }
      return []
    },
    async getTnc(){
      const key = 'rage-no-ongkir-sheet-config'
      const configs = await neo.$configs.postConfigs({
          data: { id: [key] },
        });
      const tnc = configs.data.find((c) => c.id === key)?.data;
      this.tncConfig = 
        {
          title: tnc['sheet-title'],
          content: tnc['sheet-content'],
        }
    },
  },
  mounted() {
    this.setExtraDiscountLabel();
    this.getTnc()
  },
};
</script>

<style lang="scss" src="./BlMainProductOv.scss"></style>
