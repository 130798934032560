/* eslint-disable no-underscore-dangle */
export function unbind(el) {
  /* istanbul ignore if */
  if (!el._observe) return;

  el._observe.observer.unobserve(el);
  delete el._observe;
}

export function inserted(el, binding) {
  const modifiers = binding.modifiers || /* istanbul ignore next */ {};
  const { value } = binding;
  const isObject = typeof value === 'object';
  const callback = isObject ? value.handler : value;

  const observer = new IntersectionObserver((entries = [], obs) => {
    /* istanbul ignore if */
    if (!el._observe) return; // Just in case, should never fire

    // If is not quiet or has already been
    // initted, invoke the user callback
    if (callback && (!modifiers.quiet || el._observe.init)) {
      const isIntersecting = Boolean(entries.find(entry => entry.isIntersecting));

      callback(entries, obs, isIntersecting);
    }

    // If has already been initted and
    // has the once modifier, unbind
    if (el._observe.init && modifiers.once) unbind(el);
    // Otherwise, mark the observer as initted
    else el._observe.init = true;
  }, value.options || {});

  el._observe = { init: false, observer };

  observer.observe(el);
}

export default {
  inserted,
  unbind,
};
