<script>
import intersect from '~shared-js/directives/intersect';
import { isSupportWebp } from '~shared-js/helpers/image-helper';

const { document } = global;
export default {
  name: 'BlMediaMv',

  directives: { intersect },

  props: {
    alt: {
      type: String,
      default: '',
    },
    contain: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: false,
    },
    lazySrc: {
      type: String,
      default: global.assetUrl(`images/image-placeholder--medium.png`),
    },
    options: {
      type: Object,
      // For more information on types, navigate to:
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      default: () => ({
        root: undefined,
        rootMargin: undefined,
        threshold: undefined,
      }),
    },
    position: {
      type: String,
      default: 'center center',
    },
    aspectRatio: {
      type: [String, Number],
      default: null,
    },
    sizes: {
      type: String,
      default: '',
    },
    src: {
      type: [String, Object],
      default: '',
    },
    srcset: {
      type: String,
      default: '',
    },
    ssr: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: [Boolean, String],
      default: 'fade-transition',
    },
    webp: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSrc: '', // Set from srcset
      image: null,
      imageComponent: null,
      isImageLoaded: false,
      isLoading: true,
      isError: false,
      calculatedAspectRatio: undefined,
      naturalWidth: undefined,
    };
  },
  computed: {
    normalisedSrc() {
      return typeof this.src === 'string'
        ? {
            src: this.src,
            srcset: this.srcset,
            lazySrc: this.lazySrc,
            aspect: Number(this.aspectRatio),
          }
        : {
            src: this.src.src,
            srcset: this.srcset || this.src.srcset,
            lazySrc: this.lazySrc || this.src.lazySrc,
            aspect: Number(this.aspectRatio || this.src.aspect),
          };
    },
    computedAspectRatio() {
      return Number(this.normalisedSrc.aspect || this.calculatedAspectRatio);
    },
    aspectStyle() {
      return this.computedAspectRatio
        ? { paddingBottom: `${(1 / this.computedAspectRatio) * 100}%` }
        : undefined;
    },
    cachedSizer() {
      if (!this.aspectStyle) return [];

      return this.$createElement('div', {
        style: this.aspectStyle,
        staticClass: 'c-bl-media-responsive__sizer',
      });
    },
    objectFit() {
      return document && 'objectFit' in document.documentElement.style;
    },
  },

  watch: {
    src() {
      // Force re-init when src changes
      if (!this.isLoading) this.init(undefined, undefined, true);
      else this.loadImage();
    },
  },

  mounted() {
    if (!this.src) {
      this.isError = true;
      this.isLoading = false;
      this.onError();
    }
  },

  methods: {
    init(entries, observer, isIntersecting) {
      if (!isIntersecting && !this.eager) return;
      // Load placeholder only if intersect to reduce CPU usage incase placeholder content animation
      if (isIntersecting && !this.isImageLoaded) {
        this.imageComponent = this.genPlaceholder();
      }

      if (this.normalisedSrc.lazySrc) {
        const lazyImg = new Image();
        lazyImg.src = this.normalisedSrc.lazySrc;
        this.pollForSize(lazyImg, null);
      }
      /* istanbul ignore else */
      if (this.normalisedSrc.src) this.loadImage();
    },
    onLoad() {
      this.setCurrentSrc();
      this.isLoading = false;
      this.imageComponent = this.genPicture();
      this.isImageLoaded = true;
      this.$emit('load', this.src);
    },
    onError() {
      this.isLoading = false;
      this.isError = true;
      this.imageComponent = this.genPicture();
      this.$emit('error', this.src);
    },
    setCurrentSrc() {
      /* istanbul ignore else */
      if (this.image) this.currentSrc = this.image.currentSrc || this.image.src;
    },
    getSrc(src) {
      if (this.webp && isSupportWebp()) {
        return `${src}.webp`;
      }
      return src;
    },
    loadImage() {
      const image = new Image();
      image.onload = () => {
        /* istanbul ignore if */
        if (image.decode) {
          image
            .decode()
            .catch((err) => {
              console.log(
                `Failed to decode image, trying to render anyway\n\nsrc: ${this.normalisedSrc.src}${
                  err.message ? `\nOriginal error: ${err.message}` : ''
                }`,
                this,
              );
            })
            .then(this.onLoad);
        } else {
          this.onLoad();
        }
      };
      image.onerror = this.onError;
      image.src = this.getSrc(this.normalisedSrc.src);
      image.sizes = this.sizes;
      this.image = image;

      if (this.normalisedSrc.srcset) image.srcset = this.normalisedSrc.srcset;
      this.pollForSize(image);
    },
    pollForSize(img, timeout = 100) {
      const poll = () => {
        const { naturalHeight, naturalWidth } = img;

        if (naturalHeight || naturalWidth) {
          this.naturalWidth = naturalWidth;
          this.calculatedAspectRatio = naturalWidth / naturalHeight;
        } else if (timeout !== null) {
          setTimeout(poll, timeout);
        }
      };

      poll();
    },
    handleClick() {
      this.$emit('click');
    },
    cachedImage() {
      if (!(this.normalisedSrc.src || this.normalisedSrc.lazySrc)) return [];
      const backgroundImage = [];

      const src = this.isError ? this.normalisedSrc.lazySrc : this.currentSrc;

      if (src) backgroundImage.push(`url("${src}")`);

      const imgTag = this.$createElement('img', {
        staticClass: 'c-bl-media__image',
        class: {
          'c-bl-media__image--object-fit-cover': this.objectFit,
          'c-bl-media__image--object-fit-false': !this.objectFit,
        },
        attrs: {
          src,
          alt: this.alt,
        },
      });

      const imageBg = this.$createElement('div', {
        staticClass: 'c-bl-media__image',
        class: {
          'c-bl-media__image--preload': this.isLoading,
          'c-bl-media__image--contain': this.contain,
          'c-bl-media__image--cover': !this.contain,
        },
        style: {
          backgroundImage: backgroundImage.join(', '),
          backgroundPosition: this.position,
        },
        key: +this.isLoading,
      });

      const image = this.objectFit ? [imgTag] : [imgTag, imageBg];

      /* istanbul ignore if */
      if (!this.transition) return image;

      return this.$createElement(
        'transition',
        {
          attrs: {
            name: this.transition,
            mode: 'in-out',
          },
        },
        [image],
      );
    },
    genPicture() {
      const src = this.isError ? this.lazySrc : this.src;
      const ext = src.split('.').pop();

      const webpSource =
        src && this.webp
          ? [
              this.$createElement('source', {
                attrs: {
                  srcset: `${src}.webp`,
                  type: 'image/webp',
                },
              }),
            ]
          : [];

      const imgSource = src
        ? this.$createElement('source', {
            attrs: {
              srcset: src,
              type: `image/${ext}`,
            },
          })
        : null;

      return this.$createElement('picture', {}, [webpSource, imgSource, this.cachedImage()]);
    },
    genContent() {
      if (this.naturalWidth) {
        return this.$createElement('div', {
          staticClass: 'c-bl-media-responsive__content',
          style: { width: `${this.naturalWidth}px` },
        });
      }
      return null;
    },
    genPlaceholder() {
      if (!this.ssr && this.$slots.placeholder) {
        const placeholder = this.isLoading
          ? [
              this.$createElement(
                'div',
                {
                  staticClass: 'c-bl-media__placeholder',
                },
                this.$slots.placeholder,
              ),
            ]
          : [];

        if (!this.transition) return placeholder[0];

        return this.$createElement(
          'transition',
          {
            props: {
              appear: true,
              name: this.transition,
            },
          },
          placeholder,
        );
      }
      return null;
    },
  },

  render(h) {
    return h(
      'div',
      {
        directives: [
          {
            name: 'intersect',
            value: {
              handler: this.init,
              options: this.options,
            },
          },
        ],
        staticClass: 'c-bl-media',
        on: {
          click: this.handleClick,
        },
      },
      [this.cachedSizer, this.imageComponent, this.genContent()],
    );
  },
};
</script>

<style src="./BlMediaMv.style.scss" lang="scss"></style>
