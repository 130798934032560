<template lang="pug">
  .bl-shimmer-line-mv(:style="[ widthStyle, borderStyles ]")
    svg.bl-shimmer-line-topLeftCorner(width="2" height="2")
      path(d="M0 2 A 2 2, 0, 0, 1, 2 0 L 0 0 Z")
    svg.bl-shimmer-line-topRightCorner(width="2" height="2")
      path(d="M0 0 A 2 2, 0, 0, 1, 2 2 L 2 0 Z")
    svg.bl-shimmer-line-bottomRightCorner(width="2" height="2")
      path(d="M2 0 A 2 2, 0, 0, 1, 0 2 L 2 2 Z")
    svg.bl-shimmer-line-bottomLeftCorner(width="2" height="2")
      path(d="M2 2 A 2 2, 0, 0, 1, 0 0 L 0 2 Z")
</template>

<script>
import mixin from '../mixins'

export default {
  name: 'BlShimmerLineMv',
  status: 'prototype',
  release: '0.1.0',

  mixins: [ mixin ],
  props: {
    height: {
      type: Number,
      default: 16,
    },
    width: {
      type: Number | String,
      default: '100%',
    },
  },
}
</script>
