<template lang="pug">
  .c-video
    .c-video__thumbnail(@click="showAndPlayVideo")
      .c-video__thumbnail__overlay
        ico-play-circle
      img.u-border-radius--2(:src="imgLink" :alt="productName")
    bl-dialog-mv.c-video__dialog(:active.sync="dialogVideo")
      .c-video__dialog__box
        .c-video__dialog__box__inner
          iframe(
            :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          )
</template>

<script>
import { IcoPlayCircle } from '@bukalapak/bazaar-visual-v1';
import { BlDialogMv } from '@bukalapak/bazaar-dweb';

import BlMediaMv from '~shared-js/components/BlMediaMv';

export default {
  name: 'BlVideoMv',
  status: 'prototype',
  release: '0.1.0',

  components: {
    BlDialogMv,
    BlMediaMv,

    IcoPlayCircle,
  },

  props: {
    productName: {
      type: String,
      required: true,
    },

    videoId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dialogVideo: false,
    };
  },

  computed: {
    imgLink() {
      return `https://i.ytimg.com/vi/${this.videoId}/mqdefault.jpg`;
    },
  },

  methods: {
    showAndPlayVideo() {
      this.dialogVideo = true;
    },
  },
};
</script>

<style lang="scss" src="./BlVideoMv.style.scss"></style>
