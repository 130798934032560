import to from '~lib/to';
import { favorites, productService, storeVoucher } from '~pdp/js/modules/api-modules';
import { SET_CATEGORY } from '~pdp/store/mutations';
import { getProduct as storeGetProduct } from '~pdp/store/global';
import Product from '~shared-js/models/product';

import { SET_BASE_PRODUCT_STATE } from './mutations';

export default (() => {
  async function getProduct({ dispatch, state }, force = false) {
    const [err, res] = await to(storeGetProduct(state.base36Id, force));

    if (res) {
      const { data } = res;
      dispatch('commitProduct', data);

      return res;
    }

    if (err.response && err.response.status === 404) {
      global.location.replace('https://www.bukalapak.com/u/product-not-found');
    }

    return Promise.reject(err);
  }

  function commitProduct({ dispatch, commit }, data) {
    const product = new Product(data);

    commit(SET_BASE_PRODUCT_STATE, {
      name: 'product',
      payload: product,
    });

    commit(SET_BASE_PRODUCT_STATE, {
      name: 'rawProduct',
      payload: data,
    });

    dispatch('afterGetProduct', product);
  }

  function afterGetProduct({ commit, dispatch }, product) {
    commit(SET_CATEGORY, product.category, { root: true });

    const { history, location } = global;
    const promises = [];

    promises.push(dispatch('getProductHasVariant'));
    if (global.USER && global.USER.id) {
      promises.push(dispatch('getFavoriteState'));
    }
    Promise.all(promises).catch((err) => {
      /* eslint-disable */
      console.error('Something went wrong after fetching product');
      console.error(err);
      /* eslint-enable */
    });

    // Replace url with adjusted category path
    // Shouldn't break if replacement gone wrong.
    try {
      let url = product.url.slice(product.url.indexOf('/p/'));
      url = `${url}${location.search}${location.hash}`;
      let state = {};
      if (!history.state || (history.state && !Object.keys(history.state).length)) {
        state = {
          product: {
            id: product.id,
            name: product.name,
            url: product.url,
            store: {
              id: product.store.id,
              name: product.store.name,
            },
          },
          title: `Jual ${product.name} di Lapak ${product.store.name} | Bukalapak`,
        };
      } else {
        ({ state } = history);
      }
      history.replaceState(state, '', url);
    } catch (err) {
      /** * */
    }
  }

  function updateProductState({ commit, state }, newState) {
    const product = new Product(state.rawProduct);
    commit(SET_BASE_PRODUCT_STATE, {
      name: 'product',
      payload: Object.assign(product, {
        ...state.product,
        ...newState,
      }),
    });
  }

  async function getProductHasVariant({ dispatch, state }) {
    const [, res] = await to(
      productService.$variantInfo.retrieveProductVariantInfo(state.base36Id),
    );
    dispatch('setLatencyRequest', { variantInfo: false }, { root: true });

    // eslint-disable-next-line camelcase
    if (res?.data?.has_variant) {
      dispatch('updateProductState', {
        hasVariant: res.data.has_variant,
      });
      const promises = [dispatch('getProductVariantOptions'), dispatch('getProductVariant')];

      Promise.all(promises).catch((afterErr) => {
        /* eslint-disable */
        console.error('Something went wrong after fetching variants');
        console.error(afterErr);
        /* eslint-enable */
      });
    } else {
      dispatch('setLatencyRequest', { skus: false }, { root: true });
      dispatch('setLatencyRequest', { variantOptions: false }, { root: true });
      dispatch('updateProductState', {
        hasVariant: false,
      });
    }
  }

  async function getProductVariant({ dispatch, state }) {
    const [err, res] = await to(productService.$skus.retrieveProductSkus(state.base36Id));
    dispatch('setLatencyRequest', { skus: false }, { root: true });

    if (!err) {
      dispatch('updateProductState', {
        variants: res.data,
      });

      dispatch(
        'setDefaultVariant',
        res.data.find((variant) => variant.default_sku),
      );
      return res;
    }

    return Promise.reject(err);
  }

  async function getProductVariantOptions({ dispatch, state }) {
    const [err, res] = await to(
      productService.$variantOptions.retrieveProductVariantOptions(state.base36Id),
    );
    dispatch('setLatencyRequest', { variantOptions: false }, { root: true });

    if (!err) {
      dispatch('updateProductState', {
        options: res.data,
      });
      return res;
    }

    return Promise.reject(err);
  }

  function changeVariant({ commit }, selectedVariant) {
    const {
      id,
      wholesales,
      default_sku: defaultSku,
      discount,
      discount_percentage: discountPercentage,
      images = [],
      price,
      stock,
      deal,
      discount_subsidy: discountSubsidy,
      variant_image_ids: variantImageIds = [],
    } = selectedVariant.product;

    commit(SET_BASE_PRODUCT_STATE, {
      name: 'selectedVariant',
      payload: {
        ...selectedVariant,
        product: {
          skuId: id,
          price: price - discount,
          originalPrice: price,
          discountPercentage,
          defaultSku,
          deal,
          discountSubsidy,
          stock,
          images,
          wholesales,
          variantImageIds,
        },
      },
    });
  }

  function setDefaultVariant({ commit }, defaultVariant) {
    const {
      id,
      variant_name: name,
      wholesales,
      default_sku: defaultSku,
      discount,
      discount_percentage: discountPercentage,
      discount_subsidy: discountSubsidy,
      images = [],
      price,
      stock,
      deal,
    } = defaultVariant;

    commit(SET_BASE_PRODUCT_STATE, {
      name: 'defaultVariant',
      payload: {
        id,
        name,
        product: {
          skuId: id,
          price: price - discount,
          originalPrice: price,
          discountPercentage,
          defaultSku,
          deal,
          discountSubsidy,
          // deal: {
          //   discount_price: price - discount,
          //   percentage: discountPercentage,
          //   original_price: price,
          // },
          stock,
          images,
          wholesales,
        },
      },
    });
  }

  function commitProductFavorite({ dispatch }, favorited) {
    dispatch('updateProductState', {
      favorited,
    });
  }

  async function getProductCatalog({ dispatch, state }) {
    const [err, res] = await to(
      productService.$catalogs.retrieveCatalogListOfProducts(state.base36Id),
    );
    dispatch('setLatencyRequest', { catalogs: false }, { root: true });

    if (!err) {
      dispatch('updateProductState', {
        catalog: res.data[0],
      });
      return res;
    }

    return Promise.reject(err);
  }

  async function getFavoriteState({ dispatch, state }) {
    const favoriteState = await productService.$favoriteState.retrieveProductSFavoriteState(
      state.base36Id,
    );

    dispatch('setLatencyRequest', { favorite: false }, { root: true });
    dispatch('commitProductFavorite', favoriteState.data.favorited);

    return favoriteState;
  }

  async function toggleFavorite({ dispatch }, { id, isLike = false }) {
    const data = {
      product_ids: [id],
    };
    let err;
    let res;

    if (isLike) {
      [err, res] = await to(favorites.addProductsToFavorites({ data }));
    } else {
      [err, res] = await to(favorites.removeProductsFromFavorites({ queryParams: data }));
    }

    if (!err) {
      dispatch('commitProductFavorite', isLike);
      return res;
    }

    throw err;
  }

  async function fetchStoreVouchers(context, id) {
    const [err, res] = await to(storeVoucher.retrieveStorePremiumVouchers(id));

    if (!err) {
      return res;
    }

    return Promise.reject(err);
  }

  return {
    afterGetProduct,
    changeVariant,
    commitProduct,
    commitProductFavorite,
    fetchStoreVouchers,
    getProduct,
    getProductCatalog,
    getProductHasVariant,
    getProductVariant,
    getProductVariantOptions,
    getFavoriteState,
    toggleFavorite,
    updateProductState,
    setDefaultVariant,
  };
})();
