export default class EventBus {
  constructor() {
    this.events = [];
  }

  // eslint-disable-next-line
  publish(eventName, data) {
    const ev = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(ev);
  }

  subscribe(eventName, callback) {
    const wrapper = e => callback(e);
    document.addEventListener(eventName, wrapper);

    this.events.push({
      callback,
      eventName,
      wrapper,
    });
  }

  unsubscribe(eventName, callback) {
    this.events.forEach(ev => {
      if (ev.eventName === eventName && ev.callback === callback) {
        document.removeEventListener(eventName, ev.wrapper);
      }
    });
  }
}
