<template lang="pug">
  .bl-shimmer-circle-mv(:style="[ borderStyles ]")
    svg.c-shimmer-circle__svg(
      :width="height"
      :height="height"
      viewBox="0 0 10 10"
    )
      path(d="M0,0 L10,0 L10,10 L0,10 L0,0 Z M0,5 C0,7.76142375 2.23857625,10 5,10 C7.76142375,10 10,7.76142375 10,5 C10,2.23857625 7.76142375,2.22044605e-16 5,0 C2.23857625,-2.22044605e-16 0,2.23857625 0,5 L0,5 Z")
</template>

<script>
import mixin from '../mixins'

export default {
  name: 'BlShimmerCircleMv',
  status: 'prototype',
  release: '0.1.0',

  mixins: [ mixin ],
  props: {
    height: {
      type: Number,
      default: 24,
    },
  },
}
</script>
