<template lang="pug">
  .bl-shimmer-gap-mv(:style="[ widthStyle, borderStyles ]")
</template>

<script>
import mixin from '../mixins'

export default {
  name: 'BlShimmerGapMv',
  status: 'prototype',
  release: '0.1.0',

  mixins: [ mixin ],
  props: {
    height: {
      type: Number,
      default: 16,
    },
    width: {
      type: Number | String,
      default: 10,
    },
  },
}
</script>
