import Tracker from '~lib/tracker';

import { getUserId, getDocumentReferrer } from '../common-data';

function isNode() {
  try {
    return this === global;
  } catch (e) {
    return false;
  }
}

/**
 * From toolbox helper/browser-helper
 * Rewriting this function as we don't use other helper to exclude the 'bowser' package
 */
function viewport() {
  if (!isNode()) {
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;

    return `${width}x${height}`;
  }

  return '';
}

export const pageviews = () => {
  if (process.env.NODE_ENV !== 'production') return;

  const data = {
    evn: 'pageviews',
    td_host: window.location.hostname,
    td_path: window.location.pathname,
    td_referrer: getDocumentReferrer(),
    td_title: document.title,
    td_url: window.location.href,
    td_viewport: viewport(),
    ui: getUserId(),
  };

  Tracker.event(data);
};
