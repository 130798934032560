<template>
  <div id="secondary-header" :class="{ 'show-secondary-header': showSecondaryHeader }">
    <div class="secondary-header">
      <section class="section">
        <BlFlexContainerMv align-items="center">
          <BlFlexItemAv :col="6">
            <div class="flag">
              <div class="flag-image">
                <BlMediaMv
                  class="flag-image__media"
                  :aspect-ratio="1"
                  :webp="true"
                  :src="imageUrl"
                  cover="cover"
                />
              </div>
              <div class="flag-content">
                <div
                  class="product-title truncate"
                  :aria-label="product.name"
                  :title="product.name"
                >
                  {{ product.name }}
                </div>
                <div>
                  <span
                    v-if="productDetailTvInjection.hasDeal"
                    class="product-original-price"
                  >{{ product.originalPrice | money }}</span>
                  <span class="product-price">{{ productPrice | money }}</span>
                  <span
                    v-if="productDetailTvInjection.hasDeal"
                    class="product-discount-badge"
                  >{{ Math.round(productDiscountPercentage) }}%</span>
                </div>
              </div>
            </div>
          </BlFlexItemAv>
          <BlFlexItemAv :col="6">
            <BlFlexContainerMv justify-content="flex-end">
              <BlButtonV2Av
                class="add-to-cart"
                variation="outline"
                data-pdp-add-to-cart-trigger="true"
                :is-disabled="isNoStock || isAddingCart"
                @click.prevent
              >
                <span class="add-to-cart__icon" title="Masukkan Keranjang">
                  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                      <path
                        d="M7 18.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M15 18.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0M16.21 10.193L15.744 13H4.561L3.524 6h6.001a5.004 5.004 0 0 1 0-1h-6.15l-.254-1.72A1.509 1.509 0 0 0 1.637 2H0v1h1.637c.246 0 .459.183.495.427L3.879 15.22c.108.73.746 1.28 1.484 1.28H15.5v-1H5.363a.504.504 0 0 1-.495-.427L4.708 14h11.035a1 1 0 0 0 .986-.836l.59-3.535c-.341.233-.714.42-1.108.564"
                        fill="#364058"
                      />
                      <path
                        d="M19.5 5.5a5 5 0 1 1-10 0 5 5 0 0 1 10 0zM14.5 3v5M17 5.5h-5"
                        stroke="#364058"
                      />
                    </g>
                  </svg>
                </span>
                <span v-if="!isAddingCart" class="add-to-cart__text">Masukkan Keranjang</span>
                <BlSpinnerAv v-else size="small" />
              </BlButtonV2Av>
              <BlButtonV2Av
                class="u-mrgn-left--4 buy-now"
                data-pdp-buy-now-trigger="true"
                :is-disabled="isNoStock || isOnCheckout"
                @click.prevent
              >
                <span>{{ productDetailTvInjection.isPreorder ? 'Pre-Order Sekarang' : 'Beli Sekarang' }}</span>
              </BlButtonV2Av>
            </BlFlexContainerMv>
          </BlFlexItemAv>
        </BlFlexContainerMv>
      </section>
    </div>
    <div class="secondary-header-curtain" />
  </div>
</template>

<script>
// import BlButtonV2Av from '@bukalapak/bazaar-dweb-v2/dist/components/BlButtonAv';
import { BlSpinnerAv } from '@bukalapak/bazaar-dweb';
import { money } from '@bukalapak/toolbox-helper/number-helper';
import { mapState } from 'vuex';

import BlMediaMv from '~shared-js/components/BlMediaMv';
import BlFlexItemAv from '~pdp-av/BlFlexItemAv';
import BlButtonV2Av from '~pdp-av/BlButtonV2Av';
import BlFlexContainerMv from '~pdp-mv/BlFlexContainerMv';
import { ScrollHandler } from '~shared-js/scroll-handler';

export default {
  name: 'BlBuyNowHeaderOv',

  components: {
    BlButtonV2Av,
    BlFlexItemAv,
    BlSpinnerAv,
    BlFlexContainerMv,
    BlMediaMv,
  },

  inject: ['productDetailTvInjection'],

  filters: {
    money,
  },

  data() {
    return {
      showSecondaryHeader: false,
      scrollHandler: null,
    };
  },

  computed: {
    ...mapState('cart', ['isAddingCart', 'isOnCheckout']),

    isNoStock() {
      return this.productDetailTvInjection.isNoStock;
    },
    imageUrl() {
      return this.product.images && this.product.images.small_urls[0];
    },
    product() {
      return this.productDetailTvInjection.selectedProduct;
    },
    productPrice() {
      return !this.productDetailTvInjection.hasSubsidy && this.productDetailTvInjection.hasDeal ?
        this.product.deal.discount_price :
        this.product.price;
    },

    productDiscountPercentage() {
      return !this.productDetailTvInjection.hasSubsidy && this.productDetailTvInjection.hasDeal ?
        this.product.deal.percentage :
        this.product.discountPercentage;
    },
  },

  mounted() {
    this.initOnScroll();
  },

  destroyed() {
    this.scrollHandler.disableTick();
  },

  methods: {
    initOnScroll() {
      this.scrollHandler = new ScrollHandler({ selector: '.c-main-product__action-buttons' });

      this.scrollHandler.registerScrollHandler('top', () => {
        this.showSecondaryHeader = false;
      });

      this.scrollHandler.registerScrollHandler('bottom', () => {
        this.showSecondaryHeader = true;
      });

      this.scrollHandler.enableTick();
    },
  },
};
</script>


<style lang="scss" src="./BlBuyNowHeaderOv.scss" scoped>
