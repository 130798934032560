import to from '~lib/to';
import { addresses, geocoderAddressGroups } from '~pdp/js/modules/api-modules';
import { extraApi } from '~shared-js/product-detail-page/api';
import { getCouriers } from '~shared-js/product-detail-page/helpers/product-helper';

import { SET_BASE_SHIPPING_STATE, SET_FEES, SET_GEOCODER_ADDRESSES } from './mutations';

const actions = {
  async getShippingPrice({ commit, dispatch, rootState }, { address, quantity }) {
    const { product } = rootState.productStore;
    const queryParams = {
      destination_country: address.country,
      destination_province: address.province,
      destination_city: address.city,
      destination_district: address.district,
      quantity,
    };

    const JANIO = 'Janio';
    const productCouriers = [...(getCouriers({ product }) || [])]; // deep clone
    const hasInternationalCouriers = product.internationalCouriers !== undefined;

    if (!hasInternationalCouriers && product.availableCountries.length) {
      productCouriers.push(JANIO);
    }
    const data = {
      ...queryParams,
      seller_id: product.store.id,
      product_id: product.id,
      price: product.price,
      weight: product.weight,
      category_id: product.category.id,
      product_couriers: productCouriers,
      free_shippings: product.shipping.free_shipping_coverage || [],
    };
    if (hasInternationalCouriers) {
      data.international_couriers = product.internationalCouriers;
    }
    const request = extraApi.retrieveProductShippings({ data });
    const [err, res] = await to(request);
    dispatch('setLatencyRequest', { shippingFees: false }, { root: true });

    if (!err) {
      commit(SET_FEES, res.data);
      return res.data;
    }

    return Promise.reject(err);
  },

  async getUserAddresses({ commit, dispatch }) {
    const [err, res] = await to(addresses.retrieveUserAddresses());
    dispatch('setLatencyRequest', { addresses: false }, { root: true });

    if (!err) {
      commit(SET_BASE_SHIPPING_STATE, {
        name: 'addresses',
        payload: res.data,
      });

      return res;
    }

    return Promise.reject(err);
  },

  async getGeocoderAddresses({ commit }, keywords) {
    const queryParams = { keywords, offset: 0, limit: 10 };
    const [err, res] = await to(geocoderAddressGroups.getAddressesGroups({ queryParams }));

    if (!err) {
      commit(SET_GEOCODER_ADDRESSES, res.data);

      return res;
    }

    return Promise.reject(err);
  },
};

export default actions;
