<template lang="pug">
  .c-seller__meta.u-mrgn-top--6.u-txt--base
    .c-seller__meta__feedback
      bl-progress-circular-av(
        :value="feedbackPercentage"
        :size="80"
        :strokeColor="feedbackColor"
      )
        span.feedback__score {{ feedbackPercentage }}%

      div(v-if="store.reviews")
        h3.u-mrgn-bottom--1.u-txt--bold Pelanggan Puas
        a.c-seller__meta__feedback-url(:href="feedbackUrl" rel="nofollow")
          | {{ store.reviews.positive | delimiter }} Feedback Positif
        p.c-seller__meta__feedback-total.u-mrgn-bottom--0(v-if="totalFeedback > 0")
          | dari {{ totalFeedback | delimiter }} feedback
    .c-seller__meta__pesanan(v-if="store.delivery_time")
      h3.u-mrgn-bottom--1.u-txt--bold {{ deliveryTime }}
      p.u-mrgn--0 Waktu Proses Pesanan
</template>

<script>
import BlProgressCircularAv from '~pdp-av/BlProgressCircularAv';

export default {
  name: 'BlSellerMetaMv',

  components: {
    BlProgressCircularAv,
  },

  props: {
    store: {
      type: Object,
      required: true,
    },
  },

  computed: {
    deliveryTime() {
      const deliveryTime = this.store.delivery_time;
      if (deliveryTime) {
        return deliveryTime.indexOf('<') > -1 ? deliveryTime : `± ${deliveryTime}`;
      }
      return '';
    },

    feedbackColor() {
      let color = '#ddd';
      if (this.feedbackPercentage > 49 && this.feedbackPercentage < 74) {
        color = '#ffc53e';
      } else if (this.feedbackPercentage > 75) {
        color = '#87cc62';
      }
      return color;
    },

    feedbackPercentage() {
      if (this.store.reviews) {
        const { positive } = this.store.reviews;
        if (positive > 0) {
          return Math.floor((positive / this.totalFeedback) * 100);
        }
      }

      return 0;
    },

    feedbackUrl() {
      return this.store.username
        ? `/u/${this.store.username}/feedback?feedback_as=as_seller&filter_by=all`
        : '';
    },

    totalFeedback() {
      if (this.store.reviews) {
        return this.store.reviews.positive + this.store.reviews.negative;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" src="./BlSellerMetaMv.scss"></style>
