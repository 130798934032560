<template lang="pug">
  .c-expansions
    .c-expansions__trigger(ref="expansionsTrigger")
      slot(name="trigger")

    transition(name="slide")
      .c-expansions__content(
        v-if="active"
        ref="expansionsContent"
        @click.stop="handleClickOnContent"
      )
        slot(name="content")

</template>

<script>
const { document } = global;

export default {
  name: 'BlExpansionsMv',

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    maxHeight: {
      type: Number,
      default: 200,
    },
  },

  mounted() {
    if (document) {
      document.addEventListener('click', this.handleOutsideClick);
      if ('ontouchstart' in global) {
        document.addEventListener('touchstart', this.handleOutsideClick);
      }
    }
  },

  beforeDestroy() {
    if (document) {
      document.removeEventListener('click', this.handleOutsideClick);
      if ('ontouchstart' in global) {
        document.removeEventListener('touchstart', this.handleOutsideClick);
      }
    }
  },

  methods: {
    handleOutsideClick(e) {
      /** Do not re-render list on input click  */
      const isChild = this.$el.contains(e.target);

      if (!isChild) {
        this.$emit('update:active', false);
      } else {
        this.$emit('update:active', !this.active);
      }
    },

    handleClickOnContent() {
      this.$emit('clickOnContent');
    },
  },
};
</script>

<style lang="scss" src="./BlExpansionsMv.style.scss"></style>
