<template lang="pug">
  bl-dialog-mv.c-lightbox(:active.sync="lightboxOn")
    .c-lightbox__main
      transition(
        mode="out-in"
        name="fade"
      )
        .c-lightbox__main-inner
          bl-media-mv(
            :src="images[select]"
            :webp="true"
          )
            template(slot="placeholder")
              div.c-media__placeholder__content
                bl-spinner-av(size="medium")
      button(
        type="button"
        v-if="images.length > 1"
        class="c-lightbox__nav c-lightbox__nav--prev"
        @click.stop="previousImage"
      )
        bl-icon-av(:icon="productDetailTvInjection.icon.icoChevronLeftMinor" color="#fff")
      button(
        type="button"
        class="c-lightbox__nav c-lightbox__nav--next"
        v-if="images.length > 1"
        @click.stop="nextImage"
      )
        bl-icon-av(:icon="productDetailTvInjection.icon.icoChevronRightMinor" color="#fff")
    .c-lightbox__thumbnails(v-if="showThumbs")
      .c-lightbox__thumbnail(
        v-for="(image, index) in imagesThumb"
        :class="(select === index ? '-active' : '')"
        v-show="index >= thumbIndex.begin && index <= thumbIndex.end"
        @click.stop="showImage(index)"
      )
        img(:src="image")


</template>
<script>
import { BlIconAv } from '@bukalapak/bazaar-visual-v1';
import BlButtonAv from '@bukalapak/bazaar-dweb/dist/components/BlButtonAv';
import BlDialogMv from '@bukalapak/bazaar-dweb/dist/components/BlDialogMv';
import BlSpinnerAv from '@bukalapak/bazaar-dweb/dist/components/BlSpinnerAv';

import imageHelper from '~pdp-mixins/image-helper';
import BlMediaMv from '~shared-js/components/BlMediaMv';

export default {
  name: 'BlLightboxMv',
  status: 'prototype',
  release: '0.1.0',
  components: {
    BlDialogMv,
    BlButtonAv,
    BlIconAv,
    BlMediaMv,
    BlSpinnerAv,
  },

  mixins: [imageHelper],

  inject: ['productDetailTvInjection'],

  props: {
    images: {
      type: Array,
      required: true,
    },

    startAt: {
      type: Number,
      default: 0,
    },

    showLightBox: {
      type: Boolean,
      default: false,
    },

    previousText: {
      type: String,
      default: 'Previous',
    },

    nextText: {
      type: String,
      default: 'Next',
    },

    showThumbs: {
      type: Boolean,
      default: true,
    },
    enableKeyboard: {
      type: Boolean,
      default: true,
    },
    nThumbs: {
      type: Number,
      default: 7,
    },
  },

  data() {
    return {
      select: this.startAt,
      lightboxOn: this.showLightBox,
      timer: null,
    };
  },
  computed: {
    thumbIndex() {
      const halfDown = Math.floor(this.nThumbs / 2);
      if (this.select >= halfDown && this.select < this.images.length - halfDown) {
        return {
          begin: this.select - halfDown + (1 - (this.nThumbs % 2)),
          end: this.select + halfDown,
        };
      }

      if (this.select < halfDown) {
        return {
          begin: 0,
          end: this.nThumbs - 1,
        };
      }

      return {
        begin: this.images.length - this.nThumbs,
        end: this.images.length - 1,
      };
    },

    imagesThumb() {
      const thumbs = this.images.map((image) => this.imageUrl(image, 'small'));
      return thumbs;
    },
  },
  watch: {
    lightboxOn(val) {
      if (!val) {
        this.$emit('update:startAt', null);
      }
    },
  },
  mounted() {
    this.lightboxOn = true;
    document.addEventListener('keydown', this.handleKeyStroke);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyStroke);
  },

  methods: {
    showImage(index) {
      this.$set(this, 'lightboxOn', true);
      this.$set(this, 'select', index);
    },

    nextImage() {
      const selectIndex = (this.select + 1) % this.images.length;
      this.$set(this, 'select', selectIndex);
    },

    previousImage() {
      const selectIndex = (this.select + this.images.length - 1) % this.images.length;
      this.$set(this, 'select', selectIndex);
    },

    handleKeyStroke(e) {
      const { keyCode } = e;
      switch (keyCode) {
        case 37: // Prev
          e.preventDefault();
          this.previousImage();
          break;
        case 39: // Next
          e.preventDefault();
          this.nextImage();
          break;
        case 27: // Esc
          e.preventDefault();
          this.lightboxOn = false;
          this.$emit('update:startAt', null);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style src="./BlLightboxMv.style.scss" lang="scss"></style>
