import sharedActions from '~shared-js/product-detail-page/store/modules/shipping/actions';

import { SET_BASE_SHIPPING_STATE } from './mutations';

export default {
  ...sharedActions,

  selectAddress({ commit }, address) {
    commit(SET_BASE_SHIPPING_STATE, {
      name: 'selectedAddress',
      payload: address,
    });
  },
};
