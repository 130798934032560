export default class Product {
  constructor(product = {}) {
    const { images: { large_urls: largeUrls = [], small_urls: smallUrls = [] } = {} } = product;
    this.active = product.active;
    this.assurance = product.assurance;
    this.availableCountries = product.available_countries;
    this.category = product.category;
    this.condition = product.condition;
    this.couriers = product.couriers;
    this.createdAt = product.created_at;
    this.deal = product.deal;
    this.defaultCatalog = product.default_catalog;
    this.description = product.description;
    this.discountPercentage = product.discount_percentage;
    this.discountSubsidy = product.discount_subsidy;
    this.forSale = product.for_sale;
    this.id = product.id;
    this.images = { large_urls: largeUrls, small_urls: smallUrls };
    this.imported = product.imported;
    this.installments = product.installments;
    this.maxQuantity = product.max_quantity;
    this.minQuantity = product.min_quantity;
    this.name = product.name;
    this.price = product.price;
    this.productSin = product.product_sin;
    this.originalPrice = product.original_price;
    this.rating = product.rating;
    this.relistedAt = product.relisted_at;
    this.rushDelivery = product.rush_delivery;
    this.shipping = product.shipping;
    this.skuId = product.sku_id;
    this.sla = product.sla;
    this.specs = product.specs;
    this.state = product.state;
    this.stateDescription = product.state_description;
    this.stats = product.stats;
    this.stock = product.stock;
    this.store = product.store;
    this.digitalProduct = product.digital_product;

    if (product.store && product.store.url) {
      this.store.username = Product.getStoreUsernameFromUrl(product.store.url);
    }

    this.tagPages = product.tag_pages;
    this.url = product.url;
    this.videoUrl = product.video_url;
    this.warranty = product.warranty;
    this.weight = product.weight;
    this.wholesales = product.wholesales;
    this.internationalCouriers = product.international_couriers;
    this.withoutShipping = product.without_shipping;
  }

  /**
   * Get this instance variable value
   *
   * @param {String} name - Path to the property object
   * @param {any} defaultValue - Return the default value, return undefined if not defined.
   */
  get(name = '', defaultValue = undefined) {
    if (name) {
      return name.split('.').reduce((acc, key) => {
        if (acc === undefined) return defaultValue;

        // Split by array call, e.g: 'installments[0]' -> ['installments', '0', '']
        const [k, i] = key.split(/\[(.+)]/);
        const val = i === undefined ? acc[k] : acc[k][i];
        return val === undefined ? defaultValue : val;
      }, this);
    }
    return defaultValue;
  }

  /**
   * Get product instance discount percentage value
   *
   * @param {Boolean} withSubsidy whether to consider subsidy or not
   * @returns {Number} discount percentage
   */
  getDiscountPercentage(withSubsidy = false) {
    if (withSubsidy) {
      return this.discountPercentage;
    }

    return (this.deal && this.deal.percentage) || 0;
  }

  /**
   * Get product instance price (after discount if any)
   *
   * @param {Boolean} withSubsidy whether to consider subsidy or not
   * @returns {Number} product final price
   */
  getPrice(withSubsidy = false) {
    if (withSubsidy) {
      return this.price;
    }

    return (this.deal && this.deal.discount_price) || this.originalPrice;
  }

  static getStoreUsernameFromUrl(storeUrl = '') {
    const sliceIndex = storeUrl.lastIndexOf('/') + 1;
    return storeUrl.slice(sliceIndex);
  }
}
