export default Vue => {
  /**
   * Define simple component for saving variable instead of create redundant variable
   *
   * @example
   * <temp-var :vars="{ foo: true, bar: false, tar: variableName, str: 'string' }">
   *   <div slot-scope="props">
   *     {{ props.tar }} - {{ props.str }}
   *   </div>
   *   <div slot-scope="{ foo, bar }">
   *     {{ foo }} vs {{ bar }}
   *   </div>
   * </temp-var>
   */
  Vue.component('TempVar', {
    name: 'TempVar',
    props: {
      vars: {
        type: Object,
        default: () => ({}),
      },
    },
    render() {
      return this.$scopedSlots.default(this.vars);
    },
  });
};
