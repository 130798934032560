<template lang="pug">
  .c-cart-dialog__item(:class="unavailableState.message ? 'c-cart-dialog__disabled' : ''")
    a(:href="`${product.url}?from=cart_index`").c-cart-dialog__item__thumbnail
      img(:src="item.stuff.images.small_urls[0]")
    .c-cart-dialog__item__info
      .u-txt--small.u-fg--ash-dark {{ product.store && product.store.name }}
      .c-cart-dialog__item__name
        a.c-link--primary--black(:href="product.url") {{ product.name }}
      template(v-if="isPreorder")
        .c-label Preorder {{ sla.value }} hari
        br
      .c-cart-dialog__item__variant-name(v-if="productVariant")
        | {{ productVariant }}

      template
        bl-notification-mv.u-txt--small.u-mrgn-top--1(
          v-if="unavailableState.message"
          with-icon
          size-icon="base"
          variant-icon="info"
          :variant="unavailableState.variant"
        )
          | {{ unavailableState.message }}

        .u-txt--base.u-txt--bold.u-mrgn-top--1(v-else-if="isFlashDeal") Barang Flash Deal
        .c-cart-dialog__item__quantity(v-else)
          bl-button-av(
            size="small"
            :disabled="isDisabledPrev"
            @click="quantityHandler(quantity - 1)"
          ) -
          .c-cart-dialog__item__field
            input.c-inp.c-inp--small(
              :value="quantity"
              @keypress="e => checkInput(e)"
              @input="e => quantityHandler(e.target.value)"
            )
          bl-button-av(
            size="small"
            :disabled="isDisabledNext"
            @click="quantityHandler(quantity + 1)"
          ) +

      bl-flex-container-mv.u-fg--red.u-mrgn-top--2(
        v-if="errorMessage"
        align-items="center"
      )
        bl-flex-item-av(basis="1em")
          ico-alert
        bl-flex-item-av.u-mrgn-left--1
          b {{ errorMessage }}

    .c-cart-dialog__item__price
      bl-spinner-av(v-if="isLoading" size="small")
      bl-price-av(v-else :number="item.total_price" :withSpace="false")

    .c-cart-dialog__item__remove(@click="!isLoading && removeItem(item.id)")
      ico-ui-cross.u-txt--medium

    bl-snackbar-mv(
      :active.sync="showSnackbar"
      :message="snackbarMessage")
</template>

<script>
import BlButtonAv from '@bukalapak/bazaar-dweb/dist/components/BlButtonAv';
import BlSpinnerAv from '@bukalapak/bazaar-dweb/dist/components/BlSpinnerAv';
import BlNotificationMv from '@bukalapak/bazaar-dweb/dist/components/BlNotificationMv';
import { BlSnackbarMv } from '@bukalapak/bazaar-dweb-v2';
import { IcoUiCross, IcoAlert } from '@bukalapak/bazaar-visual-v1';
import _ from 'lodash';
import { mapState } from 'vuex';

import { removeCartItem, updateCart, updateQuantity } from '~pdp/store/global';
import BlPriceAv from '~pdp-av/BlPriceAv';
import BlFlexItemAv from '~pdp-av/BlFlexItemAv';
import BlFlexContainerMv from '~pdp-mv/BlFlexContainerMv';
import { productVariantDetails } from '~pdp/js/modules/api-modules';
import { getCookie } from '~shared-js/utils/cookie';

export default {
  name: 'BlCartDialogItemMv',

  components: {
    BlButtonAv,
    BlFlexItemAv,
    BlPriceAv,
    BlSpinnerAv,
    BlFlexContainerMv,
    BlNotificationMv,
    BlSnackbarMv,

    IcoUiCross,
    IcoAlert
  },

  props: {
    item: {
      type: Object,
      required: true,
    },

    product: {
      type: Object,
      required: true,
    },

    refreshCartProduct: {
      type: Function,
      default: null,
    },

    unavailableState: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      errorMessage: '',
      isLoading: false,
      productVariant: '',
      showSnackbar: false,
      snackbarMessage: '',
    };
  },

  computed: {
    ...mapState(['category']),

    isDisabledNext() {
      return this.quantity >= this.stock || this.quantity === this.product.max_quantity;
    },

    isDisabledPrev() {
      return this.quantity === 1 || this.quantity === this.product.min_quantity;
    },

    isFlashDeal() {
      return this.item.flash_deal_discount > 0;
    },

    isPreorder() {
      return this.sla && this.sla.type === 'preorder';
    },

    quantity() {
      return this.item.quantity;
    },

    sla() {
      const { sla = {}, store = {} } = this.product;
      if (sla || store) {
        return (sla.type && sla) || store.sla;
      }
      /* istanbul ignore next */
      return null;
    },

    stock() {
      return this.item.stuff.stock;
    },

    isSubsidyApplied() {
      if (!this.item.stuff.discount_subsidy) return false;
      const { max_purchase: maxPurchase, stock } = this.item.stuff.discount_subsidy;
      const isSubsidyApplied = this.quantity <= maxPurchase && this.quantity <= stock;
      return isSubsidyApplied;
    },
  },

  watch: {
    isSubsidyApplied() {
      this.notifySubsidy();
    },
  },

  mounted() {
    if (this.item.stuff && this.item.stuff.variant_name) {
      productVariantDetails.retrieveVariantDetail(this.item.stuff.id).then(res => {
        this.productVariant = res.data.reduce((variantStr, val) => {
          const variant = `${val.label}: ${val.value}`;
          return `${variantStr ? `${variantStr}, ${variant}` : variant}`;
        }, '');
      });
    }
  },

  methods: {
    checkInput(e) {
      const { key } = e;
      if (!(key >= 0 && key <= 9) || key === ' ') {
        e.preventDefault();
      }
    },

    debounceUpdateQuantity: _.debounce(function debounceUpdateQuantity(itemId, quantity = 1) {
      this.isLoading = true;
      updateCart({
        itemId,
        quantity,
        query: {
          identity: getCookie('identity') || '',
        },
      })
        .then(() => {
          if (this.errorMessage) {
            this.errorMessage = '';
          }
          this.refreshCartProduct();
        })
        .catch(err => {
          if (err.response) {
            const { data } = err.response;
            this.errorMessage = data.errors.reduce((msg, error) => `${msg ? `\n${error.message}` : error.message}`, '');
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),

    quantityHandler(input) {
      const quantity = Number(input);
      let validQuantity = quantity <= 0 || quantity === '' ? 1 : quantity;
      // If value is lesser than minimum quantity, use minimum quantity
      validQuantity = Math.max(validQuantity, this.product.min_quantity);
      // If value is greater than the result of minimum(stock or maximum), use the result value;
      validQuantity = Math.min(validQuantity, this.item.stuff.stock, this.product.max_quantity);
      updateQuantity({ id: this.item.id, quantity: validQuantity });
      this.debounceUpdateQuantity(this.item.id, validQuantity);
    },

    removeItem(itemId) {
      this.isLoading = true;
      removeCartItem({ itemId })
        .then(() => {
          this.refreshCartProduct();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    notifySubsidy() {
      let message;
      if (this.isSubsidyApplied) {
        message = 'Hore, kamu dapat harga promo!';
      } else {
        message = 'Karena penambahan jumlah barang, harga promo jadi gak berlaku';
      }
      this.setSnackbar({ message });
    },

    setSnackbar({ message }) {
      this.snackbarMessage = message;
      this.showSnackbar = true;
    },
  },
};
</script>
