<template>
  <div class="c-product-gallery__footer">
    <BlButtonAv
      class="c-product-gallery__footer__action -separator"
      :disabled="isFavoriting"
      @click="handleFavorite"
    >
      <BlIconAv :icon="favoritedState.icon" :color="favoritedState.color" />
      <span class="c-btn__text">{{ favoritedState.text }}</span>
    </BlButtonAv>

    <BlPopoverAv keep-popover>
      <BlButtonAv class="c-product-gallery__footer__action">
        <span class="c-icon">
          <IcoShareAndroid />
        </span>
        <span class="c-btn__text">Bagikan</span>
      </BlButtonAv>
      <template slot="content">
        <BlSocialShareMv v-if="product.id" :product-id="product.id" />
      </template>
    </BlPopoverAv>
  </div>
</template>

<script>
import { BlIconAv, IcoShareAndroid } from '@bukalapak/bazaar-visual-v1';
import { BlButtonAv, BlPopoverAv } from '@bukalapak/bazaar-dweb';
import { mapActions } from 'vuex';
import qs from 'qs';

import to from '~lib/to';
import loginAction from '~pdp/js/constants/login-action';
import { pickLoginParams, setLoginComebackUrl } from '~pdp-utils/helpers';

import BlSocialShareMv from '../BlSocialShareMv';

const { history, location } = global;

export default {
  name: 'BlPreviewFooterMv',

  components: {
    BlButtonAv,
    BlIconAv,
    BlPopoverAv,
    IcoShareAndroid,

    BlSocialShareMv,
  },

  inject: ['productDetailTvInjection'],

  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isFavoriting: false,
    };
  },

  computed: {
    favoritedState() {
      const isFavorited = this.product.favorited;
      return {
        icon: isFavorited
          ? this.productDetailTvInjection.icon.icoHeart
          : this.productDetailTvInjection.icon.icoHeartOutline,
        color: isFavorited ? '#e31e52' : '',
        text: isFavorited ? 'Favoritmu' : 'Favoritkan',
      };
    },
  },

  mounted() {
    let params = qs.parse(location.search.slice(1)) || {};
    const { force_login: forceLogin } = params;
    if (forceLogin && forceLogin === loginAction.favorite) {
      this.handleFavorite(true).then(() => {
        params = pickLoginParams(params, ['force_login']);
        const url = `${location.pathname}?${qs.stringify(params)}${location.hash}`;
        history.replaceState({}, '', url);
      });
    }
  },

  methods: {
    ...mapActions('productStore', ['toggleFavorite']),

    async handleFavorite(value = null) {
      if (this.productDetailTvInjection.isLogin) {
        this.isFavoriting = true;
        let isLike = !this.product.favorited;
        if (value !== null) {
          isLike = value;
        }
        const [err, res] = await to(
          this.toggleFavorite({
            id: this.product.id,
            isLike,
          }).finally(() => {
            this.isFavoriting = false;
          }),
        );
        if (err) {
          Promise.reject(err);
        } else {
          Promise.resolve(res);
        }
      } else {
        global.location = setLoginComebackUrl(loginAction.favorite, {
          queryParams: { force_login: loginAction.favorite },
        });
      }
    },
  },
};
</script>
