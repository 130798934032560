<template lang="pug">
  .c-seller
    bl-seller-header-mv(:store="store")

    bl-seller-meta-mv(v-if="hasFeedback" :store="store")

    bl-seller-voucher-mv(:store="store")

    bl-seller-term-mv(:store="store")
</template>

<script>
import {
  BlSellerHeaderMv,
  BlSellerMetaMv,
  BlSellerTermMv,
  BlSellerVoucherMv,
} from '~pdp-mv/BlSellerMv';

export default {
  name: 'BlSellerOv',

  components: {
    BlSellerHeaderMv,
    BlSellerMetaMv,
    BlSellerTermMv,
    BlSellerVoucherMv,
  },

  props: {
    store: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasFeedback() {
      if (this.store && this.store.reviews) {
        return this.store.reviews.negative > 0 || this.store.reviews.positive > 0;
      }
      return false;
    },
  },
};
</script>
