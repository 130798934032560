<template lang="pug">
  .c-cart-dialog__footer(slot="footer")
    .c-cart-dialog__summary
      span Total Harga Barang
      bl-price-av(:number="totalProductPrice")

    .c-cart-dialog__action
      bl-button-av.c-cart-dialog__cart-button(@click.native="goToCart") Lihat Keranjang
      bl-button-av.c-cart-dialog__pay-button(
        color="red"
        :href="hasCheckout ? '/payment/purchases/new' : ''"
        :disabled="!hasCheckout"
        :title="!hasCheckout ? 'Hapus barang yang tidak tersedia terlebih dahulu!' : ''"
        @click.native="checkout"
      ) Lanjut ke Pembayaran
</template>

<script>
import BlButtonAv from '@bukalapak/bazaar-dweb/dist/components/BlButtonAv';
import qs from 'qs';

import BlPriceAv from '~pdp-av/BlPriceAv';

export default {
  name: 'BlCartDialogFooterMv',

  components: {
    BlButtonAv,
    BlPriceAv,
  },

  props: {
    cart: {
      type: Object,
      required: true,
    },
    hasCheckout: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    cartUrl() {
      const queryParams = {
        from: 'dweb_cart_popup',
      };

      return `/cart/carts?${qs.stringify(queryParams)}`;
    },

    totalProductPrice() {
      return this.cart.items.reduce((res, item) => res + item.total_price, 0);
    },
  },

  methods: {
    checkout(e) {
      this.$emit('clickCheckout', e);
    },

    goToCart(e) {
      this.$emit('clickCart', e);
      global.location = this.cartUrl;
    },
  },
};
</script>
