import { resizeImage } from '~shared-js/helpers/image-helper';
import { scoreMetric } from '~shared-js/experiments';

export default Vue => {
  Vue.mixin({
    data() {
      return {
        mxBukamallLogo:
          'https://s1.bukalapak.com/pavilion/1587095550468/original/bukamall-logo.png',
      };
    },

    computed: {},

    methods: {
      scoreMetricHandler({ experiment, metric, userId, value = 1, sync = false } = {}) {
        try {
          return scoreMetric({
            experiment,
            userId,
            metric,
            value,
            sync,
          }).catch(err => {
            // eslint-disable-next-line
            console.error('ERROR on scoring metric: ', err.message);
          });
        } catch (err) {
          return Promise.reject(err);
        }
      },
      $isLogin() {
        return global.USER && global.USER.id;
      },
      $resizeImage: resizeImage,
    },
  });
};
