export const getInitialState = () => ({
  claimedCoupons: [],
  premiumVouchers: [],
  claimbleVouchers: [],

  // availableVouchers
  availableVouchers: {},
  isAvailableVoucherLoaded: false,
  isAvailableVoucherError: false,
});

export default getInitialState;
