<template lang="pug">
  .c-product-details-section(:id="id" :class="{ '-no-border': !borderTop }")
    .c-product-details-section__aside
      bl-affix-mv(
        v-if="asideAffix"
        :offset="120"
        :boundary="computedBoundary"
      )
        slot(name="aside")
      slot(v-else name="aside")

    .c-product-details-section__main
      slot(name="main")
</template>

<script>
import BlAffixMv from '~pdp-mv/BlAffixMv';

export default {
  name: 'BlSectionMv',
  components: {
    BlAffixMv,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    borderTop: {
      type: Boolean,
      default: true,
    },
    asideAffix: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    computedBoundary() {
      return this.id ? `#${this.id}` : '';
    },
  },
};
</script>

<style src="./BlSectionMv.style.scss" lang="scss"></style>
