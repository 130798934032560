<template lang="pug">
  .c-product-details-listing
    .c-product-details-listing__heading
      h2.c-product-list-header.u-txt--medium.u-txt--bold {{ title }}
      a(v-if="moreUrl" :href="moreUrl" :rel="rel")
        bl-button-av(size="small" @click="handleSeeAllClick") Lihat Semua
    .c-product-details-listing__body
      slot
</template>

<script>
import BlButtonAv from '@bukalapak/bazaar-dweb/dist/components/BlButtonAv';

export default {
  name: 'BlProductList',

  components: {
    BlButtonAv,
  },

  props: {
    moreUrl: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      required: true,
    },

    rel: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    handleSeeAllClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style src="./BlProductListMv.style.scss" lang="scss"></style>
