/**
 * replace given image's size to preferred size
 *
 * @param {string} url - Image url with expected format:
 *    http:// ... /bucket / ... / id / style / image_name
 *    expected style's values are [original, medium larg, small, thumb)]
 * @param {string} size - Any size in string that is supported by Imagery
 *
 * @returns {string} Replaced url with preferred size
 */
export const resizeImage = (url, size = 'medium') => {
  const imagePathRegex = /\/(original|large|normal|medium|small|thumb|(w|s)(-\d+){1,2})\//;
  return url.replace(imagePathRegex, `/${size}/`);
};

/**
 * Check if browser support webp
 *
 * @returns {boolean} is webp supported
 */
export const isSupportWebp = () => {
  if (typeof window === 'undefined') return false;

  const elem = document.createElement('canvas');

  if (elem.getContext?.('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  // for old browser like IE 8, canvas not supported
  return false;
};
