import sharedMutations from '~shared-js/product-detail-page/store/modules/shipping/mutations';

export {
  SET_BASE_SHIPPING_STATE,
  SET_FEES,
  SET_GEOCODER_ADDRESSES,
} from '~shared-js/product-detail-page/store/modules/shipping/mutations';

export default {
  ...sharedMutations,
};
