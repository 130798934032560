import _ from 'lodash';

import to from '~lib/to';
import { productReviews } from '~pdp/js/modules/api-modules';

import { SET_BASE_REVIEW_STATE } from './mutations';

/**
 * Creates an array that contain rating's value in decreasing order by key.
 * - sort: Just to make sure the order of object is in increasing
 * - reverse: Set to decreasing
 * - map: To construct array only containing the rating's value
 *
 * Ex: { 1: 24, 2: 34, 4: 100, 5: 12, 3: 10 }
 * - keys:    [1, 2, 4, 5, 3]
 * - sort:    [1, 2, 3, 4, 5]
 * - reverse: [5, 4, 3, 2, 1]
 * - map:     [12, 100, 10, 34, 24]
 *
 * @param {object} ratings Ratings object from aggregates
 * @returns {number[]}
 *
 * @example
 * setRatingsOrder({ 1: 24, 2: 34, 4: 100, 5: 12, 3: 10 })
 *
 */
const setRatingsOrder = (ratings) =>
  Object.keys(ratings)
    .sort()
    .reverse()
    .map((key) => ratings[key]);

export default (() => {
  const flattenReviewData = (item) => (image) => ({
    id: image.id,
    url: image.url,
    createdAt: item.created_at,
    product: item.product,
    review: item.review,
    sender: item.sender,
  });

  const commitReviewWithPicture = (context, data, meta) => {
    const { commit, state } = context;
    const flattenReview = data.reduce((res, item) => {
      let tmp = [...res];
      tmp = tmp.concat(item.images.map(flattenReviewData(item)));

      return tmp;
    }, []);

    commit(SET_BASE_REVIEW_STATE, {
      name: 'reviewWithPicture',
      payload: {
        pagination: _.pick(meta, ['limit', 'offset', 'total']),
        reviews: [...state.reviewWithPicture.reviews, ...flattenReview],
      },
    });
  };

  const commitDialog = (context, dialogArgs) => {
    const { commit, state } = context;
    const { index, product, section } = dialogArgs;
    const activeIndex = index !== undefined && index >= 0 ? index : state.dialog.activeIndex;
    let reviewImages = [];

    if (section === 'imgCollection') {
      reviewImages = state.reviewWithPicture.reviews;
    } else if (section === 'listReview') {
      reviewImages = product.images.map(flattenReviewData(product));
    }

    commit(SET_BASE_REVIEW_STATE, {
      name: 'dialog',
      payload: {
        active: true,
        activeIndex,
        reviewImages,
        section,
      },
    });
  };

  /**
   * For setting 'filter' and 'pagination' review's state
   *
   * @param {number} num - value between -1,0,1,2,3,4,5,6,7 -> 0=all, 1-5=star between 1 to 5,
   *  6=with picture, 7=with description, -1=no star selected
   *
   */
  function commitFilter({ commit, state }, num) {
    const { star, withPicture, withDescription } = state.filter;
    let newFilter = {};
    if (num <= 0) {
      newFilter = {
        star: false,
        withPicture: false,
        withDescription: false,
      };
    } else if (num <= 5) {
      newFilter = {
        star: num === star ? false : num,
      };
    } else if (num === 6) {
      newFilter = {
        withPicture: !withPicture,
      };
    } else {
      newFilter = {
        withDescription: !withDescription,
      };
    }

    commit('setFilter', { ...state.filter, ...newFilter });
  }

  /**
   * Fetch product-reviews for summary, review with picture only review
   *
   */
  function getReviewHead({ commit, state }, id) {
    const setParams = (obj) => ({
      product_id: id,
      ...obj,
    });

    let queryParams = setParams({
      aggregates: ['ratings'],
      limit: 0,
    });

    productReviews.retrieveProductReviews({ queryParams }).then((res) => {
      const { meta } = res;

      commit(SET_BASE_REVIEW_STATE, {
        name: 'reviewSummary',
        payload: {
          ratings: meta.aggregates && setRatingsOrder(meta.aggregates.ratings),
          total: meta.total || 0,
        },
      });
    });

    queryParams = setParams({
      has_picture: true,
      offset: 0,
      limit: 6,
    });

    productReviews.retrieveProductReviews({ queryParams }).then((res) => {
      commitReviewWithPicture({ commit, state }, res.data, res.meta);
    });
  }

  async function fetchReviews({ commit, state }, { id, pagination, aggregates }) {
    const { filter } = state;
    const paginationParams = _.isEmpty(pagination) ? state.pagination : pagination;
    const normalizedFilter = _.pickBy(filter, _.identity);

    const queryParams = {
      ...paginationParams,
      product_id: id,
    };

    if (!_.isEmpty(aggregates)) {
      queryParams.aggregates = aggregates;
    }
    if (normalizedFilter.star) {
      queryParams.ratings = [normalizedFilter.star];
    }
    if (normalizedFilter.withPicture) {
      queryParams.has_picture = true;
    }
    if (normalizedFilter.withDescription) {
      queryParams.has_description = true;
    }

    const [err, res] = await to(productReviews.retrieveProductReviews({ queryParams }));

    if (res) {
      const { data, meta } = res;

      commit(SET_BASE_REVIEW_STATE, {
        name: 'review',
        payload: {
          ...state.review,
          reviews: data,
          meta: _.pick(meta, ['aggregates', 'total']),
        },
      });

      return res;
    }

    commit(SET_BASE_REVIEW_STATE, {
      name: 'review',
      payload: {
        ...state.review,
        error: err,
      },
    });
    return Promise.reject(err);
  }

  function getInfiniteReviewPictures({ commit, state }, productId) {
    const {
      dialog,
      reviewWithPicture: { pagination, reviews },
    } = state;
    const queryParams = {
      product_id: productId,
      has_picture: true,
      limit: 6,
      offset: parseInt(pagination.offset, 10) + 6,
    };

    return new Promise((resolve) => {
      if (pagination.total > reviews.length) {
        productReviews.retrieveProductReviews({ queryParams }).then((res) => {
          commitReviewWithPicture({ commit, state }, res.data, res.meta);
          commitDialog({ commit, state }, { section: dialog.section });
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  function openDialog(context, { index, product, section = 'imgCollection' } = {}) {
    commitDialog(context, { index, product, section });
  }

  function changeDialogImage({ commit, state }, index) {
    const { dialog } = state;

    if (index !== dialog.activeIndex) {
      commit(SET_BASE_REVIEW_STATE, {
        name: 'dialog',
        payload: {
          ...dialog,
          activeIndex: index,
        },
      });
    }
  }

  function voteReview({ commit, state }, { id, vote }) {
    const { review } = state;
    const hasInReviews = review.reviews.findIndex((r) => r.id === id) > -1;

    const setVoteState = (loadState) => (reviewObj) => {
      if (reviewObj.id === id) {
        const { votes } = reviewObj;
        let newVotes = { inProgress: !loadState };
        if (loadState === 'success') {
          newVotes.user_vote = votes.user_vote !== vote ? vote : 'neutral';
          if (vote === 'positive') {
            newVotes.positive_votes =
              votes.user_vote !== vote ? votes.positive_votes + 1 : votes.positive_votes - 1;
            newVotes.negative_votes =
              votes.user_vote === 'negative' ? votes.negative_votes - 1 : votes.negative_votes;
          } else if (vote === 'negative') {
            newVotes.positive_votes =
              votes.user_vote === 'positive' ? votes.positive_votes - 1 : votes.positive_votes;
            newVotes.negative_votes =
              votes.user_vote !== vote ? votes.negative_votes + 1 : votes.negative_votes - 1;
          }
        } else {
          newVotes = Object.assign({}, votes, newVotes);
        }

        return {
          ...reviewObj,
          votes: newVotes,
        };
      }
      return reviewObj;
    };

    const commitReview = (loadState) => {
      if (hasInReviews) {
        commit(SET_BASE_REVIEW_STATE, {
          name: 'review',
          payload: {
            ...review,
            reviews: review.reviews.map(setVoteState(loadState)),
          },
        });
      }
    };

    commitReview('');
    productReviews.$votes
      .voteProductReview(id, { data: { vote } })
      .then(() => commitReview('success'))
      .catch(() => commitReview('fail'));
  }

  return {
    changeDialogImage,
    commitFilter,
    fetchReviews,
    getReviewHead,
    getInfiniteReviewPictures,
    openDialog,
    voteReview,
  };
})();
