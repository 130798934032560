<template>
  <component
    :is="buttonComponent"
    v-bind="buttonProps"
    class="c-main-product__action__buy"
    @click="$emit('click')"
  >
    <BlSpinnerAv v-if="isOnCheckout" size="small" />
    <template v-else>
      {{ isPreorder ? 'Pre-Order Sekarang' : 'Beli Sekarang' }}
    </template>
  </component>
</template>

<script>
// import BlButtonV2Av from '@bukalapak/bazaar-dweb-v2/dist/components/BlButtonAv';
import { BlButtonAv, BlSpinnerAv } from '@bukalapak/bazaar-dweb';
import BlButtonV2Av from '~pdp-av/BlButtonV2Av';

export default {
  name: 'BlCheckoutButtonMv',

  components: {
    BlButtonAv,
    BlSpinnerAv,

    BlButtonV2Av,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isOnCheckout: {
      type: Boolean,
      default: false,
    },
    isPreorder: {
      type: Boolean,
      default: false,
    },
    isWhiteHeader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    buttonComponent() {
      return this.isWhiteHeader ? 'BlButtonV2Av' : 'BlButtonAv';
    },

    buttonProps() {
      const attrs = {
        disabled: this.disabled,
      };
      if (!this.isWhiteHeader) {
        attrs.color = 'green';
      }
      return attrs;
    },
  },
};
</script>
