import sharedMutations from '~shared-js/product-detail-page/store/mutations';

import { getInitialState } from './state';

export {
  SET_BASE_STATE,
  SET_LATENCY_REQUEST,
} from '~shared-js/product-detail-page/store/mutations';
export const SET_CATEGORY = 'SET_CATEGORY';
export const RESET_STATE = 'RESET_STATE';

export default {
  ...sharedMutations,

  [SET_CATEGORY](state, category) {
    const url = new URL(category.url);
    const sliceIndex = url.pathname.indexOf('/c/') + 3;
    const urlStructure = url.pathname.slice(sliceIndex).split('/');
    state.category = {
      ...category,
      pathname: url.pathname,
      urlStructure,
    };
  },

  [RESET_STATE](state) {
    const initialState = getInitialState();
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },
};
