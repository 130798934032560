<template lang="pug">
  bl-dialog-mv.c-cart-dialog(
    :active="show"
    size="large"
    title="Keranjang Belanja"
    @update:active="toggleCart"
  )
    template(v-if="reversedCarts.length")
      .c-cart-dialog__list-item(v-if="!isEmpty(cartProduct)" )
        transition-group(name="slide-fade" tag="div")
          template(v-for="(item, index) in reversedCarts")
            bl-cart-dialog-item-mv(
              v-if="cartProduct[item.stuff.product_id]"
              :key="item.id"
              :item="item"
              :product="cartProduct[item.stuff.product_id]"
              :unavailable-state="unavailableStates[index]"
              :refreshCartProduct="refreshCartProductHandler"
            )

      bl-cart-dialog-footer-mv(
        slot="footer"
        :cart="cart"
        :has-checkout="hasCheckout"
        @clickCart="onClickCart"
        @clickCheckout="onClickCheckout"
      )

    .u-txt--base.u-txt--capitalize.u-pad--4(v-else) Keranjang belanja anda kosong.
</template>

<script>
import BlDialogMv from '@bukalapak/bazaar-dweb/dist/components/BlDialogMv';
import _ from 'lodash';

import { extraApi } from '~pdp/js/modules/api-modules';

import BlCartDialogFooterMv from './components/BlCartDialogFooterMv';
import BlCartDialogItemMv from './components/BlCartDialogItemMv';

const DEFAULT_STORE_CLOSED = 'Yah, Lapaknya Tutup Sementara';
const DEFAULT_DELETE_TEXT = 'Silakan hapus terlebih dahulu';

export default {
  name: 'BlCartDialogMv',

  components: {
    BlCartDialogFooterMv,
    BlCartDialogItemMv,
    BlDialogMv,
  },

  props: {
    cart: {
      type: Object,
      default: () => ({}),
      required: true,
    },

    currentProductSku: {
      type: Number,
      required: true,
    },

    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cartProduct: {},
    };
  },

  computed: {
    hasCheckout() {
      let flag = true;
      this.unavailableStates.forEach(state => {
        if (state.message) {
          flag = false;
        }
      });
      return flag;
    },

    reversedCarts() {
      if (this.cart.items) {
        const currentProductInCart = this.cart.items.find(
          item => item.stuff.id === this.currentProductSku,
        );
        let cart = this.cart.items.slice().reverse();
        if (currentProductInCart) {
          cart = cart.filter(item => item.stuff.id !== this.currentProductSku);
          cart.unshift(currentProductInCart);
        }
        return cart;
      }
      return [];
    },

    unavailableStates() {
      if (!_.isEmpty(this.cartProduct)) {
        return this.reversedCarts.map(item => {
          const product = this.cartProduct[item.stuff.product_id] || {};
          let message = '';
          const variant = 'error';
          const { stuff } = item;
          const { store } = product;
          const noStock = stuff.stock === 0 || stuff.stock < product.min_quantity;
          if (noStock || stuff.state !== 'active' || !product.for_sale) {
            message = `Maaf, barang tidak tersedia. ${DEFAULT_DELETE_TEXT}`;
          } else if (store && store.closing.closed) {
            message = `${DEFAULT_STORE_CLOSED}. ${DEFAULT_DELETE_TEXT}`;
          }

          return {
            message,
            variant,
          };
        });
      }
      return [];
    },
  },

  mounted() {
    if (global.document) {
      document.documentElement.style.overflow = 'hidden';
    }
    this.aggregateCartProducts();
  },

  destroyed() {
    if (global.document) {
      global.document.documentElement.style.overflow = 'auto';
    }
  },

  methods: {
    isEmpty: _.isEmpty,

    aggregateCartProducts() {
      if (this.cart.items) {
        const productIds = this.getPropsCartProductIds().sort();
        const aggregate = productIds.reduce(
          (res, id) => ({
            ...res,
            [id]: {
              method: 'GET',
              path: `/products/${id}`,
            },
          }),
          {},
        );

        if (!_.isEmpty(aggregate)) {
          extraApi.sendAggregate({ data: { aggregate } }).then(res => {
            this.cartProduct = res.data;
          });
        }
      }
    },

    getPropsCartProductIds() {
      return _.uniq(this.cart.items.map(item => item.stuff.product_id));
    },

    refreshCartProductHandler() {
      const latestCarts = this.getPropsCartProductIds().sort();
      if (latestCarts.length && !_.isEqual(latestCarts, Object.keys(this.cartProduct).sort())) {
        this.aggregateCartProducts();
      }
    },

    onClickCart(params) {
      this.$emit('clickCart', params);
    },

    onClickCheckout(params) {
      this.$emit('clickCheckout', params);
    },

    toggleCart(val) {
      this.$emit('update:show', val);
    },
  },
};
</script>

<style lang="scss" src="./BlCartDialogMv.scss"></style>
