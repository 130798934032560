<template>
  <div class="c-location-search">
    <BlTextFieldMv
      ref="searchField"
      v-model="keywords"
      class="c-location-search__field"
      data-testid="location-search-field"
      @input="debounceGetGeocoderAddresses"
    />

    <div class="c-location-search__addresses">
      <div
        v-for="(geoAddress, index) in geocoderAddresses"
        :key="index"
        class="c-location-search__address"
        role="button"
        data-testid="location-geo-address"
        @click="selectAddress(geoAddress)"
      >
        <div class="c-location-search__district">
          {{ geoAddress.district }}
        </div>
        <small>{{ geoAddress.city }}, {{ geoAddress.province }}, {{ geoAddress.country }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import BlTextFieldMv from '@bukalapak/bazaar-dweb/dist/components/BlTextFieldMv';
import { debounce, isEmpty } from 'lodash';

export default {
  name: 'BlLocationSearch',
  components: {
    BlTextFieldMv,
  },

  props: {
    userAddresses: {
      type: Array,
      default: () => [],
    },
    getGeocoderAddresses: {
      type: Function,
      default: null,
    },
    geocoderAddresses: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      keywords: '',
    };
  },

  computed: {
    primaryAddress() {
      return this.userAddresses.find(address => address.primary === true) || {};
    },
  },

  mounted() {
    const field = this.$refs.searchField.$el;
    const input = field.querySelector('input');
    input.focus();

    if (!this.geocoderAddresses.length && !isEmpty(this.primaryAddress.address)) {
      const { address } = this.primaryAddress;
      const keywords = `${address.district}, ${address.city}, ${address.province}, ${address.country}`;
      this.getGeocoderAddresses(keywords);
    }
  },

  methods: {
    debounceGetGeocoderAddresses: debounce(function debounceGetGeocoderAddresses(keywords) {
      this.getGeocoderAddresses(keywords);
    }, 300),

    selectAddress(address) {
      this.$emit('onSelectAddress', address);
    },
  },
};
</script>

<style lang="scss" src="./BlLocationSearchMv.scss"></style>
