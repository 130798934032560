import BlFlexItemAv from '~pdp-av/BlFlexItemAv/BlFlexItemAv.vue';
import BlFlexContainerMv from '~pdp-mv/BlFlexContainerMv/BlFlexContainerMv.vue';

/**
 * TODO
 * REMOVE THIS and use from Bazaar Dweb 2 instead
 *  need to refactor module that have been using this
 */

const install = (Vue) => {
  Vue.component(BlFlexContainerMv.name, BlFlexContainerMv);
  Vue.component(BlFlexItemAv.name, BlFlexItemAv);
};

export default {
  install,
};

export { BlFlexContainerMv, BlFlexItemAv };
