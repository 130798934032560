<template lang="pug">
.c-reviews-empty
  .c-reviews-empty__illustration
  p.u-mrgn-bottom--1.u-txt--fair Hasilnya Tidak Ketemu
  p.u-fg--ash.u-txt--base Coba ubah filternya ya, biar ketemu hasil yang kamu cari.
</template>

<script>
export default {
  name: 'BlReviewsEmptyAv',
};
</script>

<style lang="scss" src="./BlReviewsEmptyAv.style.scss"></style>
