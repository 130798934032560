import { throttle } from 'lodash';

const SCROLL_TOLERANCE = 30;
const AVAILABLE_DIRECTIONS = ['top', 'bottom'];

function detectPassiveListenerSupport() {
  let supportsPassive = false;

  try {
    const opts = Object.defineProperty({}, 'passive', {
      get: function get() {
        supportsPassive = true;
        return undefined;
      },
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {
    //
  }

  return supportsPassive;
}

export function ScrollHandler({ offset, selector = '' } = {}) {
  this.offset = offset;

  const scrollHandler = {
    top: [],
    bottom: [],
  };

  let onScrollHandler = null;
  let isTicking = false;
  let lastScrollTop = 0;
  let requestAnimationFrame = null;

  this.registerScrollHandler = (direction, handler) => {
    if (!AVAILABLE_DIRECTIONS.includes(direction) || typeof handler !== 'function') {
      return false;
    }

    scrollHandler[direction].push(handler);

    return scrollHandler;
  };

  this.toggleScrollHandler = () => {
    if (!scrollHandler.top.length && !scrollHandler.bottom.length) {
      isTicking = false;
      return;
    }

    const pageY = window.pageYOffset || window.scrollY || document.documentElement.scrollTop;

    let scrollBottomBoundary = pageY > lastScrollTop + SCROLL_TOLERANCE;
    if (this.offset) {
      scrollBottomBoundary = pageY > this.offset;
    }
    if (selector) {
      const el = document.querySelector(selector);
      if (el) {
        const bound = el.getBoundingClientRect();
        const elOffset = pageY + bound.y + bound.height - 117;
        scrollBottomBoundary = pageY > elOffset;
      }
    }
    if (scrollHandler.bottom.length > 0 && scrollBottomBoundary) {
      scrollHandler.bottom.forEach(handler => handler());
    }

    const scrollTopBoundary = pageY < lastScrollTop || pageY <= 0;
    if (scrollHandler.top.length > 0 && scrollTopBoundary) {
      scrollHandler.top.forEach(handler => handler());
    }

    lastScrollTop = pageY;
    isTicking = false;
  };

  this.requestTick = () => {
    requestAnimationFrame =
      requestAnimationFrame ||
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame;

    if (!isTicking) {
      requestAnimationFrame(this.toggleScrollHandler);
    }

    isTicking = true;
  };

  this.enableTick = () => {
    if (onScrollHandler) return;

    const supportsPassive = detectPassiveListenerSupport();

    onScrollHandler = throttle(() => {
      this.requestTick();
    }, 300);

    document.addEventListener(
      'scroll',
      onScrollHandler,
      supportsPassive ? { passive: true } : false,
    );

    this.requestTick();
  };

  this.disableTick = () => {
    document.removeEventListener('scroll', onScrollHandler);
  };

  return this;
}
