import { experiments } from '~shared-js/modules/api-modules';

export const chooseAlternative = ({ experiment, userId = global.USER.id, override } = {}) => {
  if (userId) {
    if (!experiment) {
      throw new Error(`Required experiment's name`);
    }

    const params = {
      data: {
        participant: userId,
      },
    };
    if (override) {
      params.data.override = override;
    }

    return experiments.$participants.chooseAlternative(experiment, params);
  }
  return Promise.resolve();
};

export const scoreMetric = ({ experiment, userId = global.USER.id, ...metricPayload } = {}) => {
  if (userId) {
    if (!experiment || !metricPayload.metric || !metricPayload.value) {
      throw new Error(`Required experiment's name, metric and value`);
    }

    const data = {
      metric: metricPayload.metric,
      value: metricPayload.value,
    };

    return experiments.$participants.$metrics.scoreTheMetrics(experiment, userId, { data });
  }
  return Promise.resolve();
};

export default {
  chooseAlternative,
  scoreMetric,
};
