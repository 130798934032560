<template lang="pug">
  .c-main-product__wholesale.u-txt--base
    bl-popover-av(position="left")
      .c-popover__trigger
        span.c-popover__trigger__text Beli Lebih Banyak, Lebih Murah
        span.c-popover__trigger__icon.c-icon
      .c-main-product__wholesale__list(slot="content")
        bl-flex-container-mv.c-main-product__wholesale__item(v-for="(item, index) in prices", :key="index" )
          bl-flex-item-av(:col="6").c-main-product__wholesale__bound
            span(v-if="index === prices.length - 1") &ge; {{ item.lower_bound }} Barang
            span(v-else) {{ setLowerBoundGap(index) }} Barang
          bl-flex-item-av(:col="6")
            bl-price-av(:number="item.price" :withSpace="false")
</template>

<script>
import BlIconAv from '@bukalapak/bazaar-dweb/dist/components/BlIconAv';
import BlPopoverAv from '@bukalapak/bazaar-dweb/dist/components/BlPopoverAv';

import { BlFlexContainerMv, BlFlexItemAv } from '~pdp-mv/BlFlexMv';
import BlPriceAv from '~pdp/components/atoms/BlPriceAv';

export default {
  name: 'BlMainWholesaleMv',

  components: {
    BlFlexContainerMv,
    BlFlexItemAv,
    BlIconAv,
    BlPriceAv,
    BlPopoverAv,
  },

  props: {
    prices: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    setLowerBoundGap(index) {
      const lower = this.prices[index].lower_bound;
      const upper = this.prices[index + 1].lower_bound - 1;

      return `${lower}${lower === upper ? '' : ` - ${upper}`}`;
    },
  },
};
</script>

<style src="./BlMainWholesaleMv.style.scss"></style>
