<template>
  <component
    :is="buttonComponent"
    :variation="isWhiteHeader ? 'outline' : ''"
    class="c-main-product__action__chat js-start-chat"
    v-bind="chatData"
    @click="handleChat"
  >
    <span
      class="c-main-product__action__chat__icon"
      :class="isSellerOnline ? 'c-main-product__action__online' : ''"
    >
      <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
        <g fill="#364058">
          <path
            d="M16 0H2a2 2 0 0 0-2 2v16l5.85-4.5H16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2m0 1c.552 0 1 .448 1 1v9.5c0 .552-.448 1-1 1H5.51l-.27.207L1 15.97V2c0-.552.448-1 1-1h14"
          />
          <path
            d="M14 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0M10 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0M6 6.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"
          />
        </g>
      </svg>
    </span>
    &nbsp;Chat
  </component>
</template>

<script>
import { BlButtonAv } from '@bukalapak/bazaar-dweb';
// import BlButtonV2Av from '@bukalapak/bazaar-dweb-v2/dist/components/BlButtonAv';
import BlButtonV2Av from '~pdp-av/BlButtonV2Av';

import loginAction from '~pdp/js/constants/login-action';
import { setLoginComebackUrl } from '~pdp-utils/helpers';
import { productDetailAction } from '~shared-js/tracker/rage';
import productActionType from '~shared-js/tracker/product-detail-action-type';

export default {
  name: 'BlChatButtonMv',

  components: {
    BlButtonAv,

    BlButtonV2Av,
  },

  props: {
    chatData: {
      type: Object,
      required: true,
    },
    isSellerOnline: {
      type: Boolean,
      default: false,
    },
    isWhiteHeader: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
  },

  inject: ['productDetailTvInjection'],

  computed: {
    buttonComponent() {
      return this.isWhiteHeader ? 'BlButtonV2Av' : 'BlButtonAv';
    },
  },

  methods: {
    handleChat() {
      this.sendProductAction();

      if (!this.productDetailTvInjection.isLogin) {
        const params = {
          customPath: `messages/${this.product.get('store.id')}`,
          queryParams: {
            context: {
              class: 'Product',
              id: parseInt(this.product.id, 36),
            },
          },
        };
        global.location = setLoginComebackUrl(loginAction.chat, params);
      }
    },

    sendProductAction() {
      productDetailAction.track({
        action: productActionType.chat,
      });
    },
  },
};
</script>
