<template lang="pug">
  .c-main-product__scan.c-main-product__separator(v-if="product.id")
    BlMediaMv(:src="qrIllus" style="width: 60px")
    .c-main-product__scan__headline
      h3.u-mrgn-bottom--1.u-txt--base.u-txt--bold Lebih Hemat di Aplikasi!
      p.u-fg--ash-dark.u-mrgn-bottom--0
        | Ada gratis ongkir dan promo menarik menanti kamu. Download sekarang!
</template>

<script>
import BlMediaMv from '~shared-js/components/BlMediaMv';

export default {
  name: 'BlMainScanMv',

  components: {
    BlMediaMv,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      qrIllus: global.assetUrl('images/illustration-qr.png'),
    };
  },
};
</script>

<style lang="scss" src="./BlMainScanMv.scss"></style>
