export const SELF_VARIATION = [
  'flex-end',
  'flex-start',
  'center',
  'auto',
  'baseline',
  'stretch',
  '',
];

export const COL_VARIATION = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
